import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import RoundButton from "../../../../components/roundButton";
import ConfirmationModal from "../../../../components/confirmationModal";
import ProsConsBox from "./prosConsBox";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getProsConsList,
  createProsCons,
  updateProsCons,
  deleteProsCons,
  getProsConsById,
} from "../../../../redux/prosCons/prosConsApi";
import WrapperLoader from "../../../../components/wrapperLoader";
import { getLastIdFromUrl, getQueryParams } from "../../../../utils/utilities";
import { setSelectedProsCons } from "../../../../redux/prosCons/prosConsSlice";
import ProsConsForm from "../../../prosCons/CreationForm";
import CustomDrawer from "../../../../components/customDrawer";
import { prosConsIcon } from "../../../../assets/icons";

interface TChartListProps {
  checkingDetailTabShow: Function;
}

const ProsConsList = ({ checkingDetailTabShow }: TChartListProps) => {
  const [open, setOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [allProsConsLoading, setAllProsConsLoading] = useState(false);
  const [prosConsItems, setProsConsItems] = useState<any>([]);
  const [isEditMode, setIsEditMode] = useState<any>(null);
  const [formLoader, setFormLoader] = useState(false);
  const [prosConsByIdLoader, setProsConsByIdLoader] = useState(false);

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { prosConsList, prosConsDeleteRes, selectedProsCons, prosConsByIdRes } =
    useAppSelector((state) => state.prosCons);

  useEffect(() => {
    const prosConsDataList: any = [...(prosConsList || [])];
    setProsConsItems(prosConsDataList || []);
  }, [prosConsList]);

  useEffect(() => {
    if (prosConsList?.length > 0 && prosConsDeleteRes) {
      dispatch(setSelectedProsCons(prosConsList[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prosConsDeleteRes]);

  useEffect(() => {
    checkingDetailTabShow();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prosConsItems]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          setAllProsConsLoading(true);
          await dispatch(
            getProsConsList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setAllProsConsLoading(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryStringObj = getQueryParams(window.location.href);

    if (queryStringObj?.active === "pros-cons") {
      navigate(
        `${window.location.pathname}?active=pros-cons&id=${selectedProsCons?.id || queryStringObj?.id}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProsCons]);

  useEffect(() => {
    const queryStringObj = getQueryParams(window.location.href);

    if (prosConsItems?.length > 0 && queryStringObj?.active === "pros-cons") {
      const findProsCons = prosConsItems.find(
        (data: any) => data?.id === queryStringObj.id
      );

      dispatch(
        setSelectedProsCons(
          findProsCons || prosConsItems[0]
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prosConsItems]);

  const gettingProsConsById = (id: string) => {
    const fetchData = async () => {
      setProsConsByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(
            getProsConsById({
              accessToken,
              id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        setProsConsByIdLoader(false);
      } catch (error) {
        console.error("Error getting access token:", error);
        setProsConsByIdLoader(false);
      }
    };

    fetchData();
  };

  const onOpenModal = () => {
    setOpen(true);
    setIsEditMode(false);
  };

  const onCloseModal = () => {
    setOpen(false);
    setIsEditMode(false);
  };

  const onOpenEditModal = (id: string) => {
    setIsEditMode(true);
    setOpen(true);
    gettingProsConsById(id);
  };

  const deletingProsCons = () => {
    const fetchData = async () => {
      setDeleteLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteProsCons({
              accessToken,
              id: selectedData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setDeleteLoader(false);
          closeConfirmationModal();

          await dispatch(
            getProsConsList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setDeleteLoader(false);
      }
    };

    fetchData();
  };

  const updatingProsCons = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateProsCons({
              body,
              accessToken,
              id: prosConsByIdRes?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          onCloseModal();
          setFormLoader(false);

          await dispatch(
            getProsConsList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingProsCons = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createProsCons({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          onCloseModal();
          setFormLoader(false);

          await dispatch(
            getProsConsList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitProsCons = async (body: any) => {
    if (isEditMode) {
      updatingProsCons(body);
    } else {
      creatingProsCons(body);
    }
  };

  const deleteConfirmationModal = (data: any) => {
    setSelectedData(data);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedData(null);
  };

  const onSelectedProsCons = async (data: any) => {
    dispatch(setSelectedProsCons(data));
  };

  return (
    <>
      <WrapperLoader loading={allProsConsLoading}>
        <div className="p-0">
          <div className="mb-3">
            <h3 className=" text-md mb-2 pb-2 border-b border-zinc-100">
              Pros & Cons
            </h3>
            <div
              onClick={() => onOpenModal()}
              className="w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-amber-400"
            >
              <RoundButton onClickBtn={() => console.log("clicking...")} />
            </div>
          </div>

          <div
            className="overflow-y-hidden hover:overflow-y-auto transition-all"
            style={{ height: "calc(100vh - 169px)" }}
          >
            <ul className="mt-3 grid grid-cols-1">
              {prosConsItems.map((item: any, index: number) => (
                <div key={index}>
                  <ProsConsBox
                    totalLength={prosConsItems?.length}
                    key={index}
                    data={item}
                    onEdit={() => onOpenEditModal(item?.id)}
                    onDelete={() => deleteConfirmationModal(item)}
                    deleteLoader={false}
                    selectedData={selectedData}
                    onSelectProsCons={() => onSelectedProsCons(item)}
                    selectedProsCons={selectedProsCons}
                  />
                </div>
              ))}
            </ul>
          </div>
        </div>
      </WrapperLoader>

      <CustomDrawer
        open={open}
        onCloseModal={onCloseModal}
        title={
          <div className="flex items-center">
            <span className="mr-2">{prosConsIcon}</span>
            <span>Pros-Cons</span>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer"
              className="text-xs text-yellow-500 hover:text-yellow-800 flex items-center font-semibold ml-3"
            >
              <span className="mr-1">Learn More</span>
              <ArrowTopRightOnSquareIcon className="h-3" />
            </a>
          </div>
        }
        description={
          isEditMode
            ? "Update Pros-Cons"
            : "Create new Pros-Cons, provide title and options that you will try to analyze and compare. Options are mandatory fields."
        }
      >
        <WrapperLoader loading={prosConsByIdLoader}>
          <ProsConsForm
            onCloseModal={onCloseModal}
            editData={prosConsByIdRes}
            onSubmitForm={onSubmitProsCons}
            loader={formLoader}
            editMode={isEditMode}
          />
        </WrapperLoader>
      </CustomDrawer>

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingProsCons}
        deleteLoader={deleteLoader}
        title="Pros & Cons Delete Confirmation"
        description={
          <div>
            Are you sure you want to delete: <b>{selectedData?.title}</b>
          </div>
        }
      />
    </>
  );
};

export default ProsConsList;
