import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import WrapperLoader from "src/components/wrapperLoader";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getTbarById } from "../../../../redux/tbars/tbarsApi";
import { getQueryParams, getLastIdFromUrl } from "../../../../utils/utilities";
import DetailsOfAllDecisions from '../details';

export default function TChartDetails() {
  const [tbarByIdLoader, setTbarByIdLoader] = useState(false);
	const [detailsData, setDetailsData] = useState<any>(null);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { selectedTChart, tbarByIdRes } = useAppSelector((state) => state.tbars);

  useEffect(() => {
    const fetchData = async () => {
      const queryStringObj = getQueryParams(window.location.href);
      setTbarByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(
            getTbarById({
              accessToken,
              tbarId: queryStringObj?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        setTbarByIdLoader(false);
      } catch (error) {
        console.error("Error getting access token:", error);
        setTbarByIdLoader(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTChart]);

	useEffect(() => {
		if(tbarByIdRes) {
      const tBarData = tbarByIdRes?.tbar_analysis;
			const showData = {
        title: tBarData?.details?.TBarTitle || "",
        description: tBarData?.details?.TBarDescription || "",
        status: tBarData?.details?.TBarStatus || "",
        category: tBarData?.details?.TBarCategory || "",
        assumptions: tBarData?.details?.assumptions || "",
        implications: tBarData?.details?.implications || "",
        final_decision: tBarData?.details?.final_decision || "",
      };
			setDetailsData(showData);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tbarByIdRes]);

  return (
    <WrapperLoader loading={tbarByIdLoader}>
      <DetailsOfAllDecisions detailsData={detailsData} />
    </WrapperLoader>
  );
}
