import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  patchRequest,
} from "../../utils/api";

export const getProfileData = createAsyncThunk(
  "profile/get",
  async (accessToken: string) => {
    try {
      const response = await getRequest({
        url: "/user",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updateProfile = createAsyncThunk(
  "profile/Update",
  async ({ body, accessToken }: any) => {
    try {
      const response = await patchRequest({
        url: "/user",
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);
