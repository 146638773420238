import React from "react";
import Tooltip from "../tooltipWrapper";
import { useAppSelector } from "../../hooks";

interface MenuSubItemsListProps {
  classNames: Function;
  onClickMenu: Function;
  collapseSidebar: boolean;
  data: any;
}

export default function MenuSubItemsList({
  classNames,
  onClickMenu,
  collapseSidebar,
  data,
}: MenuSubItemsListProps) {
  const { currentPage } = useAppSelector((state) => state.profile);

  return (
    <li key={data.name} className={collapseSidebar ? "ml-0" : "-ml-[14px]"}>
      <Tooltip
        text={
          collapseSidebar ? (
            <span className="whitespace-nowrap">{data.name}</span>
          ) : (
            ""
          )
        }
        position="right"
      >
        <a
          href={data?.href}
          onClick={(e) => {
            e.preventDefault(); // Prevent default behavior
            onClickMenu(data);
          }}
          className={classNames(
            data.href === currentPage?.href
              ? "bg-white text-amber-500"
              : "text-zinc-900 hover:text-amber-500 hover:bg-zinc-100 border-transparent opacity-90 hover:opacity-100",
            `flex gap-x-3 rounded-md p-2 text-xs leading-5 font-medium cursor-pointer items-center ${
              collapseSidebar ? "items-center justify-start" : ""
            }`
          )}
        >
          <span
            className={classNames(
              data.href === currentPage?.href
                ? "text-amber-500"
                : "hover:text-amber-500"
            )}
          >
            <data.icon className="w-[17px] h-[17px]" />
          </span>
          <span>{data.name}</span>{" "}
        </a>
      </Tooltip>
    </li>
  );
}
