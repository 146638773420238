import React, { Key, useEffect, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import Tooltip from "../tooltipWrapper";
import { useAppSelector } from "../../hooks";
import MenuSubItemsList from "./menuSubItemsList";

interface SidebarProps {
  classNames: Function;
  navigationsList: Array<any>[];
  onClickMenu: Function;
  title: string;
  collapseSidebar: boolean;
}

export default function MenuItemsList({
  classNames,
  navigationsList,
  onClickMenu,
  title,
  collapseSidebar,
}: SidebarProps) {
  const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);

  const { currentPage } = useAppSelector((state) => state.profile);

  useEffect(() => {
    if (collapseSidebar) setOpenMenuIndex(null);
  }, [collapseSidebar]);

  const handleMenuClick = (index: number) => {
    setOpenMenuIndex(openMenuIndex === index ? null : index);
  };

  return (
    <li>
      {!collapseSidebar && (
        <div className="text-xs font-semibold leading-6 text-slate-400 mb- uppercase">
          {title}
        </div>
      )}

      <ul className={`space-y-1 pl-0`}>
        {navigationsList.map((item: any, index: number) => (
          <li
            key={item.name}
            className={classNames(
              "relative",
              collapseSidebar ? "ml-0" : "-ml-[14px]"
            )}
          >
            <Tooltip
              text={
                collapseSidebar ? (
                  <span className="whitespace-nowrap">{item.name}</span>
                ) : (
                  ""
                )
              }
              position="right"
            >
              <a
                href={item?.href}
                // onMouseOver={(e) => {
                //   e.preventDefault(); // Prevent default behavior
                //   if (item.childrens && item.childrens?.length > 0) {
                //     if (collapseSidebar) {
                //       handleMenuClick(index);
                //     }
                //   }
                // }}
                // onMouseLeave={(e) => {
                //   e.preventDefault(); // Prevent default behavior
                //   if (item.childrens && item.childrens?.length > 0) {
                //     if (collapseSidebar) {
                //       handleMenuClick(0);
                //     }
                //   }
                // }}
                onClick={(e) => {
                  e.preventDefault(); // Prevent default behavior
                  if (item.childrens && item.childrens?.length > 0) {
                    handleMenuClick(index);
                  }
                  onClickMenu(item);
                }}
                className={classNames(
                  (openMenuIndex === index &&
                    item.childrens &&
                    item.childrens?.length > 0) ||
                    item.href === currentPage?.href
                    ? "bg-white text-amber-500 shadow-md border-l-2 border-amber-500"
                    : "text-zinc-900 hover:text-amber-500 hover:bg-zinc-100 border-transparent opacity-90 hover:opacity-100",
                  `flex items-center rounded-md p-2 text-xs leading-5 font-medium cursor-pointer border-l-2 ${
                    collapseSidebar ? " justify-center" : "justify-between"
                  }`
                )}
              >
                <div className="flex items-center justify-center">
                  <span
                    className={classNames(
                      collapseSidebar ? "" : "mr-2",
                      item.href === currentPage?.href
                        ? "text-amber-500"
                        : "hover:text-amber-500"
                    )}
                  >
                    <item.icon className="w-[17px] h-[17px]" />
                  </span>

                  {!collapseSidebar && <span>{item.name}</span>}
                </div>

                {item.childrens && item.childrens?.length > 0 && (
                  <ChevronRightIcon
                    className={classNames(
                      "h-[14px]",
                      item.href === currentPage?.href
                        ? "text-amber-500"
                        : "hover:text-amber-500",
                      `transition-all rotate-${openMenuIndex === index ? 90 : 0}`
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleMenuClick(index);
                    }}
                  />
                )}
              </a>
            </Tooltip>

            {/* Sub Menu */}
            {openMenuIndex === index &&
              item.childrens &&
              item.childrens?.length > 0 && (
                <ul
                  className={classNames(
                    "space-y-1 rounded-md",
                    collapseSidebar
                      ? "bg-white absolute left-[45px] top-[0] shadow-lg w-[200px] border border-zinc-200 p-3"
                      : "p-3 pl-6 bg-slate-100"
                  )}
                >
                  {item.childrens.map(
                    (subitem: {
                      id: Key | null | undefined;
                      title: string;
                      menuList: any[][];
                    }) => (
                      <MenuSubItemsList
                        key={subitem.id}
                        classNames={classNames}
                        onClickMenu={onClickMenu}
                        collapseSidebar={collapseSidebar}
                        data={subitem}
                      />
                    )
                  )}
                </ul>
              )}
          </li>
        ))}
      </ul>
    </li>
  );
}
