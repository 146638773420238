import './texteditor.css';

import AutoSuggestions, { SuggestionsAPI } from './autoSuggestions';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';

import AiSuggestionsTooltip from 'src/components/aiSuggestionsTooltip';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import LoaderPage from '../../../components/loaderPage';
import { getDocumentFavoriteComponents } from '../../../redux/documentComponents/documentComponentsApi';
import { getRawIconObjectByName } from '../../../utils/documentComponentsIcons';
import { useAiSuggestionsFetcher } from './useAiSuggestions';
import { useAuth0 } from '@auth0/auth0-react';

const MyReactEditor = ({ setValue, value, ckeRef }) => {
    const [documentComponentsList, setDocumentComponentsList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useAppDispatch();
    const { collapseSidebar } = useAppSelector((state) => state.profile);
    const { getDocumentFavoriteComponentsRes } = useAppSelector((state) => state.documentComponents);
    const areAiSuggestionsEnabled = useAppSelector((state) => !!state.projects.selectedDocument?.ai_suggestions);

    const fetchSuggestions = useAiSuggestionsFetcher();
    const [suggestionsAPI, setSuggestionsAPI] = useState(null);

    useEffect(() => {
        if (!suggestionsAPI) return;

        if (areAiSuggestionsEnabled) {
            suggestionsAPI.fetcher = fetchSuggestions;
        }

        return () => {
            suggestionsAPI.fetcher = null;
        }
    }, [suggestionsAPI, areAiSuggestionsEnabled, fetchSuggestions]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                if (accessToken) {
                    await dispatch(getDocumentFavoriteComponents({ accessToken }));
                }
            } catch (error) {
                console.error('Error getting access token:', error);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getDocumentFavoriteComponentsRes?.length > 0) {
            const newList = [];

            getDocumentFavoriteComponentsRes.forEach((data) => {
                const iconObject = getRawIconObjectByName(data?.icon);

                newList.push({
                    title: data?.title,
                    icon: iconObject?.icon,
                    data: () => data?.content,
                    description: data?.short_description,
                });
            });

            setDocumentComponentsList(newList);
            setIsLoading(false);
        }

        if (getDocumentFavoriteComponentsRes === null) {
            setIsLoading(false);
        }
    }, [getDocumentFavoriteComponentsRes]);

    useEffect(() => {
        const project = document.getElementById('project-container');
        const toolkit = document.getElementsByClassName('ck-editor__top');
        if (toolkit[0] && project) {
            toolkit[0].style.width = `${project.clientWidth - 2}px`;

            if (collapseSidebar) {
                toolkit[0].style.left = `53px`;
            } else {
                toolkit[0].style.left = `257px`;
            }
        }
    }, [collapseSidebar, value, isLoading]);

    const handleChange = (value) => {
        setValue(value);
    };

    return (
        <div>
            {isLoading ? (
                <LoaderPage page='design-detail' />
            ) : (
                <>
                    <CKEditor
                        editor={Editor}
                        data={value || ''}
                        onReady={(editor) => {
                            ckeRef.current = editor;
                            setSuggestionsAPI(new SuggestionsAPI(editor, { debounceTimeMs: 500 }));
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            handleChange(data);
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                        config={{
                            template: {
                                definitions: documentComponentsList,
                            },
                            extraPlugins: [AutoSuggestions],
                        }}
                    />
                    <AiSuggestionsTooltip suggestionsApi={suggestionsAPI} />
                </>
            )}
        </div>
    );
};

export default MyReactEditor;
