import {
  MouseEvent,
  DragEvent,
  useCallback,
  useRef,
  useEffect,
  useState,
} from "react";
import {
  ReactFlow,
  Node,
  useReactFlow,
  Background,
  BackgroundVariant,
  MarkerType,
  useNodesState,
  useEdgesState,
  addEdge,
  Edge,
  Connection,
  Controls,
  reconnectEdge,
  ProOptions,
  OnConnect,
  applyNodeChanges,
  OnNodesChange,
  NodeChange,
} from "@xyflow/react";
import Sidebar from "./Sidebar";
import SimpleNode from "./SimpleNode";
import GroupNode from "./GroupNode";
import {
  nodes as initialNodes,
  edges as initialEdges,
} from "./initial-elements";
import { sortNodes, getId, getNodePositionInsideParent } from "./utils";
import SelectedNodesToolbar from "./SelectedNodesToolbar";
import { useAppSelector, useAppDispatch } from "../../../hooks";

import useCopyPaste from "./useCopyPaste";
import { getHelperLines } from "./utils";
import HelperLines from "./HelperLines";
import CustomDrawer from "../../../components/customDrawer";
import {
  setOpenNodeSetting,
  setSelectedNodeSetting,
} from "../../../redux/diagrams/diagramsSlice";

import "@xyflow/react/dist/style.css";
import "@reactflow/node-resizer/dist/style.css";

import styles from "./style.module.css";

const proOptions: ProOptions = { account: "paid-pro", hideAttribution: true };

const onDragOver = (event: DragEvent) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
};

const nodeTypes = {
  node: SimpleNode,
  group: GroupNode,
};

const defaultEdgeOptions = {
  style: {
    strokeWidth: 2,
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
  },
};

interface FlowChartProps {
  diagram: string;
}

function DynamicGrouping({ diagram }: FlowChartProps) {
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const [helperLineHorizontal, setHelperLineHorizontal] = useState<
    number | undefined
  >(undefined);

  const [helperLineVertical, setHelperLineVertical] = useState<
    number | undefined
  >(undefined);

  const { selectedDiagram, selectedNodeSetting, openNodeSetting } =
    useAppSelector((state) => state.diagrams);
  const { screenToFlowPosition, getIntersectingNodes, getNodes } =
    useReactFlow();
  const dispatch = useAppDispatch();
  useCopyPaste();

  const edgeReconnectSuccessful = useRef(true);

  useEffect(() => {
    const { nodes, edges } = selectedDiagram?.design
      ? JSON.parse(selectedDiagram?.design)
      : { nodes: null, edges: null };
    setNodes(nodes || []);
    setEdges(edges || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDiagram]);

  useEffect(() => {
    if (diagram) {
      try {
        let jsonString = diagram || "";

        // Remove the single quotes from the start and end
        let cleanedJsonString = jsonString.slice(1, -1);

        // Regular expressions to extract initialNodes and initialEdges
        let nodesRegex = /initialNodes\s*=\s*(\[[\s\S]*?\]);/;
        let edgesRegex = /initialEdges\s*=\s*(\[[\s\S]*?\]);/;

        // Extract and convert initialNodes to JSON string
        let initialNodesMatch = cleanedJsonString.match(nodesRegex);
        let initialNodesString = initialNodesMatch
          ? toJsonString(initialNodesMatch[1])
          : "[]";
        let initialNodes = JSON.parse(initialNodesString);

        // Extract and convert initialEdges to JSON string
        let initialEdgesMatch = cleanedJsonString.match(edgesRegex);
        let initialEdgesString = initialEdgesMatch
          ? toJsonString(initialEdgesMatch[1])
          : "[]";
        let initialEdges = JSON.parse(initialEdgesString);

        setNodes(initialNodes);
        setEdges(initialEdges);
      } catch {
        console.log("Error: Please try again letter");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagram]);

  // Function to convert JavaScript-like object strings to JSON strings
  function toJsonString(jsObjectString: any) {
    return jsObjectString
      .replace(/([{,]\s*)(\w+)\s*:/g, '$1"$2":') // Add quotes around property names
      .replace(/'/g, '"'); // Replace single quotes with double quotes
  }

  const onConnect: OnConnect = useCallback(
    (edge: Edge | Connection) => {
      const newEdge = {
        ...edge,
        animated: true,
      };

      setEdges((eds) => addEdge(newEdge, eds));
    },
    [setEdges]
  );

  const onDrop = (event: DragEvent) => {
    event.preventDefault();

    const type = event.dataTransfer.getData("application/reactflow");
    const iconUrl = event.dataTransfer.getData("iconUrl/reactflow");
    const name = event.dataTransfer.getData("name/reactflow");

    // Style for the group
    const style = event.dataTransfer.getData("style/reactflow");
    const styleParse = style ? JSON.parse(style) : null;

    const position = screenToFlowPosition({
      x: event.clientX - 20,
      y: event.clientY - 20,
    });
    const nodeDimensions =
      type === "group" ? { width: 400, height: 400 } : { width: 120 };

    const intersections = getIntersectingNodes({
      x: position.x,
      y: position.y,
      width: 40,
      height: 40,
    }).filter((n) => n.type === "group");
    const groupNode = intersections[0];

    const newNode: Node = {
      id: getId(),
      type,
      position,
      data: {
        label: `${name}`,
        icon_url: iconUrl,
        border_style: type === "group" ? styleParse?.border_style || "" : "",
        color: type === "group" ? styleParse?.color || "" : "",
      },
      ...nodeDimensions,
    };

    if (groupNode) {
      // if we drop a node on a group node, we want to position the node inside the group
      newNode.position = getNodePositionInsideParent(
        {
          position,
          width: 40,
          height: 40,
        },
        groupNode
      ) ?? { x: 0, y: 0 };
      newNode.parentId = groupNode?.id;
      newNode.expandParent = true;
    }

    // we need to make sure that the parents are sorted before the children
    // to make sure that the children are rendered on top of the parents
    const sortedNodes = getNodes().concat(newNode).sort(sortNodes);
    setNodes(sortedNodes);
  };

  const onNodeDragStop = useCallback(
    (_: MouseEvent, node: Node) => {
      if (node.type !== "node" && !node.parentId) {
        return;
      }

      const intersections = getIntersectingNodes(node).filter(
        (n) => n.type === "group"
      );
      const groupNode = intersections[0];

      // when there is an intersection on drag stop, we want to attach the node to its new parent
      if (intersections.length && node.parentId !== groupNode?.id) {
        const nextNodes: Node[] = getNodes()
          .map((n) => {
            if (n.id === groupNode.id) {
              return {
                ...n,
                className: "",
              };
            } else if (n.id === node.id) {
              const position = getNodePositionInsideParent(n, groupNode) ?? {
                x: 0,
                y: 0,
              };

              return {
                ...n,
                position,
                parentId: groupNode.id,
                extent: "parent",
              } as Node;
            }

            return n;
          })
          .sort(sortNodes);

        setNodes(nextNodes);
      }
    },
    [getIntersectingNodes, getNodes, setNodes]
  );

  const onNodeDrag = useCallback(
    (_: MouseEvent, node: Node) => {
      if (node.type !== "node" && !node.parentId) {
        return;
      }

      const intersections = getIntersectingNodes(node).filter(
        (n) => n.type === "group"
      );
      const groupClassName =
        intersections.length && node.parentId !== intersections[0]?.id
          ? "active"
          : "";

      setNodes((nds) => {
        return nds.map((n) => {
          if (n.type === "group") {
            return {
              ...n,
              className: groupClassName,
            };
          } else if (n.id === node.id) {
            return {
              ...n,
              position: node.position,
            };
          }

          return { ...n };
        });
      });
    },
    [getIntersectingNodes, setNodes]
  );

  const onReconnectStart = useCallback(() => {
    edgeReconnectSuccessful.current = false;
  }, []);

  const onReconnect = useCallback((oldEdge: any, newConnection: any) => {
    edgeReconnectSuccessful.current = true;
    setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReconnectEnd = useCallback((_: any, edge: any) => {
    if (!edgeReconnectSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeReconnectSuccessful.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customApplyNodeChanges = useCallback(
    (changes: NodeChange[], nodes: Node[]): Node[] => {
      // reset the helper lines (clear existing lines, if any)
      setHelperLineHorizontal(undefined);
      setHelperLineVertical(undefined);

      // this will be true if it's a single node being dragged
      // inside we calculate the helper lines and snap position for the position where the node is being moved to
      if (
        changes.length === 1 &&
        changes[0].type === "position" &&
        changes[0].dragging &&
        changes[0].position
      ) {
        const helperLines = getHelperLines(changes[0], nodes);

        // if we have a helper line, we snap the node to the helper line position
        // this is being done by manipulating the node position inside the change object
        changes[0].position.x =
          helperLines.snapPosition.x ?? changes[0].position.x;
        changes[0].position.y =
          helperLines.snapPosition.y ?? changes[0].position.y;

        // if helper lines are returned, we set them so that they can be displayed
        setHelperLineHorizontal(helperLines.horizontal);
        setHelperLineVertical(helperLines.vertical);
      }

      return applyNodeChanges(changes, nodes);
    },
    []
  );

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      setNodes((nodes) => customApplyNodeChanges(changes, nodes));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes, customApplyNodeChanges]
  );

  const onCloseNodeEditSetting = () => {
    dispatch(setOpenNodeSetting(false));
    dispatch(setSelectedNodeSetting(null));
  };

  return (
    <div className={styles.wrapper}>
      <Sidebar />
      <div className={styles.rfWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onEdgesChange={onEdgesChange}
          onNodesChange={onNodesChange}
          onConnect={onConnect}
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          onDrop={onDrop}
          onDragOver={onDragOver}
          proOptions={proOptions}
          selectNodesOnDrag={false}
          nodeTypes={nodeTypes}
          defaultEdgeOptions={defaultEdgeOptions}
          onReconnect={onReconnect}
          onReconnectStart={onReconnectStart}
          onReconnectEnd={onReconnectEnd}
        >
          <Controls />
          <Background
            color="#464646"
            gap={50}
            variant={BackgroundVariant.Dots}
          />
          <SelectedNodesToolbar />
          <HelperLines
            horizontal={helperLineHorizontal}
            vertical={helperLineVertical}
          />
        </ReactFlow>
      </div>

      <CustomDrawer
        open={openNodeSetting}
        onCloseModal={onCloseNodeEditSetting}
        title="Node Setting"
        description="Here is your node setting"
      >
        <div className="h-screen bg-white p-6">
          <h3 className="font-semibold text-customDarkBlue">{selectedNodeSetting?.data?.label || ""}</h3>
        </div>
      </CustomDrawer>
    </div>
  );
}

export default function Flow({ diagram }: FlowChartProps) {
  return <DynamicGrouping diagram={diagram} />;
}
