import React, { useEffect, useState } from "react";
import { DragEvent } from "react";
import { iconsList, getNodeGroupsList } from "./utils";
import { Tooltip } from "react-tooltip";
import IconsCategoryTabs from "../iconsCategoryTabs";
import SearchField from "src/components/searchField";
import NodeListBox from "./nodeListBox";

const onDragStart = (
  event: DragEvent,
  nodeType: string,
  iconUrl: string,
  name: string,
  style?: string
) => {
  event.dataTransfer.setData("application/reactflow", nodeType);
  event.dataTransfer.setData("iconUrl/reactflow", iconUrl);
  event.dataTransfer.setData("name/reactflow", name);

  if (nodeType === "group") {
    event.dataTransfer.setData("style/reactflow", style || "");
  }

  event.dataTransfer.effectAllowed = "move";
};

const Sidebar = () => {
  const [search, setSearch] = useState("");
  const [iconsCompleteList, setIconsCompleteList] = useState([]);
  const [iconsNonfilterList, setIconsNonfilterList] = useState([]);
  const [nodeGroupsList, setNodeGroupsList] = useState([]);
  const [currentTab, setCurrentTab] = useState(1);

  useEffect(() => {
    const iconsData: any = iconsList(
      `${(currentTab === 1 && "azure") || (currentTab === 2 && "aws") || (currentTab === 3 && "gcp") || (currentTab === 4 && "kubernetes") || (currentTab === 5 && "devops") || (currentTab === 6 && "software") || (currentTab === 7 && "vendors")}`
    );

    const groupsList: any = getNodeGroupsList(
      `${(currentTab === 1 && "azure") || (currentTab === 2 && "aws") || (currentTab === 3 && "gcp") || (currentTab === 4 && "kubernetes") || (currentTab === 5 && "devops") || (currentTab === 6 && "software") || (currentTab === 7 && "vendors")}`
    );

    // Nodes List
    setIconsCompleteList(iconsData);
    setIconsNonfilterList(iconsData);

    // Groups List
    setNodeGroupsList(groupsList);
  }, [currentTab]);

  const filterIconsByName = async (value: string) => {
    const dataList = [...iconsCompleteList];

    setSearch(value);

    const filterList: any = dataList
      .map((category: any) => ({
        ...category,
        data: category.data.filter((item: any) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        ),
      }))
      .filter((category: any) => category.data.length > 0);

    if (value.length > 0) {
      setIconsCompleteList(filterList);
    } else {
      setIconsCompleteList(iconsNonfilterList);
    }
  };

  const onChangeTab = (tab: number) => {
    setCurrentTab(tab);
    setSearch("");
  };

  return (
    <aside className="w-[220px] border-r border-zinc-200">
      <div className="pb-1 border-b border-zinc-200 px-2">
        <IconsCategoryTabs
          setCurrentTab={onChangeTab}
          currentTab={currentTab}
        />
      </div>

      <div className="px-3 pt-1 flex">
        {nodeGroupsList?.length > 0 &&
          nodeGroupsList?.map((groupItem: any, index: number) => {
            return (
              <div key={index} className="mr-2">
                <Tooltip
                  className="z-[50]"
                  id={`group-tooltip-${groupItem?.name}`}
                  place="top"
                >
                  <span className="uppercase">{groupItem?.name}</span>
                </Tooltip>

                <div
                  data-tooltip-id={`group-tooltip-${groupItem?.name}`}
                  className="bg-white p-1 flex items-center justify-center w-[40px] h-[40px] rounded-lg shadow-md cursor-move transition-all hover:scale-125"
                  onDragStart={(event: DragEvent) =>
                    onDragStart(
                      event,
                      "group",
                      groupItem?.icon,
                      groupItem?.name,
                      JSON.stringify(groupItem?.style)
                    )
                  }
                  draggable
                >
                  <img src={groupItem?.icon} alt={groupItem?.name} />
                </div>
              </div>
            );
          })}
      </div>

      <div className="py-2 border-b border-zinc-200 px-2">
        <SearchField onSearchField={filterIconsByName} searchValue={search} />
      </div>

      <div className="h-[calc(100vh_-_247px)] overflow-hidden mb-3 px-3 hover:overflow-y-auto">
        {iconsCompleteList.length > 0 &&
          iconsCompleteList.map((iconDataList: any, index: number) => (
            <NodeListBox
              key={index}
              itemIndex={index}
              iconDataList={iconDataList}
              onDragStart={onDragStart}
            />
          ))}
      </div>
    </aside>
  );
};

export default Sidebar;
