import React, { useEffect, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";
import ChatGPT from "../chatGPT/chatGPT";
import {
  getAllConversations,
  createConversation,
  deleteConversations,
} from "../../../../redux/chatGPT/chatGPTApi";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getLastIdFromUrl } from "../../../../utils/utilities";
import WrapperLoader from "src/components/wrapperLoader";
import CompleteEmptyPage from "../../../../components/completeEmptyPage";
import ChatAIModal from "../chatAImodal";
import ConversationBox from "./conversationBox";
import RoundButton from "../../../../components/roundButton";
import ConfirmationModal from "../../../../components/confirmationModal";
import DropdownSelectionOption from "../../../../components/dropdownSelectionOption";

import aiSVG from "../../../../assets/images/Illustration-09.svg";

interface DecisionMatrixDetailsProps {
  addItemToTextEditor: Function;
}

export default function DecisionMatrixDetails({
  addItemToTextEditor,
}: DecisionMatrixDetailsProps) {
  const [selectedConversation, setSelectedConversation] = useState<any>(null);
  const [allConversationsLoader, setAllConversationsLoader] = useState(false);
  const [aiChatsModal, setAiChatsModal] = useState(false);

  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { getAllConversationsRes } = useAppSelector((state) => state.chatGPT);
  const { currentAiAssistantTab } = useAppSelector(
    (state) => state.userAiAssistants
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setAllConversationsLoader(true);
          await dispatch(
            getAllConversations({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          setAllConversationsLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  }, [dispatch, getAccessTokenSilently]);

  const onOpenAIChatModal = () => {
    setAiChatsModal(true);
  };

  const onCloseAIChatModal = () => {
    setAiChatsModal(false);
  };

  const creatingConversation = (conversation_configuration_id: string) => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createConversation({
              body: {},
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
              conversation_configuration_id,
              template_type:
                (currentAiAssistantTab === 1 && "community") ||
                (currentAiAssistantTab === 2 && "private"),
            })
          ).then((res) => {
            onSelectedConversation(res?.payload?.data);
            onCloseAIChatModal();
          });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchData();
  };

  const deletingConversation = () => {
    const fetchData = async () => {
      setDeleteLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteConversations({
              accessToken,
              project_id: selectedData?.project_id,
              conversation_id: selectedData?.id,
            })
          );

          setDeleteLoader(false);
          closeConfirmationModal();
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setDeleteLoader(false);
        closeConfirmationModal();
      }
    };
    fetchData();
  };

  const deleteConfirmationModal = (e: any, data: any) => {
    e.stopPropagation();

    setSelectedData(data);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedData(null);
  };

  const onSelectedConversation = (data: any) => {
    setSelectedConversation(data);
  };

  return (
    <>
      {getAllConversationsRes?.length > 0 ? (
        selectedConversation ? (
          <div>
            <div className="flex items-center mb-3 border-b border-zinc-300 pb-4">
              <div
                onClick={() => onSelectedConversation(null)}
                className={`w-6 py-1 mr-3 rounded flex items-center justify-center cursor-pointer bg-transparent text-customDarkBlue hover:bg-zinc-100 hover:text-customDarkBlue `}
              >
                <ChevronLeftIcon className="h-4" />
              </div>

              <span className="text-xs text-customDarkBlue hover:text-customLightBlue">
                {selectedConversation && (
                  <DropdownSelectionOption
                    optionsList={getAllConversationsRes}
                    selected={selectedConversation}
                    onSelectAssistant={onSelectedConversation}
                    onAddingDocument={() => onOpenAIChatModal()}
                    btnText="Add Assistant"
                  />
                )}

                {/* <span className="mr-2 text-zinc-500 font-semibold">
                  ASSISTANT:
                </span>
                <span>{selectedConversation?.title}</span> */}
              </span>
            </div>
            <ChatGPT
              addItemToTextEditor={addItemToTextEditor}
              selectedConversation={selectedConversation}
            />
          </div>
        ) : (
          <WrapperLoader loading={allConversationsLoader}>
            <div className="p-0">
              <div className="mb-3">
                <h3 className=" text-md mb-2 pb-2 border-b border-zinc-100">
                  Ai Chats
                </h3>
                <div
                  onClick={() => onOpenAIChatModal()}
                  className="p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-amber-400"
                >
                  <RoundButton onClickBtn={() => onOpenAIChatModal()} />
                </div>
              </div>

              <div
                className="overflow-y-hidden hover:overflow-y-auto transition-all"
                style={{ height: "calc(100vh - 163px)" }}
              >
                <ul className="mt-3 grid grid-cols-1">
                  {getAllConversationsRes?.length > 0
                    ? getAllConversationsRes.map((data: any, index: number) => (
                        <ConversationBox
                          key={index}
                          data={data}
                          onDelete={(e: any) =>
                            deleteConfirmationModal(e, data)
                          }
                          deleteLoader={false}
                          selectedData={selectedData}
                          onSelectConversation={() =>
                            onSelectedConversation(data)
                          }
                          selectedConversation={selectedConversation}
                        />
                      ))
                    : ""}
                </ul>
              </div>
            </div>
          </WrapperLoader>
        )
      ) : (
        <div
          className="flex items-center justify-center home-empty"
          style={{ height: "calc(100vh - 165px)" }}
        >
          <CompleteEmptyPage
            title="You don't have any AI chats"
            subTitle="Get started by creating a new chat"
            btnText="New Chat"
            onClickBtn={onOpenAIChatModal}
            icon={aiSVG}
          />
        </div>
      )}

      {aiChatsModal && (
        <ChatAIModal
          onCloseModal={onCloseAIChatModal}
          openModal={aiChatsModal}
          onUseTemplate={creatingConversation}
        />
      )}

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingConversation}
        deleteLoader={deleteLoader}
      />
    </>
  );
}
