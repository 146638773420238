import React from "react";
import {
  TrashIcon,
} from "@heroicons/react/24/outline";
import WrapperLoader from "src/components/wrapperLoader";

interface ConversationBoxProps {
  data: any;
  onDelete: (e: any) => void;
  deleteLoader: boolean;
  selectedData: any;
  onSelectConversation: () => void;
  selectedConversation: any;
}

export default function ConversationBox({
  data,
  onDelete,
  deleteLoader,
  selectedData,
  onSelectConversation,
  selectedConversation,
}: ConversationBoxProps) {

  return (
    <li key={data?.id} className="mb-4 transition-all duration-500 ease-in-out">
      <div
        className={`rounded-md shadow-sm overflow-hidden border border-gray-200 bg-white hover:border-amber-400 hover:bg-amber-50`}
        onClick={onSelectConversation}
      >
        <WrapperLoader loading={selectedData?.id === data?.id && deleteLoader}>
          <div className={`flex items-center`}>
            <div className="flex flex-1 items-center justify-between truncate rounded-r-md cursor-pointer">
              <div className="flex-1 truncate px-4 py-2 text-xs">
                <div className="flex items-center">
                  <span className="font-medium text-xs text-gray-700 hover:text-customLightBlue">
                    {data?.title}
                  </span>
                </div>
              </div>
              <div className="flex-shrink-0 pr-4 flex items-center">
                <TrashIcon
                  onClick={onDelete}
                  className="h-4 w-4 cursor-pointer text-customLightBlue hover:text-customDarkBlue"
                />
              </div>
            </div>
          </div>
        </WrapperLoader>
      </div>
    </li>
  );
}
