import React from "react";
import FieldRow from "../../../../components/fieldRow";

interface DetailsOfAllDecisionsProps {
  detailsData: any;
}

export default function DetailsOfAllDecisions({
  detailsData,
}: DetailsOfAllDecisionsProps) {
  return (
    <div>
      <dl className="divide-y divide-gray-100">
        <FieldRow title="Title" value={detailsData?.title} />

        <FieldRow title="Description" value={detailsData?.description} />

        <FieldRow title="Status" value={detailsData?.status} />

        <FieldRow title="Category" value={detailsData?.category} />

        <FieldRow title="Assumptions" value={detailsData?.assumptions} />

        <FieldRow title="Implications" value={detailsData?.implications} />

        <FieldRow title="Final Decision" value={detailsData?.final_decision} />
      </dl>
    </div>
  );
}
