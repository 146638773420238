import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  PencilIcon,
  ArrowTopRightOnSquareIcon,
  TrashIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getProjectsList,
  getProjectById,
  createProject,
  updateProject,
  deleteProject,
} from "../../redux/projects/projectsApi";
import CustomDrawer from "../../components/customDrawer";
import CreationForm from "./CreationForm";
import WrapperLoader from "../../components/wrapperLoader";
import StatusLabel from "../../components/statusLabel";
import TableHeader from "../../components/tableHead";
import { tChartIcon } from "../../assets/icons";
import SearchField from "../../components/searchField";
import ConfirmationModal from "../../components/confirmationModal";
import TableTitle from "../../components/tableTitle";
import CompleteEmptyPage from "../../components/completeEmptyPage";
import TableColumn from "../../components/tableColumn";
import NewProjectButton from "../../components/newProjectButton";
import ComplexityStatus from "src/components/complexityStatus";
import CustomButton from "../../components/customButton";
import { setCollapseSidebar } from "../../redux/profile/profileSlice";

import designSVG from "../../assets/images/Illustration-07.svg";

interface Item {
  content: string;
  created_at: string;
  document_order: number;
  id: string;
  project_id: string;
  title: string;
  updated_at: string;
  user_id: string;
}

const Projects = () => {
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState<any>(null);
  const [formLoader, setFormLoader] = useState(false);
  const [projectByIdLoader, setProjectByIdLoader] = useState(false);
  const [projectsCompleteList, setProjectsCompleteList] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [confirmationLoader, setConfirmationLoader] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState<any>(null);
  const [draggedItem, setDraggedItem] = useState<Item | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { projectsList, projectsByIdRes, loadingProjects } = useAppSelector(
    (state) => state.projects
  );

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, item: Item) => {
    setDraggedItem(item);
  };

  const handleDragEnter = (
    e: React.DragEvent<HTMLDivElement>,
    targetItem: Item
  ) => {
    const newList = [...projectsCompleteList];
    const draggedIndex = projectsCompleteList.findIndex(
      (i: any) => i === draggedItem
    );
    const targetIndex = projectsCompleteList.findIndex(
      (i: any) => i === targetItem
    );

    // Swap the items
    if (draggedIndex !== -1 && targetIndex !== -1) {
      newList[draggedIndex] = targetItem;
      newList[targetIndex] = draggedItem as Item;
    }

    setProjectsCompleteList(newList);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) await dispatch(getProjectsList(accessToken));
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, dispatch]);

  useEffect(() => {
    setProjectsCompleteList(projectsList);
  }, [projectsList]);

  const onSearchingList = (value: string) => {
    setSearch(value);
    if (value) {
      const filteredResults = projectsList.filter((item: any) =>
        item?.title.toLowerCase().includes(value.toLowerCase())
      );

      setProjectsCompleteList(filteredResults);
    } else {
      setProjectsCompleteList(projectsList);
    }
  };

  const onOpenModal = () => {
    setOpen(true);
    setIsEditMode(false);
  };

  const onCloseModal = () => {
    setOpen(false);
    setIsEditMode(false);
  };

  const onOpenEditModal = (id: string) => {
    setIsEditMode(true);
    setOpen(true);
    gettingProjectById(id);
  };

  const updatingProject = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateProject({
              body,
              accessToken,
              id: projectsByIdRes?.id,
            })
          );
          onCloseModal();
          setFormLoader(false);

          await dispatch(getProjectsList(accessToken));
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingProject = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createProject({ body, accessToken }));
          onCloseModal();
          setFormLoader(false);

          await dispatch(getProjectsList(accessToken));
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitProject = async (body: any) => {
    if (isEditMode) {
      updatingProject(body);
    } else {
      creatingProject(body);
    }
  };

  const gettingProjectById = (id: string) => {
    const fetchData = async () => {
      setProjectByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) await dispatch(getProjectById({ accessToken, id }));
        setProjectByIdLoader(false);
      } catch (error) {
        console.error("Error getting access token:", error);
        setProjectByIdLoader(false);
      }
    };

    fetchData();
  };

  const deletingProject = () => {
    const fetchData = async () => {
      setConfirmationLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(deleteProject({ accessToken, id: selectedId }));
          setConfirmationLoader(false);
          deletingModalClose();

          await dispatch(getProjectsList(accessToken));
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setConfirmationLoader(false);
      }
    };

    fetchData();
  };

  const deletingModalOpen = (id: string) => {
    setDeleteConfirm(true);
    setSelectedId(id);
  };

  const deletingModalClose = () => {
    setDeleteConfirm(false);
    setSelectedId(null);
  };

  return (
    <>
      <WrapperLoader loading={loadingProjects}>
        <div>
          {projectsList?.length > 0 && (
            <div className="py-5 border-b border-gray-200 text-right">
              <div className="px-6 flex items-center justify-between">
                <div className="w-80">
                  <SearchField
                    onSearchField={onSearchingList}
                    searchValue={search}
                  />
                </div>

                <CustomButton
                  text="New Project"
                  type="button"
                  onClickBtn={() => onOpenModal()}
                  beforeIcon={<PlusIcon className="h-4 mr-1" />}
                />
              </div>
            </div>
          )}

          <div className="px-6 pb-6">
            {projectsCompleteList?.length > 0 && (
              <div className="low-root">
                <div className="overflow-x-auto sm:-mx-6">
                  <div className="inline-block min-w-full align-middle pb-4 sm:px-2 overflow-y-auto h-[calc(100vh_-_122px)]">
                    <table className="min-w-full bg-white overflow-hidden">
                      <thead>
                        <tr>
                          <TableHeader name="Title" />
                          <TableHeader name="Description" />
                          <TableHeader name="Status" />
                          <TableHeader name="Category" />
                          <TableHeader name="Complexity" />
                          <TableHeader name="Actions" />
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {projectsCompleteList?.map((item: any) => (
                          <tr
                            key={item?.tbar_analysis?.id}
                            className="cursor-move hover:bg-blue-100"
                            onClick={() => {
                              navigate(`/projects/${item?.id}?active=documents`);
                              dispatch(setCollapseSidebar(true));
                            }}
                            draggable
                            onDragStart={(e) => handleDragStart(e, item)}
                            onDragEnter={(e) => handleDragEnter(e, item)}
                            onDragEnd={handleDragEnd}
                          >
                            <TableColumn>
                              <TableTitle
                                title={item?.title}
                                status={item?.status}
                                created_at={item?.created_at}
                                updated_at={item?.updated_at}
                              />
                            </TableColumn>

                            <TableColumn>
                              <div className="text-xs line-clamp-2">
                                {item?.description}
                              </div>
                            </TableColumn>

                            <TableColumn>
                              <div className="text-xs text-customLightBlue">
                                <StatusLabel status={item?.status} />
                              </div>
                            </TableColumn>

                            <TableColumn>
                              <div className="text-xs text-customLightBlue">
                                {item?.category}
                              </div>
                            </TableColumn>

                            <TableColumn>
                              <div className="text-xs text-customLightBlue">
                                <ComplexityStatus status={item?.complexity} />
                              </div>
                            </TableColumn>

                            <TableColumn>
                              <div className="relative text-right text-xs font-medium">
                                <div className="flex-shrink-0 flex items-center">
                                  <PencilIcon
                                    className="h-5 w-5 mr-1 cursor-pointer text-slate-400 hover:text-blue-900"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onOpenEditModal(item?.id);
                                    }}
                                  />

                                  <TrashIcon
                                    className="h-5 w-5 cursor-pointer text-slate-400 hover:text-red-900"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      deletingModalOpen(item?.id);
                                    }}
                                  />
                                </div>
                              </div>
                            </TableColumn>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {(projectsList?.length === 0 || !projectsList) && (
              <div
                className="flex items-center justify-center"
                style={{ height: "calc(100vh - 140px)" }}
              >
                <div>
                  <CompleteEmptyPage
                    title="Get started by creating a new project"
                    subTitle="Side by side with AI create high quality documentation for any project, process, product or IT Design. You can create a new project from scratch by clicking the button below or leverage the templates created our team or by the community."
                    btnText="new"
                    icon={designSVG}
                  />

                  <div className="flex items-center justify-center relative mt-4">
                    <NewProjectButton />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </WrapperLoader>
      <CustomDrawer
        open={open}
        onCloseModal={onCloseModal}
        title={
          <div className="flex items-center">
            <span className="mr-2">{tChartIcon}</span>
            <span>Project</span>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer"
              className="text-xs text-yellow-500 hover:text-yellow-800 flex items-center font-semibold ml-3"
            >
              <span className="mr-1">Learn More</span>
              <ArrowTopRightOnSquareIcon className="h-3" />
            </a>
          </div>
        }
        description={
          isEditMode
            ? "Update Project"
            : "Create new Project, provide Title, Status and Category. Title is mandatory field."
        }
      >
        <WrapperLoader loading={projectByIdLoader}>
          <CreationForm
            onCloseModal={onCloseModal}
            editData={projectsByIdRes}
            onSubmitForm={onSubmitProject}
            loader={formLoader}
            editMode={isEditMode}
          />
        </WrapperLoader>
      </CustomDrawer>

      <ConfirmationModal
        open={deleteConfirm}
        closingModal={() => deletingModalClose()}
        onDeleting={deletingProject}
        deleteLoader={confirmationLoader}
      />
    </>
  );
};

export default Projects;
