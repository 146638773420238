// amazon_aws.js

export const devops_nodes = [
  {
    name: "Other",
    items: [
      "icons8-github",
      "jenkins",
      "pipeline",
      "release-pipeline"
    ],
  },
];