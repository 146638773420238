import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import type { AiSuggestionsFetcher } from './autoSuggestions';
import { promptMessage } from 'src/redux/chatGPT/chatGPTApi';
import { useAuth0 } from '@auth0/auth0-react';

export function useAiSuggestionsFetcher(): AiSuggestionsFetcher | null {
    const currControllerRef = useRef<AbortController | null>(null);
    const projectId = useAppSelector((state) => state.projects.selectedDocument?.project_id);
    const aiConversationId = useAppSelector((state) => state.projects.selectedDocument?.ai_conversation_id);
    const dispatch = useAppDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const cancelPendingFetch = useCallback(() => {
        currControllerRef.current?.abort();
        currControllerRef.current = null;
    }, []);

    useEffect(() => {
        return cancelPendingFetch;
    }, []);


    return useMemo(() => {
        if (!projectId || !aiConversationId) return null;

        const fetch = async (serializedHTML: string): Promise<string[]> => {
            cancelPendingFetch();

            const accessToken = await getAccessTokenSilently();
            currControllerRef.current = new AbortController();

            try {
                const {
                    payload: {
                        data: { completion },
                    },
                } = await dispatch(
                    promptMessage({
                        body: {
                            messages: [{ role: 'user', content: serializedHTML }],
                        },
                        accessToken,
                        project_id: projectId,
                        conversation_id: aiConversationId,
                        signal: currControllerRef.current.signal,
                    })
                );

                const response = completion?.completion_response ?? '';
                const suggestions = JSON.parse(response.replace(/^```json/, '').replace(/```$/, ''));

                if (!Array.isArray(suggestions)) {
                    throw new Error('Invalid response from AI');
                }

                return suggestions;
            } catch (error) {
                const isAbortError = error instanceof Error && error.name === 'AbortError';

                if (!isAbortError) {
                    console.error('Error while fetching ai suggestions: ', error);
                }

                return [];
            }
        };

        return Object.assign(fetch, { cancel: cancelPendingFetch });
    }, [projectId, aiConversationId, cancelPendingFetch]);
}
