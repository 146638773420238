import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  EllipsisVerticalIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  timeAgoOrFormattedDate,
  getLastIdFromUrl,
} from "../../utils/utilities";
import {
  updatePublicComment,
  deletePublicComment,
} from "../../redux/comments/commentsApi";
import CustomButton from "../customButton";
import ConfirmationModal from "../confirmationModal";
import ItemWithDropdown from "../itemWithDropdown";

const profileImg =
  "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80";

export default function CommentBox({ item }: any) {
  const [isEdit, setIsEdit] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [formLoader, setFormLoader] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useAppDispatch();
  const commentBoxRef = useRef<HTMLDivElement>(null);
  const {profileData} = useAppSelector<any>(state => state.profile);

  useEffect(() => {
    setCommentText(item.comment);
  }, [item]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        commentBoxRef.current &&
        !commentBoxRef.current.contains(event.target as Node)
      ) {
        setIsEdit(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [commentBoxRef]);

  const onUpdatingComment = (comment_id: string) => {
    if (commentText) {
      const fetchData = async () => {
        setFormLoader(true);
        try {
          const accessToken = await getAccessTokenSilently();
          if (accessToken) {
            const payload = {
              comment: commentText,
            };

            await dispatch(
              updatePublicComment({
                body: payload,
                accessToken,
                comment_id,
                template_id: getLastIdFromUrl(window.location.pathname),
              })
            )
              .then((res) => {
                if (res) {
                  setFormLoader(false);
                  setCommentText("");
                  setIsEdit(false);
                }
              })
              .catch((err) => {
                setFormLoader(false);
              });
          }
        } catch (error) {
          console.error("Error getting access token:", error);
          setFormLoader(false);
        }
      };

      fetchData();
    }
  };

  const onTypingComment = (e: any, comment_id: string) => {
    const value = e.target.value;
    setCommentText(value);

    if (e.key === "Enter" && !e.shiftKey && value) {
      onUpdatingComment(comment_id);
    }
  };

  const onUpdateComment = (comment_id: string) => {
    onUpdatingComment(comment_id);
  };

  const onDeletingComment = () => {
    if (commentText) {
      const fetchData = async () => {
        setDeleteLoader(true);

        try {
          const accessToken = await getAccessTokenSilently();
          if (accessToken) {
            const payload = {
              comment: commentText,
            };

            await dispatch(
              deletePublicComment({
                body: payload,
                accessToken,
                comment_id: item?.id,
                template_id: getLastIdFromUrl(window.location.pathname),
              })
            )
              .then((res) => {
                if (res) {
                  setDeleteLoader(false);
                  setDeleteConfirmation(false);
                }
              })
              .catch((err) => {
                setDeleteLoader(false);
              });
          }
        } catch (error) {
          console.error("Error getting access token:", error);
          setDeleteLoader(false);
        }
      };

      fetchData();
    }
  };

  const actionsList = [
    {
      id: 1,
      name: "Edit",
      onActionClick: () => setIsEdit(true),
      icon: (
        <PencilSquareIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
      ),
    },
    {
      id: 3,
      name: "Delete",
      onActionClick: () => setDeleteConfirmation(true),
      icon: (
        <TrashIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
      ),
    },
  ];

  return (
    <>
      <img
        src={profileData?.id === item?.user_id ? user?.picture : profileImg}
        alt={user?.email}
        className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
      />
      <div className="bg-white flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
        <div className="flex justify-between gap-x-4">
          <div className="py-0.5 text-xs leading-5 text-customLightBlue">
            <span className="font-medium text-customDarkBlue">User</span>{" "}
            commented
          </div>
          <time
            dateTime={item.dateTime}
            className="flex-none py-0.5 text-xs leading-5 text-customLightBlue"
          >
            {timeAgoOrFormattedDate(item.created_at)}
          </time>
        </div>

        <div className="flex items-center justify-between">
          {isEdit ? (
            <form action="#" className="relative flex-auto">
              <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                <label htmlFor="comment" className="sr-only">
                  Add your comment
                </label>
                <textarea
                  rows={2}
                  name="comment"
                  id="comment"
                  className="block w-full resize-none border-0 bg-transparent py-1.5 text-customDarkBlue placeholder:text-gray-400 focus:ring-0 sm:text-xs sm:leading-6"
                  placeholder="Add your comment..."
                  onChange={(e: any) => onTypingComment(e, item.id)}
                  value={commentText}
                  onKeyDown={(e: any) => onTypingComment(e, item.id)}
                />
              </div>

              <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                <div className="flex items-center space-x-5" />
                <div>
                  <CustomButton
                    text="Cancel"
                    type="button"
                    onClickBtn={() => setIsEdit(false)}
                    btnStyle="bg-zinc-50 text-zinc-800 mr-3 hover:bg-zinc-100"
                  />

                  <CustomButton
                    text="Update"
                    type="button"
                    onClickBtn={() => onUpdateComment(item.id)}
                    loading={formLoader}
                  />
                </div>
              </div>
            </form>
          ) : (
            <p className="text-xs leading-6 text-customLightBlue">
              {item.comment}
            </p>
          )}

          {!isEdit && (
            <>
              {profileData?.id === item?.user_id && (
                <ItemWithDropdown
                  actionsData={actionsList}
                  menuPosition="bottom-[40px]"
                >
                  <EllipsisVerticalIcon
                    className="h-4 w-4"
                    aria-hidden="true"
                  />
                </ItemWithDropdown>
              )}
            </>
          )}
        </div>
      </div>

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => setDeleteConfirmation(false)}
        onDeleting={onDeletingComment}
        deleteLoader={deleteLoader}
      />
    </>
  );
}
