import React from "react";

interface Props {
  htmlString: string;
}

const HtmlRenderer: React.FC<Props> = ({ htmlString }) => {
  return (
    <>
      <style>
        {`
          /* Styling for headings */
          h1, h2, h3, h4, h5, h6 {
            font-weight: bold;
            color: #333;
            margin-bottom: 20px;
          }
          h1 { font-size: 22px; }
          h2 { font-size: 18px; }
          h3 { font-size: 16px; }
          h4 { font-size: 14px; }
          h5 { font-size: 12px; }
          h6 { font-size: 10px; }

          /* Styling for paragraphs */
          p {
            font-size: 12px;
            line-height: 1.5;
            color: #666;
          }

          /* Styling for links */
          a {
            color: #007bff;
            text-decoration: none;
          }
          a:hover {
            text-decoration: underline;
          }

          /* Styling for lists */
          ul, ol {
            padding-left: 20px;
            color: #666;
          }
          li {
            margin-bottom: 5px;
          }

          /* Styling for tables */
          table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
          }
          th, td {
            border: 1px solid #ccc;
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f2f2f2;
          }

          /* Styling for images */
          img {
            max-width: 100%;
            height: auto;
          }

          /* Styling for forms */
          input, textarea, select {
            width: 100%;
            padding: 8px;
            margin: 5px 0;
            box-sizing: border-box;
          }
          button {
            padding: 10px 20px;
            background-color: #007bff;
            color: white;
            border: none;
            cursor: pointer;
          }
          button:hover {
            background-color: #0056b3;
          }
        `}
      </style>
      <div dangerouslySetInnerHTML={{ __html: htmlString }} />
    </>
  );
};

export default HtmlRenderer;
