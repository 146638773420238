import React, { InputHTMLAttributes } from "react";

interface CommonInputProps {
  type: string;
  id: string;
  error?: boolean;
}

type InputFieldProps = CommonInputProps & InputHTMLAttributes<HTMLInputElement>;

const InputField: React.FC<InputFieldProps> = ({
  type,
  id,
  error,
  ...rest
}) => {
  return (
    <input
      type={type}
      id={id}
      className={`block w-full rounded-md border-0 py-1.5 text-customDarkBlue shadow-sm ring-1 ring-inset bg-gray-100 ${
        error ? "ring-red-600" : "ring-gray-300"
      } placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
        error ? "focus:ring-red-600" : "focus:ring-blue-600"
      }`}
      {...rest}
    />
  );
};

export default InputField;
