import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  DocumentTextIcon,
  PlusCircleIcon
} from "@heroicons/react/20/solid";
import { classNames } from "../../utils/utilities";

interface DropdownSelectionOptionProps {
  optionsList: any;
  selected: any;
  setSelected?: Function;
  onAddingDocument?: any;
  btnText: string;
  onSelectAssistant?: Function;
}

export default function DropdownSelectionOption({
  optionsList,
  selected,
  setSelected,
  onAddingDocument,
  btnText,
  onSelectAssistant,
}: DropdownSelectionOptionProps) {
  return (
    <Listbox
      value={selected}
      onChange={
        setSelected ? (e: any) => setSelected(e) : () => console.log("....")
      }
    >
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button className="relative cursor-pointer rounded-md text-left text-customLightBlue  sm:text-xs sm:leading-6">
              <div className="flex items-center text-xs">
                <span className="block truncate line-clamp-1">
                  {selected?.title || "Loading..."}
                </span>
                <ChevronDownIcon className="h-5 w-5 ml-2 text-gray-400" />
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute w-[350px] z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white p-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
                {optionsList?.map((item: any) => (
                  <Listbox.Option
                    onClick={
                      onSelectAssistant
                        ? () => onSelectAssistant(item)
                        : () => console.log("....")
                    }
                    key={item.id}
                    className={({ active }) =>
                      classNames(
                        selected?.id === item?.id
                          ? "bg-zinc-100 text-customDarkBlue"
                          : "text-customDarkBlue",
                        "relative cursor-pointer select-none py-2 pl-8 pr-4 rounded-md hover:bg-zinc-100"
                      )
                    }
                    value={item}
                  >
                    <>
                      <span
                        className={classNames(
                          selected?.id === item?.id
                            ? "font-semibold"
                            : "font-normal",
                          "truncate flex items-center"
                        )}
                      >
                        <div className="w-4 mr-2">
                          <DocumentTextIcon
                            className={`h-4 w-4 ${selected?.id === item?.id ? "text-customLightBlue" : "text-zinc-400"} `}
                          />
                        </div>
                        <span className="line-clamp-1">{item.title}</span>
                      </span>

                      {selected?.id === item?.id ? (
                        <span
                          className={classNames(
                            selected?.id === item?.id
                              ? "text-amber-600"
                              : "text-customLightBlue",
                            "absolute inset-y-0 left-0 flex items-center pl-1.5"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  </Listbox.Option>
                ))}

                {onAddingDocument && (
                  <>
                    <hr className="my-1 opacity-60" />

                    <div
                      className={classNames(
                        selected ? "font-semibold" : "font-normal",
                        "truncate flex items-center bg-white py-3 pl-8 pr-4 cursor-pointer hover:bg-zinc-100 rounded-md"
                      )}
                      onClick={() => onAddingDocument()}
                    >
                      <PlusCircleIcon
                        className={`h-4 w-4 mr-2 text-zinc-500`}
                      />
                      <span>{btnText}</span>
                    </div>
                  </>
                )}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
