import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import InputField from "../../../components/formFields/inputField";
import CustomButton from "../../../components/customButton";
import SelectField from "../../../components/formFields/selectField";
import TextAreaField from "../../../components/formFields/textareaField";
import { categoriesList } from "../../../utils/utilities";

interface TBarFormProps {
  onCloseModal?: any;
  editData: any;
  onSubmitForm: Function;
  loader: boolean;
  editMode: boolean;
  isProjectDetail?: boolean;
  documentsList?: any;
}

export default function TBarForm({
  onCloseModal,
  editData,
  onSubmitForm,
  loader,
  editMode,
  isProjectDetail,
  documentsList,
}: TBarFormProps) {
  const validationObject = {
    title: yup.string().required("Title is required"),
    status: yup.string().required("Status is required"),
    category: yup.string().required("Category is required"),
    complexity: yup.string().required("Complexity is required"),
    description: yup.string().required("Description is required"),
  };

  const validationSchema = yup.object().shape(validationObject);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: editMode ? editData?.title : "",
      status: editMode ? editData?.status : "Not Started",
      category: editMode ? editData?.category : "Personal",
      complexity: editMode ? editData?.complexity : "low",
      description: editMode ? editData?.description : "",
    },
    validationSchema,
    onSubmit: (values) => {
      const payloadObj = {
        title: values?.title,
        status: values?.status,
        category: values?.category,
        complexity: values?.complexity,
        description: values?.description,
        autosave: editData?.autosave || false
      };

      onSubmitForm(payloadObj);
    },
  });

  const complexityList = [
    { value: "low" },
    { value: "medium" },
    { value: "complex" },
  ];

  const processList = [
    { value: "Not Started" },
    { value: "In Progress" },
    { value: "Concluded" },
    { value: "Canceled" },
  ];

  const eachFieldRow =
    "items-start space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 border-b border-gray-200";

  return (
    <form
      className={`flex flex-col bg-white ${isProjectDetail ? "" : "shadow-xl"}`}
      onSubmit={formik.handleSubmit}
    >
      {/* container */}
      <div
        className={isProjectDetail ? "" : "overflow-y-scroll"}
        style={{
          height: isProjectDetail
            ? "100%"
            : editMode
              ? "calc(100vh - 150px)"
              : "calc(100vh - 188px)",
        }}
      >
        {/* Title */}
        <div className={eachFieldRow}>
          <div className="flex items-center">
            <label
              htmlFor="title"
              className="block mr-3 text-xs font-medium leading-6 text-customDarkBlue"
            >
              Title
            </label>
          </div>
          <div className="sm:col-span-2">
            <InputField
              type="text"
              id="title"
              {...formik.getFieldProps("title")}
              placeholder="Enter project name"
            />

            {formik.touched.title && formik.errors.title ? (
              <div className="text-red-600">{formik.errors.title}</div>
            ) : null}
          </div>
        </div>

        {/* Status */}
        <div className={eachFieldRow}>
          <div>
            <label
              htmlFor="status"
              className="block text-xs font-medium leading-6 text-customDarkBlue"
            >
              Status
            </label>
          </div>
          <div className="sm:col-span-2">
            <SelectField
              options={processList}
              id="status"
              {...formik.getFieldProps("status")}
            />

            {formik.touched.status && formik.errors.status ? (
              <div className="text-red-600">{formik.errors.status}</div>
            ) : null}
          </div>
        </div>

        {/* Category */}
        <div className={eachFieldRow}>
          <div>
            <label
              htmlFor="category"
              className="block text-xs font-medium leading-6 text-customDarkBlue"
            >
              Category
            </label>
          </div>
          <div className="sm:col-span-2">
            <SelectField
              options={categoriesList}
              id="category"
              {...formik.getFieldProps("category")}
            />

            {formik.touched.category && formik.errors.category ? (
              <div className="text-red-600">{formik.errors.category}</div>
            ) : null}
          </div>
        </div>

        {/* Complexity */}
        <div className={eachFieldRow}>
          <div className="flex items-center">
            <label
              htmlFor="complexity"
              className="block text-xs font-medium leading-6 text-customDarkBlue"
            >
              Complexity
            </label>
          </div>

          <div className="sm:col-span-2">
            <SelectField
              options={complexityList}
              id="complexity"
              {...formik.getFieldProps("complexity")}
            />

            {formik.touched.complexity && formik.errors.complexity ? (
              <div className="text-red-600">{formik.errors.complexity}</div>
            ) : null}
          </div>
        </div>

        {/* Description */}
        <div className={eachFieldRow}>
          <div className="flex items-center">
            <label
              htmlFor="description"
              className="block text-xs font-medium leading-6 text-customDarkBlue"
            >
              Description
            </label>
          </div>
          <div className="sm:col-span-2">
            <TextAreaField
              id="description"
              {...formik.getFieldProps("description")}
              placeholder="Enter a brief description."
            />

            {formik.touched.description && formik.errors.description ? (
              <div className="text-red-600">{formik.errors.description}</div>
            ) : null}
          </div>
        </div>

        {/* Documents List */}
        {documentsList?.length > 0 && (
          <div className="px-4 mb-8 sm:px-6 sm:py-5">
            <div className="block text-xs font-medium leading-6 text-customDarkBlue sm:mt-1.5 mb-3">
              Documents List
            </div>
            {documentsList.map((data: any) => (
              <div
                key={data.id}
                className="group text-xs flex items-center mb-4 hover:text-amber-500"
              >
                <DocumentTextIcon className="mr-2 h-4 text-zinc-500 group-hover:text-amber-500" />
                <span>{data.title}</span>
              </div>
            ))}
          </div>
        )}
      </div>

      {isProjectDetail ? (
        <div className="pl-6 py-5 text-right">
          <CustomButton
            type="submit"
            onClickBtn={() => console.log("Clicking...")}
            text="Save"
            loading={loader}
          />
        </div>
      ) : (
        <div className="fixed bg-white bottom-0 w-[530px] border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex justify-end space-x-3">
            {onCloseModal && (
              <button
                type="button"
                className="rounded-md bg-white px-3 py-2 text-xs font-semibold text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={onCloseModal}
              >
                Cancel
              </button>
            )}

            <CustomButton
              type="submit"
              onClickBtn={() => console.log("Clicking...")}
              text="Save"
              loading={loader}
            />
          </div>
        </div>
      )}

      {/* Action buttons */}
    </form>
  );
}
