import React, { useEffect, useState } from "react";
import CustomButton from "src/components/customButton";
import InputField from "../../../../../components/formFields/inputField";
import SelectField from '../../../../../components/formFields/selectField';

interface DocumentProps {
  onCloseModal: () => void;
  loading: boolean;
  onSubmitData: Function;
  editData: any;
}

export default function Document({
  onCloseModal,
  loading,
  onSubmitData,
  editData,
}: DocumentProps) {
  const [title, setTitle] = useState("");
  const [isTitle, setIsTitle] = useState(false);
  const [complexity, setComplexity] = useState('low');

  useEffect(() => {
    if (editData) {
      if (editData?.title) setTitle(editData?.title);
      if (editData?.complexity) setComplexity(editData?.complexity);
    }
  }, [editData]);

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    const payload = {
      title: title,
      content: `<h1>${title}</h1>`,
      complexity,
    };

    if (title) {
      onSubmitData(payload);
    } else {
      setIsTitle(true);
    }
  };

  const onChangeTitle = (value: string) => {
    setTitle(value);
    setIsTitle(false);
  };

  const complexityList = [
    { value: "low" },
    { value: "medium" },
    { value: "complex" },
  ];

  return (
    <form className="flex h-full flex-col" onSubmit={onSubmitForm}>
      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mb-3">
        <div>
          <label className="block text-xs font-medium leading-6 text-customDarkBlue">
            Title
          </label>
        </div>
        <div className="sm:col-span-2">
          <InputField
            type="text"
            id="title"
            error={isTitle}
            name="title"
            value={title}
            onChange={(e) => onChangeTitle(e.target.value)}
          />
        </div>
      </div>

      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
        <div>
          <label className="block text-xs font-medium leading-6 text-customDarkBlue">
            Complexity
          </label>
        </div>
        <div className="sm:col-span-2">
          <SelectField
            options={complexityList}
            value={complexity}
            id="complexity"
            onChange={(e) => setComplexity(e.target.value)}
            rest={undefined}
          />
        </div>
      </div>

      {/* Action buttons */}
      <div className="w-full text-right mt-5">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-xs font-semibold text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-2"
          onClick={onCloseModal}
        >
          Cancel
        </button>
        <CustomButton
          text="Save"
          type="submit"
          onClickBtn={() => console.log()}
          loading={loading}
        />
      </div>
    </form>
  );
}
