import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/customButton";
import InputField from "../../../components/formFields/inputField";

interface ProjectAsTemplateFormProps {
  onCloseModal: () => void;
  loading: boolean;
  onSubmitData: Function;
  templateType: string;
  titleValue: string;
}

export default function ProjectAsTemplateForm({
  onCloseModal,
  loading,
  onSubmitData,
  templateType,
  titleValue,
}: ProjectAsTemplateFormProps) {
  const [title, setTitle] = useState("");
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setTitle(titleValue);
  }, [titleValue]);

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    if (title?.length > 0) {
      onSubmitData(title, templateType);
    } else {
      setShowError(true);
    }
  };

  const onChangeTitle = (e: any) => {
    const value = e.target.value;
    setTitle(value);
    setShowError(false);
  };

  return (
    <form className="flex h-full flex-col" onSubmit={onSubmitForm}>
      {/* Title */}
      <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 border-b border-gray-200">
        <div>
          <label
            htmlFor="title"
            className="block text-xs font-medium leading-6 text-customDarkBlue"
          >
            Title
          </label>
        </div>
        <div className="sm:col-span-2">
          <InputField
            type="text"
            id="title"
            placeholder="Enter template title"
            value={title}
            onChange={(e: any) => onChangeTitle(e)}
            error={showError}
          />
        </div>
      </div>

      {/* Action buttons */}
      <div className="w-full text-right mt-5">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-xs font-semibold text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-2"
          onClick={onCloseModal}
        >
          Cancel
        </button>

        <CustomButton
          text="Save"
          type="submit"
          onClickBtn={() => console.log()}
          loading={loading}
        />
      </div>
    </form>
  );
}
