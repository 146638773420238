import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface CustomDrawerProps {
	open: boolean;
	onCloseModal: () => void;
	title: any;
	description: string;
	children: ReactNode;
}

export default function CustomDrawer({
	open,
	onCloseModal,
	title,
	description,
	children,
}: CustomDrawerProps) {
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as='div' className='relative z-50' onClose={onCloseModal}>
				<Transition.Child
					as={Fragment}
					enter='ease-in-out duration-500'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in-out duration-500'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-hidden'>
					<div className='absolute inset-0 overflow-hidden'>
						<div className='pointer-events-none fixed inset-y-0 right-0 flex pl-10' style={{ maxWidth: "570px", width: '100%' }}>
							<Transition.Child
								as={Fragment}
								enter='transform transition ease-in-out duration-500 sm:duration-700'
								enterFrom='translate-x-full'
								enterTo='translate-x-0'
								leave='transform transition ease-in-out duration-500 sm:duration-700'
								leaveFrom='translate-x-0'
								leaveTo='translate-x-full'>
								<Dialog.Panel className='pointer-events-auto relative w-full'>
									{/* Header */}
									<div className='bg-gray-50 px-4 py-6 sm:px-6'>
										<div className='flex items-start justify-between space-x-3'>
											<div className='space-y-1'>
												<Dialog.Title className='text-base font-semibold leading-6 text-customDarkBlue'>
													{title}
												</Dialog.Title>
												<p className='text-xs text-customLightBlue'>{description}</p>
											</div>
											<div className='flex h-7 items-center'>
												<button
													type='button'
													className='relative text-gray-400 hover:text-customLightBlue'
													onClick={onCloseModal}>
													<span className='absolute -inset-2.5' />
													<span className='sr-only'>Close panel</span>
													<XMarkIcon className='h-6 w-6' aria-hidden='true' />
												</button>
											</div>
										</div>
									</div>
									{children}
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
