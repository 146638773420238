import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  deleteRequest,
} from "../../utils/api";

export const getTemplatesList = createAsyncThunk(
  "getTemplatesList/get",
  async (accessToken: string) => {
    try {
      const response = await getRequest({
        url: "/projectTemplates",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getTemplateById = createAsyncThunk(
  "getTemplateById/get",
  async ({ accessToken, id }: any) => {
    try {
      const response = await getRequest({
        url: `/projectTemplate?id=${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createTemplate = createAsyncThunk(
  "createTemplate/Create",
  async ({ body, accessToken, id }: any) => {
    try {
      const response = await postRequest({
        url: `/projectTemplate?id=${id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  "deleteTemplate/Delete",
  async ({ accessToken, id }: any) => {
    try {
      const response = await deleteRequest({
        url: `/projectTemplate?id=${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createProjectFromTemplate = createAsyncThunk(
  "createProjectFromTemplate/Create",
  async ({ body, accessToken, id }: any) => {
    try {
      const response = await postRequest({
        url: `/projectFromTemplate?template_id=${id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createProjectFromPublicTemplate = createAsyncThunk(
  "createProjectFromPublicTemplate/Create",
  async ({ body, accessToken, id }: any) => {
    try {
      const response = await postRequest({
        url: `/pub/projectFromPubTemplate?public_template_id=${id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getPublicTemplatesList = createAsyncThunk(
  "getPublicTemplatesList/get",
  async ({accessToken, query}: any) => {
    try {
      const response = await getRequest({
        url: `/publicProjectTemplates${query}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getPublicTemplateById = createAsyncThunk(
  "getPublicTemplateById/get",
  async ({ accessToken, id }: any) => {
    try {
      const response = await getRequest({
        url: `/publicProjectTemplate?public_template_id=${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const publishProjectTemplate = createAsyncThunk(
  "publishProjectTemplate/Create",
  async ({ body, accessToken, id }: any) => {
    try {
      const response = await postRequest({
        url: `/publishProjectTemplate?template_id=${id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const unPublishProjectTemplate = createAsyncThunk(
  "unPublishProjectTemplate/Create",
  async ({ accessToken, id }: any) => {
    try {
      const response = await postRequest({
        url: `/unpublishProjectTemplate?template_id=${id}`,
        payload: {},
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getPrivateTemplateDocument = createAsyncThunk(
  "getPrivateTemplateDocument/get",
  async ({ accessToken, template_id, template_document_id }: any) => {
    try {
      const response = await getRequest({
        url: `/documentTemplate?template_id=${template_id}&template_document_id=${template_document_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

// Only Documents APIs

export const getPublicDocumentsTemplatesList = createAsyncThunk(
  "getPublicDocumentsTemplatesList/get",
  async ({ accessToken, query }: any) => {
    try {
      const response = await getRequest({
        url: `/publicDocumentTemplates${query}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getPublicTemplateDocument = createAsyncThunk(
  "getPublicTemplateDocument/get",
  async ({ accessToken, template_id, template_document_id }: any) => {
    try {
      const response = await getRequest({
        url: `/publicProjectDocumentTemplate?cm_template_id=${template_id}&cm_template_document_id=${template_document_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getPrivateDocumentsTemplatesList = createAsyncThunk(
  "getPrivateDocumentsTemplatesList/get",
  async ({ accessToken }: any) => {
    try {
      const response = await getRequest({
        url: `/idt/documentTemplates`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getPrivateDocumentTemplate = createAsyncThunk(
  "getPrivateDocumentTemplate/get",
  async ({ accessToken, document_id }: any) => {
    try {
      const response = await getRequest({
        url: `/idt/documentTemplate?id=${document_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createDocumentFromPublicTemplate = createAsyncThunk(
  "createDocumentFromPublicTemplate/Create",
  async ({ accessToken, project_id, document_id }: any) => {
    try {
      const response = await postRequest({
        url: `/pub/documentFromPubTemplate?project_id=${project_id}&public_document_template_id=${document_id}`,
        payload: {},
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createDocumentFromPrivateTemplate = createAsyncThunk(
  "createDocumentFromPrivateTemplate/Create",
  async ({ accessToken, project_id, document_id }: any) => {
    try {
      const response = await postRequest({
        url: `/documentFromTemplate?project_id=${project_id}&document_template_id=${document_id}`,
        payload: {},
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);