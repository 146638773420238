import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import {
  InformationCircleIcon,
  LinkIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";
import CustomButton from "../customButton";
import { useAppSelector, useAppDispatch } from "../../hooks";
import Discussions from "../Comments";
import HTMLContentModal from "./HTMLContentModal";
import { getUserAiAssistant, getPublicAiAssistant } from "../../redux/userAiAssistants/userAiAssistantsApi";
import { getFirstPathFromUrl, getLastIdFromUrl } from "../../utils/utilities";

import chatGPT from "../../assets/images/chatgpt_logo.jpg";
import twitterIcon from "../../assets/images/twitter-logo.png";
import linkedInIcon from "../../assets/images/linkedin-logo.png";
import WrapperLoader from "../wrapperLoader";

const tabsList = [
  { id: 1, title: "Detials" },
  { id: 2, title: "Discussion" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface AIChatTempDetailProps {
  onCancel: Function;
  onUse: Function;
  isModal: boolean;
  tempType?: string;
  showTempDetail?: any;
}

export default function AIChatTempDetail({
  onCancel,
  onUse,
  isModal,
  tempType,
  showTempDetail,
}: AIChatTempDetailProps) {
  const [selectedTab, setSelectedTab] = useState(1);
  const [isOpenHTML, setIsOpenHTML] = useState(false);
  const [detailLoader, setDetailLoader] = useState(false);
  const [aiDetail, setAiDetail] = useState<any>(null);

  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { profileData } = useAppSelector<any>((state: any) => state.profile);
  const { getUserAiAssistantRes, getPublicAiAssistantRes } = useAppSelector(
    (state) => state.userAiAssistants
  );

  const firstPath = getFirstPathFromUrl(window.location.pathname);

  const configurationsList = [
    {
      id: 1,
      title: "Temperature",
      value: aiDetail?.configuration?.ai_temperature,
    },
    {
      id: 2,
      title: "Frequency",
      value: aiDetail?.configuration?.frequency_penalty,
    },
    {
      id: 3,
      title: "Presence",
      value: aiDetail?.configuration?.presence_penalty,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setDetailLoader(true);

          if (firstPath === "ai-assistant" || tempType === "my") {
            await dispatch(
              getUserAiAssistant({
                accessToken,
                resource_id: showTempDetail?.id ? showTempDetail?.id : getLastIdFromUrl(
                  window.location.pathname
                ),
              })
            );
          }

          if (
            firstPath === "community-ai-assistant" ||
            tempType === "community"
          ) {
            await dispatch(
              getPublicAiAssistant({
                accessToken,
                resource_id: showTempDetail?.id
                  ? showTempDetail?.id
                  : getLastIdFromUrl(window.location.pathname),
              })
            );
          }

          setDetailLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstPath === "ai-assistant" || tempType === "my") {
      setAiDetail(getUserAiAssistantRes);
    }

    if (firstPath === "community-ai-assistant" || tempType === "community") {
      setAiDetail(getPublicAiAssistantRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserAiAssistantRes, getPublicAiAssistantRes]);

  return (
    <WrapperLoader loading={detailLoader}>
      <div className="w-full">
        <div className="grid grid-cols-4">
          <div className=" col-span-3 border-r border-zinc-200 pr-7">
            <nav className="flex space-x-4 mb-5" aria-label="Tabs">
              {tabsList.map((tab: any) => (
                <span
                  key={tab.id}
                  onClick={() => setSelectedTab(tab.id)}
                  className={classNames(
                    tab.id === selectedTab
                      ? "text-amber-500 border-amber-500"
                      : "border-transparent text-customLightBlue hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 py-2 px-2 text-xs font-medium cursor-pointer"
                  )}
                >
                  {tab.title}
                </span>
              ))}
            </nav>

            <h3 className="group text-xs font-semibold flex items-center mb-4 text-customLightBlue">
              <LightBulbIcon className="mr-2 h-5 text-zinc-400 group-hover:text-amber-500" />
              <span>{aiDetail?.title}</span>
            </h3>

            {selectedTab === 1 && (
              <>
                <div className="bg-amber-50 border border-y border-amber-400 mt-2 mb-6 p-7 rounded-xl shadow-md">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center mb-2 text-xs">
                      <div className="mr-3">
                        <img
                          src={chatGPT}
                          alt="Chat GPT"
                          className="w-6 h-6 rounded-full"
                        />
                      </div>
                      <span>{aiDetail?.ai_type}</span>
                    </div>

                    <div>
                      {isModal && (
                        <>
                          <CustomButton
                            text="Back"
                            type="button"
                            onClickBtn={() => onCancel()}
                            outlined
                            btnStyle="border-gray-300 text-customLightBlue px-6 text-lg mr-4 hover:border-gray-600 hover:text-customDarkBlue"
                          />

                          <CustomButton
                            text="Use"
                            type="button"
                            onClickBtn={() => onUse(aiDetail?.id)}
                            btnStyle="w-24 flex items-center justify-center text-lg bg-blue-500 hover:bg-amber-400"
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {/* <h3 className="text-xs font-semibold text-customDarkBlue mb-3 line-clamp-1">
                  {aiDetail?.title}
                </h3> */}
                  <p className="text-xs">{aiDetail?.description}</p>
                  <div className="mt-4 pt-4 border-t border-amber-200">
                    <h3 className="text-xs font-semibold text-customDarkBlue mb-2 line-clamp-1">
                      Prompt Message
                    </h3>
                    <p className="text-xs">
                      {aiDetail?.configuration?.system_config}
                    </p>
                  </div>
                </div>

                <div className="text-gray-500 mb-3 text-lg">Configurations</div>

                {configurationsList.map((data) => {
                  return (
                    <div
                      key={data.id}
                      className="bg-white shadow-md rounded-md flex items-stretch mb-3"
                    >
                      <div className="p-4 text-xs font-bold text-customDarkBlue flex items-center w-full">
                        <div className="mr-3">
                          <InformationCircleIcon
                            data-tooltip-id="tooltip-content"
                            className="w-5 text-zinc-400 hover:customDarkBlue"
                          />
                          <Tooltip id="tooltip-content" place="right">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>Hello</span>
                              <span>some stuff in between</span>
                              <span>world!</span>
                            </div>
                          </Tooltip>
                        </div>
                        <span>{data.title}</span>
                      </div>

                      <div className="border-l text-xs border-zinc-200 px-4 flex items-center justify-center flex-grow w-[20%]">
                        {data.value}
                      </div>
                    </div>
                  );
                })}

                {/* <div className="bg-white border border-zinc-100 mt-2 px-7 py-6 rounded-xl shadow-md">
                <h3 className="text-xs font-semibold mb-5">Documents</h3>
                {documentsList.map((data) => (
                  <div
                    key={data.id}
                    onClick={() => setIsOpenHTML(true)}
                    className="group text-xs flex items-center mb-4 cursor-pointer hover:text-amber-500"
                  >
                    <DocumentTextIcon className="mr-2 h-4 text-zinc-500 group-hover:text-amber-500" />
                    <span>{data.title}</span>
                  </div>
                ))}
              </div> */}
              </>
            )}

            {selectedTab === 2 && <Discussions />}
          </div>
          <div className=" col-span-1 px-6">
            <div className="mb-7">
              <div className="text-gray-400 mb-2 text-xs uppercase">
                Publisher
              </div>

              <div className="mb-2 text-xs">
                <div className="flex items-center" data-tooltip-id="user-name">
                  <div className="mr-3">
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src={user?.picture}
                      alt={profileData?.first_name}
                    />
                  </div>
                  <div>
                    {`${profileData?.first_name || profileData?.last_name ? `${profileData?.first_name} ${profileData?.last_name}` : profileData?.email}`}
                  </div>
                </div>

                {/* {data?.published_by === "userTemplate" && (
                  <div
                    className="flex items-center"
                    data-tooltip-id="user-name"
                  >
                    <div className="mr-3">
                      <img
                        className="h-8 w-8 rounded-full bg-gray-50"
                        src={user?.picture}
                        alt={profileData?.first_name}
                      />
                    </div>
                    <div>
                      {`${profileData?.first_name || profileData?.last_name ? `${profileData?.first_name} ${profileData?.last_name}` : profileData?.email}`}
                    </div>
                  </div>
                )}

                <Tooltip id="user-name" place="top">
                  {`${profileData?.first_name || profileData?.last_name ? `${profileData?.first_name} ${profileData?.last_name}` : profileData?.email}`}
                </Tooltip>

                {data?.published_by === "solutionPilotTemplate" && (
                  <div className="flex items-center">
                    <img
                      src={logoIcon}
                      alt="Solution Pilot"
                      className="h-8 mr-2"
                    />
                    <span>Solution Pilot</span>
                  </div>
                )}

                {data?.published_by === "communityTemplate" && (
                  <div>Community</div>
                )} */}
              </div>
            </div>

            <div className="mb-7">
              <div className="text-gray-400 mb-2 text-xs uppercase">
                Version
              </div>
              <div className="flex items-center mb-2 text-xs">1.0.0</div>
            </div>

            <div className="mb-7">
              <div className="text-gray-400 mb-2 text-xs uppercase">
                Last Update
              </div>
              <div className="flex items-center mb-2 text-xs">
                December 26th, 2024
              </div>
            </div>

            <div className="mb-7">
              <div className="text-gray-400 mb-2 text-xs uppercase">
                Maximum Tokens
              </div>
              <div className="flex items-center mb-2 text-xs">
                {aiDetail?.configuration?.max_tokens}
              </div>
            </div>

            <div className="mb-7">
              <div className="text-gray-400 mb-2 text-xs uppercase">
                AI Vendor
              </div>
              <div className="flex items-center mb-2 text-xs">
                <span
                  className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset justify-center bg-blue-50 text-blue-700 ring-blue-600/20`}
                >
                  {aiDetail?.ai_vendor}
                </span>
              </div>
            </div>

            <div className="mb-7">
              <div className="text-gray-400 mb-2 text-xs uppercase">
                Category
              </div>
              <div className="flex items-center mb-2 text-xs">
                <span
                  className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset justify-center bg-blue-50 text-blue-700 ring-blue-600/20`}
                >
                  {aiDetail?.category}
                </span>
              </div>
            </div>

            <div>
              <div className="text-gray-400 mb-2 text-xs uppercase">Share</div>
              <div className="flex items-center">
                <div className="cursor-pointer mr-2 hover:scale-110">
                  <img
                    className="h-[15px]"
                    src={twitterIcon}
                    alt="Twitter Logo"
                  />
                </div>
                <div className="cursor-pointer mr-2 hover:scale-110">
                  <img
                    className="h-[15px]"
                    src={linkedInIcon}
                    alt="LinkedIn Logo"
                  />
                </div>
                <div className="cursor-pointer mr-2 hover:scale-110">
                  <LinkIcon className="h-[15px]" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <HTMLContentModal
          openModal={isOpenHTML}
          onClosingModal={() => setIsOpenHTML(false)}
        />
      </div>
    </WrapperLoader>
  );
}
