import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CustomButton from "src/components/customButton";
import TextareaField from "../../../components/formFields/textareaField";
import { getAllConversations } from "../../../redux/chatGPT/chatGPTApi";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getLastIdFromUrl } from "../../../utils/utilities";
import WrapperLoader from "src/components/wrapperLoader";

interface FlowChartGenerateFormProps {
  onCloseModal: () => void;
  loading: boolean;
  onSubmitData: Function;
}

export default function FlowChartGenerateForm({
  onCloseModal,
  loading,
  onSubmitData,
}: FlowChartGenerateFormProps) {
  const [generateText, setGenerateText] = useState("");
  const [isError, setIsError] = useState(false);
  const [allConversationsLoader, setAllConversationsLoader] = useState(false);
  const [selectedConversationId, setSelectedConversationId] = useState("");

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { getAllConversationsRes } = useAppSelector((state) => state.chatGPT);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setAllConversationsLoader(true);
          await dispatch(
            getAllConversations({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          setAllConversationsLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  }, [dispatch, getAccessTokenSilently]);

  useEffect(() => {
    if (getAllConversationsRes?.length > 0) {
      setSelectedConversationId(getAllConversationsRes[0]?.id);
    }
  }, [getAllConversationsRes]);

  const onSubmitForm = (e: any) => {
    e.preventDefault();

    if (generateText) {
      onSubmitData(generateText, selectedConversationId);
    } else {
      setIsError(true);
    }
  };

  const onChangeGenerateText = (value: string) => {
    setGenerateText(value);

    if (value?.length > 0) {
      setIsError(false);
    }
  };

  const onSelectConversation = (e: any) => {
    const value = e.target.value;
    setSelectedConversationId(value);
  };

  return (
    <WrapperLoader loading={allConversationsLoader}>
      <form className="flex h-full flex-col" onSubmit={onSubmitForm}>
        <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mb-3">
          <div>
            <label className="block text-xs font-medium leading-6 text-customDarkBlue">
              Flow Chart Text
            </label>
          </div>
          <div className="sm:col-span-2">
            <TextareaField
              id="text"
              name="text"
              rows={5}
              value={generateText}
              onChange={(e) => onChangeGenerateText(e.target.value)}
              style={{
                border: `${isError ? "solid 1px red" : "solid 1px #cacaca"}`,
              }}
            />
          </div>
        </div>

        <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mb-3">
          <div>
            <label className="block text-xs font-medium leading-6 text-customDarkBlue">
              Select Conversation
            </label>
          </div>
          <div className="sm:col-span-2">
            <select
              onChange={onSelectConversation}
              value={selectedConversationId}
              className="block capitalize w-full bg-gray-100 rounded-md border-0 py-1.5 text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-xs sm:leading-6"
            >
              {getAllConversationsRes?.length > 0 &&
                getAllConversationsRes?.map((data: any) => (
                  <option key={data.id} value={data.id}>
                    {data.title}
                  </option>
                ))}
            </select>
          </div>
        </div>

        {/* Action buttons */}
        <div className="w-full text-right mt-5">
          <button
            type="button"
            className="rounded-md bg-white px-3 py-2 text-xs font-semibold text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-2"
            onClick={onCloseModal}
          >
            Cancel
          </button>

          <CustomButton
            text="Save"
            type="submit"
            onClickBtn={() => console.log()}
            loading={loading}
          />
        </div>
      </form>
    </WrapperLoader>
  );
}
