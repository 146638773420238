import React, { useState } from "react";
import {
  ArrowLongLeftIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import CustomModal from "../../../../components/customModal";
import {useAppDispatch, useAppSelector} from '../../../../hooks';
import {setCurrentAiAssistantTab} from '../../../../redux/userAiAssistants/userAiAssistantsSlice';
import AiAssistantsData from "../../../aiAssistants/aiAssistantsData";
import {classNames} from '../../../../utils/utilities'

import logoIcon from '../../../../assets/images/logo_icon.png';

const aiAssistantsTabsList: any = [
  { id: 1, name: "Community", current: false },
  { id: 2, name: "My Assistants", current: false },
];

interface ChatAIModalProps {
  onCloseModal: Function;
  openModal: boolean;
  onUseTemplate: Function;
}

export default function ChatAIModal({
  onCloseModal,
  openModal,
  onUseTemplate,
}: ChatAIModalProps) {
  const [showTempDetail, setShowTempDetail] = useState<any>(null);

  const dispatch = useAppDispatch();
  const {currentAiAssistantTab} = useAppSelector(state => state.userAiAssistants);

  return (
    <CustomModal
      open={openModal}
      onCloseModal={() => onCloseModal()}
      title={
        <div className="flex items-center">
          {showTempDetail && (
            <div
              onClick={() => setShowTempDetail(null)}
              className="mr-3 border px-3 border-gray-200 text-customLightBlue rounded flex items-center justify-center cursor-pointer hover:bg-amber-400 hover:text-white hover:border-gray-800"
            >
              <ArrowLongLeftIcon className="h-4 mr-2" />
              <span>Back</span>
            </div>
          )}
          <ChatBubbleLeftRightIcon className="h-5 mr-2 text-customLightBlue" />AI Chat Templates
        </div>
      }
      size="max-w-7xl"
    >
      <div className="w-full flex">
        <div className="w-[30%] border-r border-zinc-200 pr-4">
          <nav aria-label="Tabs">
            {aiAssistantsTabsList.map((tab: any) => (
              <div
                key={tab.id}
                onClick={() => dispatch(setCurrentAiAssistantTab(tab.id))}
                className={classNames(
                  tab.id === currentAiAssistantTab
                    ? "text-customDarkBlue bg-zinc-100 border-amber-500"
                    : "border-transparent text-customLightBlue hover:border-gray-300 hover:text-gray-700",
                  "border rounded-md p-3 text-xs font-medium cursor-pointer mb-3 flex items-center"
                )}
              >
                <span>
                  <img
                    className="h-6 mr-2"
                    src={logoIcon}
                    alt="Solutions Pilot"
                  />
                </span>
                <span>{tab.name}</span>
              </div>
            ))}
          </nav>
        </div>

        <div className="w-full">
          {currentAiAssistantTab === 1 && (
            <AiAssistantsData
              isModal={true}
              onUseWithModal={onUseTemplate}
              tempType="community"
            />
          )}
          {currentAiAssistantTab === 2 && (
            <AiAssistantsData
              isModal={true}
              onUseWithModal={onUseTemplate}
              tempType="my"
            />
          )}
        </div>
      </div>
    </CustomModal>
  );
}
