import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CustomModal from "../../../components/customModal";
import ArgumentForm from "./argumentForm";
import ArgumentBox from "../../../components/argumentBox";
import {
  getProsConsArgumentsList,
  createProsConsArgument,
  updateProsConsArgument,
  deleteProsConsArgument,
} from "../../../redux/prosConsArguments/prosConsArgumentsApi";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getLastIdFromUrl } from "../../../utils/utilities";
import RoundButton from "../../../components/roundButton";
import ProgressBar from "../../../components/progressBar";
import ConfirmationModal from "../../../components/confirmationModal";
import { classNames } from "../../../utils/utilities";

interface AnalysisProps {
  detailsData: any;
}

export default function Analysis({ detailsData }: AnalysisProps) {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [argumentFormLoader, setArgumentFormLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [prosList, setProsList] = useState<any>(null);
  const [consList, setConsList] = useState<any>(null);
  const [argumentDeleteLoader, setArgumentDeleteLoader] = useState(false);
  const [side, setSide] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { prosConsArgumentsList } = useAppSelector(
    (state: any) => state.prosConsArguments
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            getProsConsArgumentsList({
              accessToken,
              pnc_id: detailsData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pros = prosConsArgumentsList?.filter((data: any) => data.side === "pro");
    const cons = prosConsArgumentsList?.filter((data: any) => data.side === "con");

    setProsList(pros);
    setConsList(cons);
  }, [prosConsArgumentsList]);

  const calculatingPercentage = (type: string) => {
    if (prosList?.length > 0 && consList?.length > 0) {
      const prosSum = prosList.reduce((start: number, data: any) => {
        return start + data.argument_weight;
      }, 0);

      const consSum = consList.reduce((start: number, data: any) => {
        return start + data.argument_weight;
      }, 0);

      const allSum = prosSum + consSum;

      if (type === "pro") {
        return Math.ceil((prosSum / allSum) * 100);
      }

      if (type === "con") {
        return Math.ceil((consSum / allSum) * 100);
      }
    } else {
      return "";
    }
  };

  const onCloseModal = () => {
    setOpenFormModal(false);
    setSelectedData(null);
  };

  const onOpenModal = (type: string) => {
    setSide(type);
    setOpenFormModal(true);
  };

  const onOpenEditModal = (data: any, type: string) => {
    setSide(type);
    setSelectedData(data);
    setOpenFormModal(true);
  };

  const updatingArgument = (body: any) => {
    const fetchData = async () => {
      setArgumentFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateProsConsArgument({
              body,
              accessToken,
              pnc_id: detailsData?.id,
              argument_id: selectedData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          await dispatch(
            getProsConsArgumentsList({
              accessToken,
              pnc_id: detailsData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          onCloseModal();
          setArgumentFormLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setArgumentFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingArgument = (body: any) => {
    const fetchData = async () => {
      setArgumentFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createProsConsArgument({
              body,
              accessToken,
              pnc_id: detailsData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          await dispatch(
            getProsConsArgumentsList({
              accessToken,
              pnc_id: detailsData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          onCloseModal();
          setArgumentFormLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setArgumentFormLoader(false);
      }
    };

    fetchData();
  };

  const deletingArgument = () => {
    const fetchData = async () => {
      setArgumentDeleteLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            deleteProsConsArgument({
              accessToken,
              argument_id: selectedData?.id,
              pnc_id: detailsData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          await dispatch(
            getProsConsArgumentsList({
              accessToken,
              pnc_id: detailsData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          setArgumentDeleteLoader(false);
          closeConfirmationModal();
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setArgumentDeleteLoader(false);
        closeConfirmationModal();
      }
    };

    fetchData();
  };

  const onSubmitForm = async (body: any) => {
    if (selectedData) {
      updatingArgument(body);
    } else {
      creatingArgument(body);
    }
  };

  const deleteConfirmationModal = (data: any) => {
    setSelectedData(data);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedData(null);
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <div className="flex">
          <div className="flex items-center">
            <span className="mr-2 text-xs font-semibold">
              {`${calculatingPercentage("pro")}`}%
            </span>
            <div className="rotate-180" style={{ width: "300px" }}>
              <ProgressBar
                percent={`${calculatingPercentage("pro")}`}
                styles="rounded-l-none"
                color="bg-green-600"
              />
            </div>
          </div>

          <div className="flex items-center">
            <div style={{ width: "300px" }}>
              <ProgressBar
                percent={`${calculatingPercentage("con")}`}
                styles="rounded-l-none"
                color="bg-amber-400"
              />
            </div>
            <span className="ml-2 text-xs font-semibold">
              {`${calculatingPercentage("con")}`}%
            </span>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2">
        {/** Pros Column **/}
        <div className="border-r border-gray-200">
          <div className="bg-white rounded-2xl overflow-hidden mt-4 mr-4 shadow-md">
            <div className="pt-6 px-6 pb-4">
              <div className="text-center">
                <span className="font-semibold text-base">Pros</span>
              </div>
            </div>

            <div className="border-t border-gray-200 p-4">
              <div
                onClick={() => onOpenModal("pro")}
                className="w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-amber-400"
              >
                <RoundButton onClickBtn={() => console.log("...")} />
              </div>
              <div
                className=" overflow-y-auto"
                style={{ height: "calc(100vh - 255px)" }}
              >
                <ul className="mt-3 grid grid-cols-1">
                  {prosList?.length > 0
                    ? prosList.map((data: any, index: number) => (
                        <ArgumentBox
                          key={index}
                          id={data?.id}
                          name={data.argument}
                          classNames={classNames}
                          value={data.argument_weight}
                          onEdit={() => onOpenEditModal(data, "pro")}
                          onDelete={() => deleteConfirmationModal(data)}
                          classes="bg-green-600 text-white"
                          deleteLoader={false}
                          selectedData={selectedData}
                        />
                      ))
                    : ""}
                </ul>
              </div>

              {/* <div className="text-center">
                <RoundButton onClickBtn={() => onOpenModal("pro")} />
              </div> */}
            </div>
          </div>
        </div>

        {/** Cons Column **/}
        <div className="bg-white rounded-2xl overflow-hidden mt-4 ml-4 shadow-md">
          <div className="pt-6 px-6 pb-4">
            <div className="text-center">
              <span className="font-semibold text-base">Cons</span>
            </div>
          </div>

          <div className="border-t border-gray-200 p-4">
            <div
              onClick={() => onOpenModal("con")}
              className="w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-amber-400"
            >
              <RoundButton onClickBtn={() => console.log("...")} />
            </div>
            <div
              className="overflow-y-auto"
              style={{ height: "calc(100vh - 255px)" }}
            >
              <ul className="mt-3 grid grid-cols-1">
                {consList?.length > 0
                  ? consList.map((data: any, index: number) => (
                      <ArgumentBox
                        key={index}
                        id={data?.id}
                        name={data.argument}
                        classNames={classNames}
                        value={data.argument_weight}
                        onEdit={() => onOpenEditModal(data, "con")}
                        onDelete={() => deleteConfirmationModal(data)}
                        classes="bg-amber-400 text-white"
                        deleteLoader={false}
                        selectedData={selectedData}
                      />
                    ))
                  : ""}
              </ul>
            </div>

            {/* <div className="text-center">
              <RoundButton onClickBtn={() => onOpenModal("con")} />
            </div> */}
          </div>
        </div>
      </div>

      <CustomModal
        open={openFormModal}
        onCloseModal={() => onCloseModal()}
        title={`${selectedData ? "Update" : "Add"} Argument`}
        size="max-w-lg"
      >
        <ArgumentForm
          onCloseModal={() => onCloseModal()}
          loading={argumentFormLoader}
          onSubmitData={onSubmitForm}
          editData={selectedData}
          side={side}
        />
      </CustomModal>

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingArgument}
        deleteLoader={argumentDeleteLoader}
      />
    </>
  );
}
