import React, { useState, useEffect, useRef } from "react";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  promptMessage,
  getAllMessages,
  promptStreamMessage,
} from "../../../../redux/chatGPT/chatGPTApi";
import BlockAction from "./blockAction";
import { timeAgoOrFormattedDate } from "../../../../utils/utilities";
import WrapperLoader from "../../../../components/wrapperLoader";
import ChatGPTInput from "../../../../components/chatGPTInput";

import "./style.css";

interface ChatGPTProps {
  addItemToTextEditor: Function;
  selectedConversation: any;
}

const ChatGPT = ({
  addItemToTextEditor,
  selectedConversation,
}: ChatGPTProps) => {
  const [messages, setMessages] = useState<any>([]);
  const [messageLoader, setMessageLoader] = useState(false);
  const [allMessagesLoader, setAllMessagesLoader] = useState(false);
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);

  const messagesContainerRef = useRef<HTMLUListElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently, user } = useAuth0();
  const { getAllMessagesRes } = useAppSelector((state) => state.chatGPT);
  const { profileData } = useAppSelector<any>((state: any) => state.profile);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setAllMessagesLoader(true);
          await dispatch(
            getAllMessages({
              accessToken,
              project_id: selectedConversation?.project_id,
              conversation_id: selectedConversation?.id,
            })
          )
            .then(() => setAllMessagesLoader(false))
            .catch(() => setAllMessagesLoader(false));
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversation]);

  useEffect(() => {
    setMessages(getAllMessagesRes);
  }, [getAllMessagesRes]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      const scrollHeight = messagesContainerRef.current.scrollHeight;
      const clientHeight = messagesContainerRef.current.clientHeight;

      // Assuming you have a unique ID for the last message, e.g., "show-first"
      const lastMessageId = "show-first";
      const lastMessageElement = document.getElementById(lastMessageId);

      if (lastMessageElement) {
        const lastMessageOffset = lastMessageElement.offsetTop;
        const scrollTop = Math.max(0, lastMessageOffset - 200);

        messagesContainerRef.current.scrollTop = scrollTop;
      } else {
        // If the element is not found, scroll to the bottom as a fallback
        messagesContainerRef.current.scrollTop = scrollHeight - clientHeight;
      }
    }
  };


  console.log(messages)

  const sendMessage = async () => {
    const messageData = textareaRef?.current?.value || "";
    if (messageData.trim() !== "") {
      setMessageLoader(true);
  
      setMessages((prev: any) => [
        ...(prev || []),
        {
          content: messageData,
          created_at: new Date(),
          role: "user",
        },
        {
          content: "q_loading",
          created_at: new Date(),
          role: "assistant",
        },
      ]);



  
      const messagesArray = [{ role: "user", content: messageData }];
      const payload = {
        messages: messagesArray,
      };

  
      const fetchData = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          if (accessToken) {
            // POST to start the generation
            await dispatch(
              promptStreamMessage({
                body: payload,
                accessToken,
                project_id: selectedConversation?.project_id,
                conversation_id: selectedConversation?.id,
              })
            );
            // GET the SSE stream
            const eventSource = new EventSource(
              `${process.env.REACT_APP_BASE_API_URL}/aoaiCompletionStream?conversation_id=${selectedConversation?.id}`
            );  
            setMessageLoader(false);
            function appendMessageChunk(chunk: string) {
              const indentationUnit = "  ";
              let currentIndentationLevel = 0;        
              // Add indentation to the chunk
              function updateIndentation(chunk: string): string {
                  let indentedChunk = chunk;
                  if (chunk.includes("}")) {
                      currentIndentationLevel = Math.max(0, currentIndentationLevel - 1);
                  }
                  indentedChunk = indentationUnit.repeat(currentIndentationLevel) + chunk;
                  if (chunk.includes("{")) {
                      currentIndentationLevel += 1;
                  }
                  return indentedChunk;
              }
          
              const formattedChunk = updateIndentation(chunk);
          
              setMessages((prev: any) => {
                  const lastMessageIndex = prev.length - 1;
                  const updatedMessages = [...prev];
                  const lastMessage = { ...updatedMessages[lastMessageIndex] };
                  const firstChar = formattedChunk.charAt(0);
          
                  if (lastMessage.role === "assistant") {
                      if (lastMessage.content === "q_loading") {
                          lastMessage.content = `${formattedChunk}`;
                      } else {
                        if(firstChar === "!" || firstChar === "?" || firstChar === "." || firstChar === ",") {
                          lastMessage.content += `${formattedChunk}`;
                        } else {
                          lastMessage.content += `\n${formattedChunk}`;
                        }
                      }
                  }
          
                  updatedMessages[lastMessageIndex] = lastMessage;
                  return updatedMessages;
              });
          }
            eventSource.onmessage = (event) => {
              const data = event.data;
              appendMessageChunk(data);
            };
  
            eventSource.onerror = (error) => {
              console.error("SSE error:", error);
              eventSource.close();
              setMessageLoader(false);
            };
  
            eventSource.onopen = () => {
              setMessageLoader(false);
            };
          }
        } catch (error) {
          console.error("Error getting access token:", error);
          setMessageLoader(false);
        }
      };
  
      fetchData();

      if (textareaRef.current) {
          textareaRef.current.value = "";
      }
    }
  };

  const handleKeyPress = (event: any) => {
    if (messageLoader) {
      return "";
    }

    if (event.key === "Enter" && event.shiftKey) {
      if (textareaRef.current) {
        textareaRef.current.value += "\n";
      }
    } else if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <WrapperLoader loading={allMessagesLoader}>
      <div className="bg-white">
        <ul
          ref={messagesContainerRef}
          className="overflow-y-auto mb-4 pr-2"
          style={{
            height: `calc(100vh - ${isTextareaFocused ? "270px" : "220px"})`,
          }}
        >
          {messages?.map((data: any, dataIdx: number) => (
            <li
              key={dataIdx}
              className={`relative flex gap-x-4 mt-3 first:mt-0`}
              id={`${
                messages?.length === dataIdx + 1 ? "show-first" : "dont-show"
              }`}
            >
              <>
                <div
                  className="flex items-start rounded-md p-3 ring-1 ring-inset ring-gray-200"
                  style={{
                    width: "calc(100%)",
                  }}
                >
                  <div className="mr-2">
                    <img
                      src={`${
                        data.role === "user"
                          ? user?.picture
                          : "https://static.vecteezy.com/system/resources/previews/021/059/825/original/chatgpt-logo-chat-gpt-icon-on-green-background-free-vector.jpg"
                      }`}
                      alt=""
                      className="relative h-6 w-6 flex-none rounded-full bg-gray-50"
                    />
                  </div>

                  <div style={{ width: "calc(100% - 40px)" }}>
                    <div className="flex justify-between gap-x-4">
                      <div className="py-0.5 text-xs leading-5 text-customLightBlue font-semibold">
                        {data.role === "user"
                          ? profileData?.first_name
                            ? `${profileData?.first_name} ${profileData?.last_name}`
                            : profileData?.email
                          : "Chat GPT"}
                      </div>
                      <time
                        dateTime="2023-01-24T09:20"
                        className="flex-none py-0.5 text-xs leading-5 text-customLightBlue"
                      >
                        {timeAgoOrFormattedDate(data?.created_at)}
                      </time>
                    </div>

                    {data.role === "user" ? (
                      <p className="text-xs leading-7 text-customLightBlue break-words">
                        {data.content}
                      </p>
                    ) : (
                      <div className="text-xs leading-7 text-gray-600 messages-response break-words">
                        {data?.content === "q_loading" ? (
                          <div className="flex space-x-2 items-center mt-3">
                            <span className="sr-only">Loading...</span>
                            <div className="h-2 w-2 bg-amber-300 rounded-full animate-bounce [animation-delay:-0.2s]"></div>
                            <div className="h-2 w-2 bg-amber-300 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                            <div className="h-2 w-2 bg-amber-300 rounded-full animate-bounce"></div>
                          </div>
                        ) : (
                          <Markdown
                            children={data?.content}
                            components={{
                              code(props: any) {
                                const { children, className, node, ...rest } =
                                  props;
                                const match = /language-(\w+)/.exec(
                                  className || ""
                                );
                                return match ? (
                                  <div className="relative group">
                                    <SyntaxHighlighter
                                      {...rest}
                                      PreTag="div"
                                      children={String(children).replace(
                                        /\n$/,
                                        ""
                                      )}
                                      language={match[1]}
                                      style={okaidia}
                                    />

                                    <BlockAction
                                      textToCopy={String(children).replace(
                                        /\n$/,
                                        ""
                                      )}
                                      addItemToTextEditor={() =>
                                        addItemToTextEditor(
                                          String(children).replace(/\n$/, "")
                                        )
                                      }
                                    />
                                  </div>
                                ) : (
                                  <code
                                    {...rest}
                                    className={`${className} break-words`}
                                  >
                                    {children}
                                  </code>
                                );
                              },
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </>
            </li>
          ))}
        </ul>

        <div className="flex items-center w-full">
          <div className="w-full">
            <div className="chat-form">
              <ChatGPTInput
                textareaRef={textareaRef}
                handleKeyPress={handleKeyPress}
                messageLoader={messageLoader}
                sendMessage={sendMessage}
                setIsTextareaFocused={setIsTextareaFocused}
                isTextareaFocused={isTextareaFocused}
              />
              
            </div>
          </div>
        </div>
      </div>
    </WrapperLoader>
  );
};

export default React.memo(ChatGPT);
