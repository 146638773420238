import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {
  ArrowDownCircleIcon,
  TrashIcon,
  ArrowUpOnSquareStackIcon,
  ArrowTopRightOnSquareIcon,
  PencilIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../hooks";
import WrapperLoader from "../../components/wrapperLoader";

import AIChatTempDetail from "../../components/aiChatTempDetail";
import CustomButton from "../../components/customButton";
import {
  getUserAllAiAssistants,
  createUserAiAssistant,
  updateUserAiAssistant,
  deleteUserAiAssistant,
  getUserAiAssistant,
  publishUserAiAssistant,
  unPublishUserAiAssistant,
} from "../../redux/userAiAssistants/userAiAssistantsApi";
import CustomDrawer from "../../components/customDrawer";
import ConfirmationModal from "../../components/confirmationModal";
import CreationForm from "../aiAssistants/CreationForm";
import PublishConfirmation from "../../components/publishConfirmation";
import {getLastIdFromUrl} from '../../utils/utilities';

const AiAssistantsDetails = () => {
  const [showTempDetail, setShowTempDetail] = useState<any>(null);
  const [allTemplatesLoader, setAllTemplatesLoader] = useState(false);
  const [projectSelectionModal, setProjectSelectionModal] = useState(false);
  const [selectedAIChatId, setSelectedAIChatId] = useState("");

  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState<any>(null);
  const [formLoader, setFormLoader] = useState(false);
  const [assistantByIdLoader, setAssistantByIdLoader] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState<any>(null);
  const [confirmationLoader, setConfirmationLoader] = useState(false);
  const [publishingLoader, setPublishingLoader] = useState(false);
  const [publishConfirmation, setPublishConfirmation] = useState(false);

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { getUserAiAssistantRes } = useAppSelector(
    (state) => state.userAiAssistants
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setAllTemplatesLoader(true);
          await dispatch(
            getUserAllAiAssistants({
              accessToken,
            })
          );

          setAllTemplatesLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpeningProjectSelection = (id: string) => {
    setSelectedAIChatId(id);
    setProjectSelectionModal(true);
  };

  const onOpenEditModal = (id: string) => {
    setIsEditMode(true);
    setOpen(true);
    gettingSingleAiAssistant(id);
  };

  const onCloseModal = () => {
    setOpen(false);
    setIsEditMode(false);
  };

  const updatingAIAssistant = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateUserAiAssistant({
              body,
              accessToken,
              resource_id: getLastIdFromUrl(window.location.pathname)
            })
          )
            .then((res) => {
              onCloseModal();
              setFormLoader(false);
              setShowTempDetail(res?.payload?.data);
            })
            .catch(() => {
              setFormLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingAIAssistant = (body: any) => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          setFormLoader(true);

          await dispatch(createUserAiAssistant({ body, accessToken }))
            .then(() => {
              onCloseModal();
              setFormLoader(false);
            })
            .catch(() => {
              setFormLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  const onSubmitAiAssistant = async (body: any) => {
    if (isEditMode) {
      updatingAIAssistant(body);
    } else {
      creatingAIAssistant(body);
    }
  };

  const gettingSingleAiAssistant = (id: string) => {
    const fetchData = async () => {
      setAssistantByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(getUserAiAssistant({ accessToken, resource_id: id }));
        setAssistantByIdLoader(false);
      } catch (error) {
        console.error("Error getting access token:", error);
        setAssistantByIdLoader(false);
      }
    };

    fetchData();
  };

  const deletingAIAssistant = () => {
    const fetchData = async () => {
      setConfirmationLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteUserAiAssistant({ accessToken, resource_id: selectedId })
          )
            .then(() => {
              setConfirmationLoader(false);
              deletingModalClose();
              setShowTempDetail(null);
              navigate("/ai-assistants");
            })
            .catch(() => {
              setConfirmationLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setConfirmationLoader(false);
      }
    };

    fetchData();
  };

  const deletingModalOpen = (id: string) => {
    setDeleteConfirm(true);
    setSelectedId(id);
  };

  const deletingModalClose = () => {
    setDeleteConfirm(false);
    setSelectedId(null);
  };

  const publishingAIAssistant = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          await dispatch(
            publishUserAiAssistant({
              accessToken,
              resource_id: getLastIdFromUrl(window.location.pathname)
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: false,
                  };
                });

                setPublishConfirmation(false);
              }
              setPublishingLoader(false);
            })
            .catch(() => {
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  const unPublishingAIAssistant = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          await dispatch(
            unPublishUserAiAssistant({
              accessToken,
              resource_id: getLastIdFromUrl(window.location.pathname)
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: true,
                  };
                });

                setPublishConfirmation(false);
              }
              setPublishingLoader(false);
            })
            .catch(() => {
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  return (
    <>
      <WrapperLoader loading={allTemplatesLoader}>
        <div>
          <div className="mb-5 pb-5 mt-5 border-b border-gray-200 text-right">
            <div className="px-6 flex items-center justify-between">
              <div className="flex items-center justify-between w-full">
                <CustomButton
                  text="Back"
                  type="button"
                  onClickBtn={() => navigate(-1)}
                  beforeIcon={<ChevronLeftIcon className="h-4 mr-1" />}
                  outlined
                  btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue border-none"
                />

                <div className="flex">
                  <CustomButton
                    text="Delete"
                    type="button"
                    onClickBtn={() =>
                      deletingModalOpen(getUserAiAssistantRes?.id)
                    }
                    beforeIcon={<TrashIcon className="h-4 mr-1" />}
                    loading={false}
                    outlined
                    btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                  />

                  <CustomButton
                    text={
                      getUserAiAssistantRes?.privacy === true
                        ? "Publish"
                        : "Unpublish"
                    }
                    type="button"
                    onClickBtn={() => setPublishConfirmation(true)}
                    beforeIcon={
                      <ArrowUpOnSquareStackIcon className="h-4 mr-1" />
                    }
                    loading={publishingLoader}
                    outlined
                    btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                  />

                  <CustomButton
                    text="Publish on LinkedIn"
                    type="button"
                    onClickBtn={() => console.log("hello...")}
                    beforeIcon={<ArrowDownCircleIcon className="h-4 mr-1" />}
                    loading={false}
                    outlined
                    btnStyle="whitespace-nowrap text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                  />

                  <CustomButton
                    text="Edit"
                    type="button"
                    onClickBtn={() => onOpenEditModal(showTempDetail?.id)}
                    beforeIcon={<PencilIcon className="h-4 mr-1" />}
                    loading={false}
                    outlined
                    btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="px-6 pb-6">
            <AIChatTempDetail
              onCancel={() => setShowTempDetail(null)}
              onUse={onOpeningProjectSelection}
              isModal={false}
            />
          </div>
        </div>
      </WrapperLoader>

      <CustomDrawer
        open={open}
        onCloseModal={onCloseModal}
        title={
          <div className="flex items-center">
            <span>{isEditMode ? "Update" : "Create"} AI Assistant</span>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer"
              className="text-xs text-yellow-500 hover:text-yellow-800 flex items-center font-semibold ml-3"
            >
              <span className="mr-1">Learn More</span>
              <ArrowTopRightOnSquareIcon className="h-3" />
            </a>
          </div>
        }
        description={
          isEditMode
            ? "Update Your AI Assistant."
            : "Create new AI Assistant, provide all the data during create your Assistant."
        }
      >
        <WrapperLoader loading={assistantByIdLoader}>
          <CreationForm
            onCloseModal={onCloseModal}
            editData={getUserAiAssistantRes}
            onSubmitForm={onSubmitAiAssistant}
            loader={formLoader}
            editMode={isEditMode}
          />
        </WrapperLoader>
      </CustomDrawer>

      <ConfirmationModal
        open={deleteConfirm}
        closingModal={() => deletingModalClose()}
        onDeleting={deletingAIAssistant}
        deleteLoader={confirmationLoader}
      />

      <PublishConfirmation
        open={publishConfirmation}
        closingModal={() => setPublishConfirmation(false)}
        onClickYes={
          getUserAiAssistantRes?.privacy === true
            ? () => publishingAIAssistant()
            : () => unPublishingAIAssistant()
        }
        loading={publishingLoader}
        privacy={getUserAiAssistantRes?.privacy}
      />
    </>
  );
};

export default AiAssistantsDetails;
