import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CustomModal from "../../../components/customModal";
import ArgumentForm from "./argumentForm";
import ArgumentBox from "../../../components/argumentBox";
import {
  getSwotArgumentsList,
  createSwotArgument,
  updateSwotArgument,
  deleteSwotArgument,
} from "../../../redux/swotArguments/swotArgumentsApi";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getLastIdFromUrl } from "../../../utils/utilities";
import RoundButton from "src/components/roundButton";
import ConfirmationModal from "../../../components/confirmationModal";
import { classNames } from "../../../utils/utilities";

interface AnalysisProps {
  detailsData: any;
}

export default function Analysis({ detailsData }: AnalysisProps) {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [argumentFormLoader, setArgumentFormLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [strengthsList, setStrengthsList] = useState<any>(null);
  const [weaknessesList, setWeaknessesList] = useState<any>(null);
  const [opportunitiesList, setOpportunitiesList] = useState<any>(null);
  const [threatsList, setThreatsList] = useState<any>(null);
  const [argumentDeleteLoader, setArgumentDeleteLoader] = useState(false);
  const [side, setSide] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { swotArgumentsList } = useAppSelector((state) => state.swotArguments);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            getSwotArgumentsList({
              accessToken,
              id: detailsData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const strengths = swotArgumentsList?.filter(
      (data) => data.side === "strength"
    );
    const weakness = swotArgumentsList?.filter(
      (data) => data.side === "weakness"
    );
    const opportunity = swotArgumentsList?.filter(
      (data) => data.side === "opportunity"
    );
    const threat = swotArgumentsList?.filter((data) => data.side === "threat");

    setStrengthsList(strengths);
    setWeaknessesList(weakness);
    setOpportunitiesList(opportunity);
    setThreatsList(threat);
  }, [swotArgumentsList]);

  const calculatingPercentage = (type: string) => {
    if (
      strengthsList?.length > 0 &&
      weaknessesList?.length > 0 &&
      opportunitiesList?.length > 0 &&
      threatsList?.length > 0
    ) {
      const strengthsSum = strengthsList.reduce((start: number, data: any) => {
        return start + data.argument_weight;
      }, 0);

      const weaknessesSum = weaknessesList.reduce(
        (start: number, data: any) => {
          return start + data.argument_weight;
        },
        0
      );

      const opportunitiesSum = opportunitiesList.reduce(
        (start: number, data: any) => {
          return start + data.argument_weight;
        },
        0
      );

      const threatsSum = threatsList.reduce((start: number, data: any) => {
        return start + data.argument_weight;
      }, 0);

      const allSum =
        strengthsSum + weaknessesSum + opportunitiesSum + threatsSum;

      if (type === "strength") {
        return Math.ceil((strengthsSum / allSum) * 100) + "%";
      }

      if (type === "weakness") {
        return Math.ceil((weaknessesSum / allSum) * 100) + "%";
      }

      if (type === "opportunity") {
        return Math.ceil((opportunitiesSum / allSum) * 100) + "%";
      }

      if (type === "threat") {
        return Math.ceil((threatsSum / allSum) * 100) + "%";
      }
    } else {
      return "";
    }
  };

  const onCloseModal = () => {
    setOpenFormModal(false);
    setSelectedData(null);
  };

  const onOpenModal = (type: string) => {
    setSide(type);
    setOpenFormModal(true);
  };

  const onOpenEditModal = (data: any, type: string) => {
    setSide(type);
    setSelectedData(data);
    setOpenFormModal(true);
  };

  const updatingArgument = (body: any) => {
    const fetchData = async () => {
      setArgumentFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateSwotArgument({
              body,
              accessToken,
              argument_id: selectedData?.id,
              swot_id: detailsData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          await dispatch(
            getSwotArgumentsList({
              accessToken,
              id: detailsData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          onCloseModal();
          setArgumentFormLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setArgumentFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingArgument = (body: any) => {
    const fetchData = async () => {
      setArgumentFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createSwotArgument({
              body,
              accessToken,
              id: detailsData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          await dispatch(
            getSwotArgumentsList({
              accessToken,
              id: detailsData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          onCloseModal();
          setArgumentFormLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setArgumentFormLoader(false);
      }
    };

    fetchData();
  };

  const deletingArgument = () => {
    const fetchData = async () => {
      setArgumentDeleteLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            deleteSwotArgument({
              accessToken,
              argument_id: selectedData?.id,
              swot_id: detailsData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          await dispatch(
            getSwotArgumentsList({
              accessToken,
              id: detailsData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          setArgumentDeleteLoader(false);
          closeConfirmationModal();
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setArgumentDeleteLoader(false);
        closeConfirmationModal();
      }
    };

    fetchData();
  };

  const onSubmitForm = async (body: any) => {
    if (selectedData) {
      updatingArgument(body);
    } else {
      creatingArgument(body);
    }
  };

  const deleteConfirmationModal = (data: any) => {
    setSelectedData(data);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedData(null);
  };

  return (
    <>
      <div className="grid grid-cols-2 -mt-6 space-6">
        {/** Strengths Column **/}
        <div
          className="border-r border-gray-200 "
          style={{ minHeight: "calc(50vh - 100px)" }}
        >
          <div
            className="bg-white rounded-2xl overflow-hidden m-4 ml-0 shadow-md"
            style={{ minHeight: "calc(50vh - 100px)" }}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center p-3">
                {/* <RoundButton onClickBtn={() => onOpenModal("strength")} />

                <span className="mx-2 text-gray-300">|</span> */}
                <span className="font-semibold text-xs">Strengths</span>
              </div>

              {calculatingPercentage("strength") && (
                <span
                  className="bg-green-50 flex items-center justify-center"
                  style={{ width: "47px", height: "47px" }}
                >
                  <span className="font-medium text-green-800 text-xs">
                    {calculatingPercentage("strength")}
                  </span>
                </span>
              )}
            </div>
            <div className="border-t border-gray-200 p-4">
              <div>
                <div
                  onClick={() => onOpenModal("strength")}
                  className="w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-amber-400"
                >
                  <RoundButton onClickBtn={() => console.log("...")} />
                </div>
                <ul className="mt-3 grid grid-cols-1 roun">
                  {strengthsList?.length > 0
                    ? strengthsList.map((data: any, index: number) => (
                        <ArgumentBox
                          key={index}
                          id={data?.id}
                          name={data.argument}
                          classNames={classNames}
                          value={data.argument_weight}
                          onEdit={() => onOpenEditModal(data, "strength")}
                          onDelete={() => deleteConfirmationModal(data)}
                          classes="bg-green-100 text-black rounded-md"
                          deleteLoader={false}
                          selectedData={selectedData}
                          type="swot"
                        />
                      ))
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/** Weaknesses Column **/}
        <div className="bg-white rounded-2xl overflow-hidden m-4 mr-0 shadow-md">
          <div className="flex items-center justify-between">
            <div className="flex items-center p-3">
              {/* <RoundButton onClickBtn={() => onOpenModal("weakness")} />

              <span className="mx-2 text-gray-300">|</span> */}
              <span className="font-semibold text-xs">Weaknesses</span>
            </div>

            {calculatingPercentage("weakness") && (
              <span
                className="bg-blue-50 flex items-center justify-center"
                style={{ width: "47px", height: "47px" }}
              >
                <span className="font-medium text-yellow-800 text-xs">
                  {calculatingPercentage("weakness")}
                </span>
              </span>
            )}
          </div>

          <div className="border-t border-gray-200 p-4">
            <div
              onClick={() => onOpenModal("weakness")}
              className="w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-amber-400"
            >
              <RoundButton onClickBtn={() => console.log("...")} />
            </div>
            <div>
              <ul className="mt-3 grid grid-cols-1">
                {weaknessesList?.length > 0
                  ? weaknessesList.map((data: any, index: number) => (
                      <ArgumentBox
                        key={index}
                        id={data?.id}
                        name={data.argument}
                        classNames={classNames}
                        value={data.argument_weight}
                        onEdit={() => onOpenEditModal(data, "weakness")}
                        onDelete={() => deleteConfirmationModal(data)}
                        classes="bg-blue-100 text-black rounded-md"
                        deleteLoader={false}
                        selectedData={selectedData}
                        type="swot"
                      />
                    ))
                  : ""}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 border-t border-gray-200">
        {/** Opportunities Column **/}
        <div
          className="border-r border-gray-200"
          style={{ minHeight: "calc(50vh - 100px)" }}
        >
          <div className="bg-white rounded-2xl overflow-hidden m-4 ml-0 shadow-md">
            <div className="flex items-center justify-between">
              <div className="flex items-center p-3">
                {/* <RoundButton onClickBtn={() => onOpenModal("opportunity")} />

                <span className="mx-2 text-gray-300">|</span> */}
                <span className="font-semibold text-xs">Opportunites</span>
              </div>

              {calculatingPercentage("opportunity") && (
                <span
                  className=" bg-cyan-50 flex items-center justify-center"
                  style={{ width: "47px", height: "47px" }}
                >
                  <span className="font-medium text-cyan-800 text-xs">
                    {calculatingPercentage("opportunity")}
                  </span>
                </span>
              )}
            </div>

            <div className="border-t border-gray-200 p-4">
              <div
                onClick={() => onOpenModal("opportunity")}
                className="w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-amber-400"
              >
                <RoundButton onClickBtn={() => console.log("...")} />
              </div>
              <div>
                <ul className="mt-3 grid grid-cols-1">
                  {opportunitiesList?.length > 0
                    ? opportunitiesList.map((data: any, index: number) => (
                        <ArgumentBox
                          key={index}
                          id={data?.id}
                          name={data.argument}
                          classNames={classNames}
                          value={data.argument_weight}
                          onEdit={() => onOpenEditModal(data, "opportunity")}
                          onDelete={() => deleteConfirmationModal(data)}
                          classes="bg-cyan-100 text-black rounded-md"
                          deleteLoader={false}
                          selectedData={selectedData}
                          type="swot"
                        />
                      ))
                    : ""}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/** Threats Column **/}
        <div className="bg-white rounded-2xl overflow-hidden m-4 mr-0 shadow-md">
          <div className="flex items-center justify-between">
            <div className="flex items-center p-3">
              {/* <RoundButton onClickBtn={() => onOpenModal("threat")} />

              <span className="mx-2 text-gray-300">|</span> */}
              <span className="font-semibold text-xs">Threats</span>
            </div>

            {calculatingPercentage("threat") && (
              <span
                className="bg-yellow-50 flex items-center justify-center"
                style={{ width: "47px", height: "47px" }}
              >
                <span className="font-medium text-yellow-800 text-xs">
                  {calculatingPercentage("threat")}
                </span>
              </span>
            )}
          </div>

          <div className="border-t border-gray-200 p-4">
            <div
              onClick={() => onOpenModal("threat")}
              className="w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-amber-400"
            >
              <RoundButton onClickBtn={() => console.log("...")} />
            </div>
            <div>
              <ul className="mt-3 grid grid-cols-1">
                {threatsList?.length > 0
                  ? threatsList.map((data: any, index: number) => (
                      <ArgumentBox
                        key={index}
                        id={data?.id}
                        name={data.argument}
                        classNames={classNames}
                        value={data.argument_weight}
                        onEdit={() => onOpenEditModal(data, "threat")}
                        onDelete={() => deleteConfirmationModal(data)}
                        classes="bg-yellow-100 text-black rounded-md"
                        deleteLoader={false}
                        selectedData={selectedData}
                        type="swot"
                      />
                    ))
                  : ""}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        open={openFormModal}
        onCloseModal={() => onCloseModal()}
        title={`${selectedData ? "Update" : "Add"} Argument`}
        size="max-w-lg"
      >
        <ArgumentForm
          onCloseModal={() => onCloseModal()}
          loading={argumentFormLoader}
          onSubmitData={onSubmitForm}
          editData={selectedData}
          side={side}
        />
      </CustomModal>

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingArgument}
        deleteLoader={argumentDeleteLoader}
      />
    </>
  );
}
