import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SearchField from "../../components/searchField";
import WrapperLoader from "../../components/wrapperLoader";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getDocumentComponents,
  getDocumentComponentDetail,
  pinDocumentComponent,
  unpinDocumentComponent,
} from "../../redux/documentComponents/documentComponentsApi";
import CompleteEmptyPage from "../../components/completeEmptyPage";
import DocumentComponentBox from "../../components/documentComponentBox";
import DocumentComponentDetail from "../../components/documentComponentDetail";

import designTemplateSVG from "../../assets/images/Illustration-08.svg";

const DocumentComponents = () => {
  const [documentComponentsList, setDocumentComponentsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { getDocumentComponentsRes, getDocumentComponentDetailRes } =
    useAppSelector((state) => state.documentComponents);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setLoading(true);
          await dispatch(getDocumentComponents({ accessToken })).then(res => {
            if (res?.payload?.data?.length > 0) {
              gettingDocumentComponentDetail(res?.payload?.data[0]);
            }
          })

          setLoading(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDocumentComponentsList(getDocumentComponentsRes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDocumentComponentsRes]);

  const onSearchingList = (value: string) => {
    setSearch(value);

    if (value) {
      let filteredResults = null;

      filteredResults = getDocumentComponentsRes?.filter((item: any) =>
        item?.title.toLowerCase().includes(value.toLowerCase())
      );

      setDocumentComponentsList(filteredResults);
    } else {
      setDocumentComponentsList(getDocumentComponentsRes);
    }
  };

  const gettingDocumentComponentDetail = (data: any) => {
    setSelectedItem(data);
    setDetailLoading(true);

    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            getDocumentComponentDetail({ accessToken, id: data?.id })
          );

          setDetailLoading(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchData();
  };

  const pinAndUnpinComponent = (id: string) => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          if (!getDocumentComponentDetailRes?.is_favorite) {
            await dispatch(pinDocumentComponent({ accessToken, id }));
          }

          if (getDocumentComponentDetailRes?.is_favorite) {
            await dispatch(unpinDocumentComponent({ accessToken, id }));
          }
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchData();
  };

  return (
    <div className="w-full">
      {getDocumentComponentsRes?.length > 0 && (
        <div className="pb-4 mt-4 border-b border-gray-200 text-right">
          <div className="flex">
            <div className="w-60 ml-4">
              <SearchField
                onSearchField={onSearchingList}
                searchValue={search}
              />
            </div>
          </div>
        </div>
      )}

      <div className="flex mx-4">
        <div className="w-full mr-4  mt-4">
          <WrapperLoader loading={loading}>
            <div className="w-full overflow-y-hidden hover:overflow-y-auto h-[calc(100vh_-_105px)]">
              {documentComponentsList?.length > 0 ? (
                <div className="grid grid-cols-3 gap-2">
                  {documentComponentsList?.map((data: any, index: number) => {
                    return (
                      <div
                        className={`group overflow-hidden cursor-pointer relative w-full bg-white shadow-sm border transition-all ${selectedItem?.id === data?.id ? "border-2 border-amber-400" : "border-zinc-100"} bg-zinc-0 rounded-xl hover:shadow-xl top-0 hover:top-1`}
                        onClick={() => gettingDocumentComponentDetail(data)}
                      >
                        <DocumentComponentBox key={index} data={data} />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  className="flex items-center justify-center"
                  style={{ height: "calc(100vh - 110px)" }}
                >
                  <CompleteEmptyPage
                    title="You don't have any document components"
                    subTitle="Don't have document components"
                    btnText="New Template"
                    onClickBtn={null}
                    icon={designTemplateSVG}
                  />
                </div>
              )}
            </div>
          </WrapperLoader>
        </div>

        {selectedItem && getDocumentComponentsRes?.length > 0 && (
          <div className="w-full max-w-[400px] pl-4 border-l border-zinc-200">
            <WrapperLoader loading={detailLoading}>
              <div className="mt-4">
                <DocumentComponentDetail
                  data={getDocumentComponentDetailRes}
                  onPined={pinAndUnpinComponent}
                />
              </div>
            </WrapperLoader>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentComponents;
