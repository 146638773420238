import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import Detials from "./Details";
import Analysis from "./Analysis";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getDecisionMatrixById } from "../../redux/decisionMatrix/decisionMatrixApi";
import { getLastIdFromUrl } from "../../utils/utilities";
import WrapperLoader from "../../components/wrapperLoader";
// import DetailPageHead from "../../components/detialPageHead";
import { getQueryParams } from "../../utils/utilities";

// const tabs: any = [
//   { id: 1, name: "Details", current: true },
//   { id: 2, name: "Analysis", current: false },
// ];

export default function DecisionMatrixDetails() {
  // const [tabsList, setDecisionMatrixList] = useState(tabs);
  // const [activeId, setActiveId] = useState(1);
  const [decisionMatrixByIdLoader, setDecisionMatrixByIdLoader] =
    useState(false);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { decisionMatrixByIdRes } = useAppSelector(
    (state) => state.decisionMatrixs
  );
  const queryStringObj = getQueryParams(window.location.href);

  useEffect(() => {
    const fetchData = async () => {
      setDecisionMatrixByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(
            getDecisionMatrixById({
              accessToken,
              id: queryStringObj?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        setDecisionMatrixByIdLoader(false);
      } catch (error) {
        console.error("Error getting access token:", error);
        setDecisionMatrixByIdLoader(false);
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, dispatch]);

  // const onSelectTab = (id: number) => {
  //   const updatedProsConsList = tabsList.map((item: any) => ({
  //     ...item,
  //     current: item.id === id,
  //   }));

  //   setDecisionMatrixList(updatedProsConsList);
  //   setActiveId(id);
  // };

  // const pageTitle = decisionMatrixByIdRes?.details?.title;

  return (
    <WrapperLoader loading={decisionMatrixByIdLoader}>
      <div className="p-6">
        {/* <DetailPageHead
          title={pageTitle}
          backUrl={`${window.location.pathname}?active=decision-matrix`}
        />

        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabsList.map((tab: any) => (
              <span
                key={tab.id}
                onClick={() => onSelectTab(tab.id)}
                className={classNames(
                  tab.current
                    ? "text-amber-500 border-amber-500"
                    : "border-transparent text-customLightBlue hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 py-4 px-1 text-xs font-medium cursor-pointer"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div> */}

        <div>
          <Analysis detailsData={decisionMatrixByIdRes} />
          {/* {activeId === 1 && (
            <div className="bg-white rounded-xl shadow-lg pt-6 px-6 h-[calc(100vh_-_190px)] overflow-y-auto">
              <Detials detailsData={decisionMatrixByIdRes} />
            </div>
          )}
          {activeId === 2 && (
            <div className="h-[calc(100vh_-_190px)] overflow-y-auto pt-4">
              <Analysis detailsData={decisionMatrixByIdRes} />
            </div>
          )} */}
        </div>
      </div>
    </WrapperLoader>
  );
}
