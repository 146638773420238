import React from "react";

interface ContentsListProps {
  headingsList: { heading: string; text: string }[];
  classNames: Function;
}

export default function ContentsList({ headingsList, classNames }: ContentsListProps) {
  const handleHeaderClick = (headingText: string, headingName: string) => {
    const datathings = document.getElementsByClassName("ck-content")[0];

    if (datathings) {
      const headingElements: any = datathings.querySelectorAll(headingName);

      for (const headingElement of headingElements) {
        if (headingElement.textContent.includes(headingText)) {
          headingElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  };

  return (
    <div className="ml-1">
      {headingsList?.length > 0 &&
        headingsList.map((data: any, index) => (
          <div
            className={classNames(
              "text-xs cursor-pointer hover:text-yellow-500",
              (data.heading === "h1" &&
                "text-slate-800 mt-3 mb-1 first:mt-0") ||
                (data.heading === "h2" && "ml-4 mb-1 text-slate-500") ||
                (data.heading === "h3" && "ml-6 mb-1 text-slate-400")
            )}
            key={index}
            onClick={() => handleHeaderClick(data.text, data.heading)}
          >
            <span className="mr-3">→</span>{data?.text}
          </div>
        ))}
    </div>
  );
}
