// Node groups collection for AWS

export const kubernetes_groups = [
  {
    name: "node",
    style: {
      color: "#bfdbfe",
      border_style: "solid",
    },
    icon: "node",
    subdirectory: "infrastructure",
  },
  {
    name: "controll plane",
    style: {
      color: "#bfdbfe",
      border_style: "solid",
    },
    icon: "control-plane",
    subdirectory: "infrastructure",
  }
];