import React from "react";
import CustomButton from "src/components/customButton";
import {
  ArrowPathRoundedSquareIcon,
  PlusIcon,
  TrashIcon,
  ArrowDownTrayIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { useAppSelector, useAppDispatch } from "../../../../../hooks";
import { setSelectedDocument } from "../../../../../redux/projects/projectsSlice";
import DropdownSelectionOption from "../../../../../components/dropdownSelectionOption";
import ItemWithDropdown from "../../../../../components/itemWithDropdown";

interface DiagramActionsProps {
  setOpenGenerateModal?: Function;
  diagramSaveLoading: boolean;
  updatingProjectDiagram: Function;
}

export default function DiagramActions({
  setOpenGenerateModal,
  diagramSaveLoading,
  updatingProjectDiagram,
}: DiagramActionsProps) {
  const { getProjectAllDocumentsRes, selectedDocument } = useAppSelector(
    (state) => state.projects
  );
  const dispatch = useAppDispatch();

  const onSelectingDocument = (value: any) => {
    dispatch(setSelectedDocument(value));
  };

  const validateList = [
    {
      id: 1,
      name: "/Check for limitations",
      onActionClick: () => console.log("..."),
    },
    {
      id: 2,
      name: "/Validate Trafic Flow",
      onActionClick: () => console.log("..."),
    },
  ];

  return (
    <div className="flex items-center justify-end">
      {/* <CustomButton
          text="Regenerate"
          type="button"
          outlined
          onClickBtn={() => console.log("Regenerate...")}
          btnStyle="border-none text-customLightBlue hover:text-amber-400 mr-2"
          beforeIcon={<ArrowPathRoundedSquareIcon className="h-4 mr-1" />}
        /> */}

      <div className="font-medium text-customLightBlue border border-zinc-300 pr-2 pl-3 py-1 rounded-md mr-2 hover:border-blue-500 hover:text-blue-500">
        <DropdownSelectionOption
          optionsList={getProjectAllDocumentsRes}
          selected={selectedDocument}
          setSelected={onSelectingDocument}
          onAddingDocument={null}
          btnText="Add Document"
        />
      </div>

      <div className="relative mr-2">
        <ItemWithDropdown actionsData={validateList}>
          <CustomButton
            text={
              <div className="flex items-center">
                <span>Validate</span>
                <ChevronDownIcon className="h-4 ml-2" />
              </div>
            }
            type="button"
            outlined
            onClickBtn={() => console.log("...")}
            btnStyle="border-zinc-300 text-customLightBlue hover:text-amber-400"
          />
        </ItemWithDropdown>
      </div>

      {setOpenGenerateModal && (
        <CustomButton
          text="Generate Flow Chart"
          type="button"
          outlined
          onClickBtn={setOpenGenerateModal}
          btnStyle="border-zinc-300 text-customLightBlue hover:text-amber-400 mr-2"
          beforeIcon={<PlusIcon className="h-4 mr-1" />}
        />
      )}

      {/* <CustomButton
          text="Clear"
          type="button"
          outlined
          onClickBtn={() => console.log("Clear...")}
          btnStyle="border-none text-red-600 hover:text-amber-400 mr-2"
          beforeIcon={<TrashIcon className="h-4 mr-1" />}
        /> */}
      <CustomButton
        text="Save Changes"
        loading={diagramSaveLoading}
        type="button"
        outlined
        onClickBtn={updatingProjectDiagram}
        btnStyle="border-zinc-300 text-customLightBlue hover:text-amber-400"
        beforeIcon={<ArrowDownTrayIcon className="h-4 mr-1" />}
      />
    </div>
  );
}
