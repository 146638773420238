import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getProjectsList } from "../../redux/projects/projectsApi";
import WrapperLoader from "../../components/wrapperLoader";
import StatusLabel from "../../components/statusLabel";
import TableHeader from "../../components/tableHead";
import TableTitle from "../../components/tableTitle";
import CompleteEmptyPage from "../../components/completeEmptyPage";
import TableColumn from "../../components/tableColumn";
import designSVG from "../../assets/images/Illustration-07.svg";

const RecentProjects = () => {
  const [projectsCompleteList, setProjectsCompleteList] = useState<any>([]);

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectsList, loadingProjects } = useAppSelector(
    (state) => state.projects
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) await dispatch(getProjectsList(accessToken));
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, dispatch]);

  useEffect(() => {
    const sortedData: any =
      projectsList?.length > 0
        ? [...projectsList]
            .sort(
              (a: any, b: any) =>
                new Date(b.updated_at).getTime() -
                new Date(a.updated_at).getTime()
            )
            .slice(0, 10) // Take only the first 10 items
        : projectsList;

    setProjectsCompleteList(sortedData);
  }, [projectsList]);

  return (
    <>
      <WrapperLoader loading={loadingProjects}>
        <div>
          {projectsCompleteList?.length > 0 && (
            <div className="low-root">
              <div className="sm:-mx-6">
                <div className="inline-block min-w-full align-middle pb-4 sm:px-6">
                  <div className="h-[calc(100vh_-_210px)] overflow-y-hidden hover:overflow-y-auto">
                    <table className="min-w-full divide-y divide-gray-300 bg-white rounded-xl shadow-md border border-zinc-200 overflow-hidden">
                      <thead>
                        <tr>
                          <TableHeader name="Title" />
                          <TableHeader name="Status" />
                          <TableHeader name="Category" />
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {projectsCompleteList?.map((item: any) => (
                          <tr
                            key={item?.tbar_analysis?.id}
                            className="cursor-pointer hover:bg-zinc-100"
                            onClick={() => navigate(`/projects/${item?.id}?active=documents`)}
                          >
                            <TableColumn>
                              <TableTitle
                                title={item?.title}
                                status={item?.status}
                                created_at={item?.created_at}
                                updated_at={item?.updated_at}
                              />
                            </TableColumn>

                            <TableColumn>
                              <div className="text-xs text-customLightBlue">
                                <StatusLabel status={item?.status} />
                              </div>
                            </TableColumn>

                            <TableColumn>
                              <div className="text-xs text-customLightBlue">
                                {item?.category}
                              </div>
                            </TableColumn>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {(projectsList?.length === 0 || !projectsList) && (
            <div
              className="flex items-center justify-center home-empty"
              style={{ height: "calc(100vh - 300px)" }}
            >
              <CompleteEmptyPage
                title="Get started by creating a new project"
                subTitle="Get started by creating a new project"
                btnText="New Design"
                icon={designSVG}
              />
            </div>
          )}
        </div>
      </WrapperLoader>
    </>
  );
};

export default RecentProjects;
