import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getDecisionMatrixList } from "../../redux/decisionMatrix/decisionMatrixApi";
import WrapperLoader from "../../components/wrapperLoader";
import StatusLabel from "../../components/statusLabel";
import TableHeader from "../../components/tableHead";
import TableTitle from "../../components/tableTitle";
import CompleteEmptyPage from "../../components/completeEmptyPage";
import TableColumn from "../../components/tableColumn";
import decisionSVG from "../../assets/images/Illustration-06.svg";

const RecentDecisions = () => {
  const [decisionsCompleteList, setDecisionsCompleteList] = useState<any>([]);

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { decisionMatrixList, loadingDecisionMatrix } =
    useAppSelector((state) => state.decisionMatrixs);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const accessToken = await getAccessTokenSilently();
  //       if (accessToken) await dispatch(getDecisionMatrixList(accessToken));
  //     } catch (error) {
  //       console.error("Error getting access token:", error);
  //     }
  //   };

  //   fetchData();
  // }, [getAccessTokenSilently, dispatch]);

  useEffect(() => {
    // const sortedData: any =
    //   decisionMatrixList?.length > 0
    //     ? [...decisionMatrixList].sort(
    //         (a: any, b: any) =>
    //           new Date(b.updated_at).getTime() -
    //           new Date(a.updated_at).getTime()
    //       )
    //     : decisionMatrixList;

    setDecisionsCompleteList(decisionMatrixList);
  }, [decisionMatrixList]);

  return (
    <>
      <WrapperLoader loading={loadingDecisionMatrix}>
        <div>
          {decisionsCompleteList?.length > 0 && (
            <div className="low-root">
              <div className="overflow-x-auto sm:-mx-6">
                <div className="inline-block min-w-full align-middle pb-4 sm:px-6">
                  <div className="h-[calc(100vh_-_210px)] overflow-y-hidden hover:overflow-y-auto">
                    <table className="min-w-full divide-y divide-gray-300 bg-white rounded-xl shadow-md border border-zinc-200 overflow-hidden">
                      <thead>
                        <tr>
                          <TableHeader name="Title" />
                          <TableHeader name="Status" />
                          <TableHeader name="Category" />
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {decisionsCompleteList?.map((item: any) => (
                          <tr
                            key={item?.tbar_analysis?.id}
                            className="cursor-pointer hover:bg-zinc-100"
                            onClick={() =>
                              navigate(`/decision-matrix/${item?.id}`)
                            }
                          >
                            <TableColumn>
                              <TableTitle
                                title={item?.title}
                                status={item?.matrix_status}
                                // created_at={item?.created_at}
                                // updated_at={item?.updated_at}
                              />
                            </TableColumn>

                            <TableColumn>
                              <div className="text-xs text-customLightBlue">
                                <StatusLabel status={item?.matrix_status} />
                              </div>
                            </TableColumn>

                            <TableColumn>
                              <div className="text-xs text-customLightBlue">
                                {item?.category}
                              </div>
                            </TableColumn>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {(decisionMatrixList?.length === 0 || !decisionMatrixList) && (
            <div
              className="flex items-center justify-center home-empty"
              style={{ height: "calc(100vh - 300px)" }}
            >
              <CompleteEmptyPage
                title="Make calculated decisions"
                subTitle="Making decisions is critical in when choosing the right technology or solution for your business. Regardless if it is a simple or complex decision, we have the right tools to help you make the right choice."
                btnText="New Decision"
                icon={decisionSVG}
              />
            </div>
          )}
        </div>
      </WrapperLoader>
    </>
  );
};

export default RecentDecisions;
