import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { createTbar } from "../../redux/tbars/tbarsApi";
import CustomDrawer from "../../components/customDrawer";
import TBarForm from "../T-bar/TBarForm";
import { createProsCons } from "../../redux/prosCons/prosConsApi";
import ProConCreationForm from "../prosCons/CreationForm";
import { createDecisionMatrix } from "../../redux/decisionMatrix/decisionMatrixApi";
import CreationDecisionMatrixForm from "../decisionMatrix/CreationForm";
import { createSwot } from "../../redux/swot/swotApi";
import { createProject } from "../../redux/projects/projectsApi";
import CreationSWOTForm from "../swot/CreationForm";
import CreateProjectForm from "../projects/CreationForm";
import IconWithBox from "../../components/iconWithBox";
import RecentProjects from "./RecentProjects";
import RecentDecisions from "./RecentDecisions";
import { setCommunityPageTabs } from "../../redux/userAiAssistants/userAiAssistantsSlice";

import {
  LightBulbIcon,
  Square3Stack3DIcon,
  ScaleIcon,
  AdjustmentsHorizontalIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";

export default function Home() {
  const [formLoader, setFormLoader] = useState(false);
  const [openTChart, setOpenTChart] = useState(false);
  const [openProsCons, setProsConsOpen] = useState(false);
  const [openDecisionMatrix, setOpenDecisionMatrix] = useState(false);
  const [openSWOT, setOpenSWOT] = useState(false);
  const [openProject, setOpenProject] = useState(false);

  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { homeCurrentTab } = useAppSelector((state) => state.profile);

  const creatingTbar = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createTbar({ body, accessToken }));
          setOpenTChart(false);
          setFormLoader(false);
          navigate("/t-bar");
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitTChart = async (body: any) => {
    body.user_id = user?.sub;

    creatingTbar(body);
  };

  const creatingProsCons = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createProsCons({ body, accessToken }));
          setProsConsOpen(false);
          setFormLoader(false);

          navigate("/pro-con");
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitProsCons = async (body: any) => {
    body.user_id = user?.sub;

    creatingProsCons(body);
  };

  const creatingDecisionMatrix = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createDecisionMatrix({ body, accessToken }));
          setOpenDecisionMatrix(false);
          setFormLoader(false);
          navigate("/decision-matrix");
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitDecisionMatrix = async (body: any) => {
    body.user_id = user?.sub;

    creatingDecisionMatrix(body);
  };

  const creatingSwot = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createSwot({ body, accessToken }));
          setOpenSWOT(false);
          setFormLoader(false);
          navigate("/swot-analysis");
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitSwot = async (body: any) => {
    body.user_id = user?.sub;

    creatingSwot(body);
  };

  const creatingProject = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createProject({ body, accessToken })).then((res) => {
            if (res) {
              setOpenProject(false);
              setFormLoader(false);
              navigate(`/projects/${res?.payload?.data?.id}`);
            }
          });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitProject = async (body: any) => {
    body.user_id = user?.sub;

    creatingProject(body);
  };

  const onClickTemplates = () => {
    dispatch(setCommunityPageTabs(1));
    navigate("/community");
  };

  return (
    <div className="pb-0">
      {homeCurrentTab === 1 && (
        <>
          <div className="bg-[#f8f8f8] w-full">
            <div className="flex p-6 border-dashed border-b border-zinc-300">
              <div>
                <h3 className="text-xs font-semibold mb-4 text-customDarkBlue">
                  Projects and AI
                </h3>
                <div className="border-dashed border-r border-zinc-300 mr-4 pr-4">
                  <div className="flex items-center">
                    <div className="mr-2">
                      <IconWithBox
                        title="Project"
                        icon={LightBulbIcon}
                        onClickBox={() => setOpenProject(true)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h3 className="text-xs font-semibold mb-4 text-customDarkBlue">
                  Community
                </h3>
                <div className="border-dashed border-r border-zinc-300 mr-4 pr-4">
                  <div className="flex items-center">
                    <div className="mr-2">
                      <IconWithBox
                        title={<span>Templates</span>}
                        icon={Square3Stack3DIcon}
                        onClickBox={onClickTemplates}
                      />
                    </div>

                    <div>
                      <IconWithBox
                        title="AI Config"
                        icon={LightBulbIcon}
                        onClickBox={() => setOpenProject(true)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h3 className="text-xs font-semibold mb-4 text-customDarkBlue">
                  Decision Analysis
                </h3>
                <div>
                  <div className="flex items-center">
                    <div className="mr-2">
                      <IconWithBox
                        title="T-Chart"
                        icon={ScaleIcon}
                        onClickBox={() => setOpenTChart(true)}
                      />
                    </div>

                    <div className="mr-2">
                      <IconWithBox
                        title="Pros & Cons"
                        icon={AdjustmentsHorizontalIcon}
                        onClickBox={() => setProsConsOpen(true)}
                      />
                    </div>

                    <div className="mr-2">
                      <IconWithBox
                        title="Matrix"
                        icon={AdjustmentsHorizontalIcon}
                        onClickBox={() => setOpenDecisionMatrix(true)}
                      />
                    </div>

                    <div>
                      <IconWithBox
                        title="SWOT"
                        icon={Squares2X2Icon}
                        onClickBox={() => setOpenSWOT(true)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="flex p-6">
              <HomeItemBox
                title="Create new T-Chart"
                description="Quickstart analysing two options."
                icon={tChartIcon}
                graphImg={tCharGraph}
                onClickBox={() => setOpenTChart(true)}
                customCss="mr-3"
              />

              <HomeItemBox
                title="Create Pros and Cons list"
                description="Analyze a decision point with a pros and cons list."
                icon={prosConsIcon}
                graphImg={prosConsGraph}
                onClickBox={() => setProsConsOpen(true)}
                customCss="mr-3"
              />

              <HomeItemBox
                title="Create new Decision Matrix"
                description="Streamline complex decisions with analytical precision."
                icon={decisionMatrixIcon}
                graphImg={decisionMatrixGraph}
                onClickBox={() => setOpenDecisionMatrix(true)}
                customCss="mr-3"
              />

              <HomeItemBox
                title="Create SWOT Analysis"
                description="Map out strengths, weaknesses, opportunities, and threats effortlessly."
                icon={swotAnalysisIcon}
                graphImg={swotAnalysisGraph}
                onClickBox={() => setOpenSWOT(true)}
                customCss=""
              />
            </div> */}
          </div>

          <div className="px-6 pt-[20px]">
            {/* <PageHeader
              title={`Templates`}
              description="Try out common examples."
              size="medium"
            /> */}

            <div className="flex">
              <div className="mr-2 w-[50%]">
                <h3 className="text-xs font-semibold text-customDarkBlue mb-4">
                  Recent Projects
                </h3>
                <RecentProjects />
              </div>
              <div className="ml-2 w-[50%]">
                <h3 className="text-xs font-semibold text-customDarkBlue mb-4">
                  Decesions
                </h3>
                <RecentDecisions />
              </div>
            </div>

            {/* <div className="flex px-6 pt-0">
              <HomeItemBox
                title="Customer Support Agent"
                description="Maecenas ornare, nisl sed vehicula laoreet. Maecenas ornare, nisl sed vehicula laoreet."
                icon={swotAnalysisIcon}
                onClickBox={() => console.log("New url")}
                customCss="mr-3"
              />

              <HomeItemBox
                title="Writing Asistant"
                description="Maecenas ornare, nisl sed vehicula laoreet. Maecenas ornare, nisl sed vehicula laoreet."
                icon={swotAnalysisIcon}
                onClickBox={() => console.log("New url")}
                customCss="mr-3"
              />

              <HomeItemBox
                title="Summarize an Article"
                description="Maecenas ornare, nisl sed vehicula laoreet. Maecenas ornare, nisl sed vehicula laoreet."
                icon={swotAnalysisIcon}
                onClickBox={() => console.log("New url")}
                customCss="mr-3"
              />

              <HomeItemBox
                title="Create Cover Art"
                description="Maecenas ornare, nisl sed vehicula laoreet. Maecenas ornare, nisl sed vehicula laoreet."
                icon={swotAnalysisIcon}
                onClickBox={() => console.log("New url")}
                customCss="mr-3"
              />
            </div> */}
          </div>

          <CustomDrawer
            open={openTChart}
            onCloseModal={() => setOpenTChart(false)}
            title="T-Chart"
            description="Create new T-Chart, provide title and options that you will try to analyze and compare. Options are mandatory fields."
          >
            <TBarForm
              onCloseModal={() => setOpenTChart(false)}
              editData={null}
              onSubmitForm={onSubmitTChart}
              loader={formLoader}
              editMode={false}
            />
          </CustomDrawer>

          <CustomDrawer
            open={openProsCons}
            onCloseModal={() => setProsConsOpen(false)}
            title="Pros-Cons"
            description="Create new Pros-Cons, provide title and options that you will try to analyze and compare. Options are mandatory fields."
          >
            <ProConCreationForm
              onCloseModal={() => setProsConsOpen(false)}
              editData={null}
              onSubmitForm={onSubmitProsCons}
              loader={formLoader}
              editMode={false}
            />
          </CustomDrawer>

          <CustomDrawer
            open={openDecisionMatrix}
            onCloseModal={() => setOpenDecisionMatrix(false)}
            title="Decision Matrix"
            description="Create new Decision Matrix, provide title and options that you will try to analyze and compare. Options are mandatory fields."
          >
            <CreationDecisionMatrixForm
              onCloseModal={() => setOpenDecisionMatrix(false)}
              editData={null}
              onSubmitForm={onSubmitDecisionMatrix}
              loader={formLoader}
              editMode={false}
            />
          </CustomDrawer>

          <CustomDrawer
            open={openSWOT}
            onCloseModal={() => setOpenSWOT(false)}
            title="SWOT Analysis"
            description="Create new SWOT, provide title and options that you will try to analyze and compare. Options are mandatory fields."
          >
            <CreationSWOTForm
              onCloseModal={() => setOpenSWOT(false)}
              editData={null}
              onSubmitForm={onSubmitSwot}
              loader={formLoader}
              editMode={false}
            />
          </CustomDrawer>

          <CustomDrawer
            open={openProject}
            onCloseModal={() => setOpenProject(false)}
            title="Create Project"
            description="Create new Project, provide Title, Status and Category. Title is mandatory field."
          >
            <CreateProjectForm
              onCloseModal={() => setOpenProject(false)}
              editData={null}
              onSubmitForm={onSubmitProject}
              loader={formLoader}
              editMode={false}
            />
          </CustomDrawer>
        </>
      )}
    </div>
  );
}
