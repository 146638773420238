import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Bars3Icon, HomeIcon } from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getProfileData } from "../../redux/profile/profileApi";

import { createTbar, getTbarsList } from "../../redux/tbars/tbarsApi";
import CustomDrawer from "../../components/customDrawer";
import TBarForm from "../../pages/T-bar/TBarForm";
import {
  createProsCons,
  getProsConsList,
} from "../../redux/prosCons/prosConsApi";
import ProConCreationForm from "../../pages/prosCons/CreationForm";
import {
  createDecisionMatrix,
  getDecisionMatrixList,
} from "../../redux/decisionMatrix/decisionMatrixApi";
import CreationDecisionMatrixForm from "../../pages/decisionMatrix/CreationForm";
import { createSwot, getSwotList } from "../../redux/swot/swotApi";
import {
  createProject,
  getProjectsList,
} from "../../redux/projects/projectsApi";
import CreationSWOTForm from "../../pages/swot/CreationForm";
import CreateProjectForm from "../../pages/projects/CreationForm";
import {
  setCurrentAiAssistantTab,
  setCommunityPageTabs,
} from "../../redux/userAiAssistants/userAiAssistantsSlice";
import { createUserAiAssistant } from "../../redux/userAiAssistants/userAiAssistantsApi";
import CreateAiAisstantForm from "../../pages/aiAssistants/CreationForm";
import { setTemplatesCurrentTab } from "../../redux/profile/profileSlice";
import { classNames } from "../../utils/utilities";
import DropdownSelectionOption from "../dropdownSelectionOption";
import { setSelectedDocument } from "../../redux/projects/projectsSlice";
import { setSelectedDiagram } from "../../redux/diagrams/diagramsSlice";
import CustomModal from "../customModal";
import DocumentForm from "../../pages/projectsDetails/leftSideBar/documents/documentForm";
import { createProjectDocument } from "../../redux/projects/projectsApi";
import { getLastIdFromUrl } from "../../utils/utilities";
import NewProjectButton from "../newProjectButton";
import CreateNewDropdownButton from "../createNewDropdownButton";
import LoginUserInfo from "../loginUserInfo";
import DiagramForm from "../../pages/projectsDetails/leftSideBar/diagrams/diagramForm";
import {
  createDiagram,
  getDiagramsList,
} from "../../redux/diagrams/diagramsApi";

import mainLogo from "../../assets/images/solution_pilot.svg";

const aiAssistantsTabsList: any = [
  { id: 1, name: "Community" },
  { id: 2, name: "My Assistants" },
];

const communityPageTabsList: any = [
  { id: 1, name: "Designs & Documents" }, // A place where users can share their designs and documents
  { id: 2, name: "AI Prompts" }, // A place where users can share their AI prompts
  { id: 3, name: "Jobs" }, // A job board. If companies are looking for an architect, this is where they will find them.
  { id: 4, name: "Network" }, // Other users profiles
];

interface MainHeaderProps {
  setSidebarOpen: Function;
  collapseSidebar: boolean;
}

export default function MainHeader({
  setSidebarOpen,
  collapseSidebar,
}: MainHeaderProps) {
  const [formLoader, setFormLoader] = useState(false);
  const [openTChart, setOpenTChart] = useState(false);
  const [openProsCons, setProsConsOpen] = useState(false);
  const [openDecisionMatrix, setOpenDecisionMatrix] = useState(false);
  const [openSWOT, setOpenSWOT] = useState(false);
  const [openProject, setOpenProject] = useState(false);
  const [openAiAssistant, setOpenAiAssistant] = useState(false);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [documentFormLoader, setDocumentFormLoader] = useState(false);
  const [openDiagramModal, setOpenDiagramModal] = useState(false);
  const [diagramFormLoader, setDiagramFormLoader] = useState(false);

  const navigate = useNavigate();
  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useAppDispatch();
  const { currentPage, templatesCurrentTab } = useAppSelector(
    (state) => state.profile
  );
  const {
    projectsByIdRes,
    getProjectAllDocumentsRes,
    selectedDocument,
    multiSelectedOption,
  } = useAppSelector((state) => state.projects);
  const { getDiagramByIdRes, getDiagramsListRes, selectedDiagram } =
    useAppSelector((state) => state.diagrams);

  const { templateByIdRes } = useAppSelector((state) => state.templates);

  const { currentAiAssistantTab, communityPageTab, getUserAiAssistantRes } =
    useAppSelector((state) => state.userAiAssistants);
  const { prosConsByIdRes } = useAppSelector((state) => state.prosCons);
  const { decisionMatrixByIdRes } = useAppSelector(
    (state) => state.decisionMatrixs
  );
  const { swotByIdRes } = useAppSelector((state) => state.swot);
  const { tbarByIdRes } = useAppSelector((state) => state.tbars);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) await dispatch(getProfileData(accessToken));
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, dispatch]);

  const creatingTbar = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createTbar({ body, accessToken }));
          setOpenTChart(false);
          setFormLoader(false);

          await dispatch(getTbarsList(accessToken));
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitTChart = async (body: any) => {
    body.user_id = user?.sub;

    creatingTbar(body);
  };

  const creatingProsCons = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createProsCons({ body, accessToken }));
          setProsConsOpen(false);
          setFormLoader(false);

          await dispatch(getProsConsList({ accessToken, project_id: "" }));
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitProsCons = async (body: any) => {
    body.user_id = user?.sub;

    creatingProsCons(body);
  };

  const creatingDecisionMatrix = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createDecisionMatrix({ body, accessToken }));
          setOpenDecisionMatrix(false);
          setFormLoader(false);

          await dispatch(getDecisionMatrixList(accessToken));
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitDecisionMatrix = async (body: any) => {
    body.user_id = user?.sub;

    creatingDecisionMatrix(body);
  };

  const creatingSwot = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createSwot({ body, accessToken }));
          setOpenSWOT(false);
          setFormLoader(false);

          await dispatch(getSwotList(accessToken));
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitSwot = async (body: any) => {
    body.user_id = user?.sub;

    creatingSwot(body);
  };

  const creatingProject = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createProject({ body, accessToken })).then((res) => {
            if (res) {
              setOpenProject(false);
              setFormLoader(false);
              gettingProjectsList();
              navigate(`/projects`);
            }
          });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitProject = async (body: any) => {
    body.user_id = user?.sub;

    creatingProject(body);
  };

  const gettingProjectsList = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) await dispatch(getProjectsList(accessToken));
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  const creatingAIAssistant = (body: any) => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          setFormLoader(true);

          await dispatch(createUserAiAssistant({ body, accessToken }))
            .then(() => {
              setOpenAiAssistant(false);
              setFormLoader(false);
            })
            .catch(() => {
              setFormLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  const onSubmitAiAssistant = async (body: any) => {
    creatingAIAssistant(body);
  };

  const onSelectingDocument = (value: any) => {
    dispatch(setSelectedDocument(value));
  };

  const onSelectingDiagram = (value: any) => {
    dispatch(setSelectedDiagram(value));
  };

  const creatingDocument = (body: any) => {
    const fetchData = async () => {
      setDocumentFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createProjectDocument({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          setOpenDocumentModal(false);
          setDocumentFormLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setDocumentFormLoader(false);
      }
    };
    fetchData();
  };

  const creatingDiagram = (body: any) => {
    const fetchData = async () => {
      setDiagramFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createDiagram({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          setOpenDiagramModal(false);
          setDiagramFormLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setDiagramFormLoader(false);
      }
    };
    fetchData();
  };

  return (
    <div id="main_header">
      {/* <div
        className={`fixed top-0 z-40 flex h-12 shrink-0 items-center gap-x-4 px-4 sm:gap-x-6 sm:px-6 lg:px-8 ${
          collapseSidebar ? "lg:ml-[52px]" : "lg:ml-64"
        }`}
        style={{
          width: `calc(100% - ${collapseSidebar ? "30.16px" : "256px"})`,
        }}
      > */}

      <div
        className={`w-full fixed top-0 z-40 flex h-12 shrink-0 items-center gap-x-4 px-5`}
      >
        <div className="flex mr-3">
          <img
            className="h-[30px] cursor-pointer"
            src={mainLogo}
            alt="Solution Pilot"
            onClick={() => navigate("/")}
          />
        </div>

        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        {/* Separator */}
        <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

        <div className="flex items-center justify-between w-full">
          <div className={`${collapseSidebar ? "ml-[10px]" : "-ml-[7px]"}`}>
            {window?.location?.pathname === "/home" && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        // onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Home
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {/** Projects Pages **/}
            {window?.location?.pathname === "/projects" && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Projects
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {window?.location?.pathname ===
              `/projects/${projectsByIdRes?.id}` && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span
                        onClick={() => navigate("/projects")}
                        className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700 cursor-pointer"
                      >
                        Projects
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700 line-clamp-1">
                        {projectsByIdRes?.title}
                      </span>
                    </div>
                  </li>

                  {multiSelectedOption === 2 ? (
                    <>
                      {getDiagramsListRes?.length > 0 && (
                        <li>
                          <div className="flex items-center">
                            <svg
                              className="h-4 w-4 flex-shrink-0 text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                            >
                              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                            </svg>
                            <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                              <DropdownSelectionOption
                                optionsList={getDiagramsListRes}
                                selected={selectedDiagram}
                                setSelected={onSelectingDiagram}
                                onAddingDocument={() =>
                                  setOpenDiagramModal(true)
                                }
                                btnText="Add Diagram"
                              />
                            </span>
                          </div>
                        </li>
                      )}
                    </>
                  ) : (
                    <>
                      {getProjectAllDocumentsRes?.length > 0 && (
                        <li>
                          <div className="flex items-center">
                            <svg
                              className="h-4 w-4 flex-shrink-0 text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                            >
                              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                            </svg>
                            <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                              <DropdownSelectionOption
                                optionsList={getProjectAllDocumentsRes}
                                selected={selectedDocument}
                                setSelected={onSelectingDocument}
                                onAddingDocument={() =>
                                  setOpenDocumentModal(true)
                                }
                                btnText="Add Document"
                              />
                            </span>
                          </div>
                        </li>
                      )}
                    </>
                  )}
                </ol>
              </nav>
            )}

            {/** T Chart Pages **/}
            {window?.location?.pathname === "/t-bar" && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        T-Chart Analysis
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {window?.location?.pathname ===
              `/t-bar/${tbarByIdRes?.tbar_analysis?.details?.ID}` && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span
                        onClick={() => navigate("/t-bar")}
                        className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700 cursor-pointer"
                      >
                        T-Chart Analysis
                      </span>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        {tbarByIdRes?.tbar_analysis?.details?.TBarTitle}
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {/** Pros and Cons Pages **/}
            {window?.location?.pathname === "/pro-con" && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Pros & Cons
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {window?.location?.pathname ===
              `/pro-con/${prosConsByIdRes?.id}` && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span
                        onClick={() => navigate("/pro-con")}
                        className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700 cursor-pointer"
                      >
                        Pros & Cons
                      </span>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        {prosConsByIdRes?.details?.title}
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {/** Decision Matrix Pages **/}
            {window?.location?.pathname === "/decision-matrix" && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Decision Matrix
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {window?.location?.pathname ===
              `/decision-matrix/${decisionMatrixByIdRes?.id}` && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span
                        onClick={() => navigate("/decision-matrix")}
                        className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700 cursor-pointer"
                      >
                        Decision Matrix
                      </span>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        {decisionMatrixByIdRes?.details?.title}
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {/** SWOT Analysis Pages **/}
            {window?.location?.pathname === "/swot-analysis" && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        SWOT Analysis
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {window?.location?.pathname ===
              `/swot-analysis/${swotByIdRes?.id}` && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span
                        onClick={() => navigate("/swot-analysis")}
                        className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700 cursor-pointer"
                      >
                        SWOT Analysis
                      </span>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        {swotByIdRes?.details?.title}
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {/* {window?.location?.pathname === "/home" && (
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {homeTabsList.map((tab: any) => (
                  <span
                    key={tab.id}
                    onClick={() => onSelectTab(tab.id)}
                    className={classNames(
                      tab.id === homeCurrentTab
                        ? "text-amber-500 border-amber-500"
                        : "border-transparent text-customLightBlue hover:border-gray-300 hover:text-gray-700",
                      "whitespace-nowrap border-b-2 py-2 px-2 text-xs font-medium cursor-pointer"
                    )}
                  >
                    {tab.name}
                  </span>
                ))}
              </nav>
            )} */}

            {window?.location?.pathname === "/playground" && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        // onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Ai Prompts
                      </span>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Playground
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {window?.location?.pathname === "/ai-assistants" && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        // onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Ai Prompts
                      </span>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Prompt Book
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {window?.location?.pathname ===
              `/ai-assistant/${getUserAiAssistantRes?.id}` && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        // onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => navigate("/ai-assistants")}
                    >
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Ai Prompts
                      </span>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        {getUserAiAssistantRes?.title}
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {window?.location?.pathname === "/community-templates" && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        // onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Community
                      </span>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Design & Documents
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {window?.location?.pathname === "/community-ai-assistants" && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        // onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Community
                      </span>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Ai Prompts
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {window?.location?.pathname === "/templates" && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        // onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Content Templates
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {window?.location?.pathname ===
              `/template/${templateByIdRes?.project_template?.id}` && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        // onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => navigate("/templates")}
                    >
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Content Templates
                      </span>
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        {templateByIdRes?.project_template?.title}
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {window?.location?.pathname === "/document-components" && (
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <HomeIcon
                        // onClick={() => navigate("/home")}
                        className="h-4 w-4 flex-shrink-0 text-customLightBlue hover:text-gray-700 cursor-pointer"
                        aria-hidden="true"
                      />
                    </div>
                  </li>

                  <li>
                    <div className="flex items-center">
                      <svg
                        className="h-4 w-4 flex-shrink-0 text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <span className="ml-4 text-xs font-medium text-customLightBlue hover:text-gray-700">
                        Document Components
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
            )}

            {window?.location?.pathname === "/community" && (
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {communityPageTabsList.map((tab: any) => (
                  <span
                    key={tab.id}
                    onClick={() => dispatch(setCommunityPageTabs(tab.id))}
                    className={classNames(
                      tab.id === communityPageTab
                        ? "text-amber-500 border-amber-500"
                        : "border-transparent text-customLightBlue hover:border-gray-300 hover:text-gray-700",
                      "whitespace-nowrap border-b-2 py-2 px-2 text-xs font-medium cursor-pointer"
                    )}
                  >
                    {tab.name}
                  </span>
                ))}
              </nav>
            )}
          </div>

          <div className="flex items-center gap-x-2">
            <LoginUserInfo collapseSidebar={collapseSidebar} />
          </div>
        </div>
      </div>

      <CustomDrawer
        open={openTChart}
        onCloseModal={() => setOpenTChart(false)}
        title="T-Chart"
        description="Create new T-Chart, provide title and options that you will try to analyze and compare. Options are mandatory fields."
      >
        <TBarForm
          onCloseModal={() => setOpenTChart(false)}
          editData={null}
          onSubmitForm={onSubmitTChart}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer>

      <CustomDrawer
        open={openProsCons}
        onCloseModal={() => setProsConsOpen(false)}
        title="Pros-Cons"
        description="Create new Pros-Cons, provide title and options that you will try to analyze and compare. Options are mandatory fields."
      >
        <ProConCreationForm
          onCloseModal={() => setProsConsOpen(false)}
          editData={null}
          onSubmitForm={onSubmitProsCons}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer>

      <CustomDrawer
        open={openDecisionMatrix}
        onCloseModal={() => setOpenDecisionMatrix(false)}
        title="Decision Matrix"
        description="Create new Decision Matrix, provide title and options that you will try to analyze and compare. Options are mandatory fields."
      >
        <CreationDecisionMatrixForm
          onCloseModal={() => setOpenDecisionMatrix(false)}
          editData={null}
          onSubmitForm={onSubmitDecisionMatrix}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer>

      <CustomDrawer
        open={openSWOT}
        onCloseModal={() => setOpenSWOT(false)}
        title="SWOT Analysis"
        description="Create new SWOT, provide title and options that you will try to analyze and compare. Options are mandatory fields."
      >
        <CreationSWOTForm
          onCloseModal={() => setOpenSWOT(false)}
          editData={null}
          onSubmitForm={onSubmitSwot}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer>

      <CustomDrawer
        open={openProject}
        onCloseModal={() => setOpenProject(false)}
        title="Create Project"
        description="Create new Project, provide Title, Status and Category. Title is mandatory field."
      >
        <CreateProjectForm
          onCloseModal={() => setOpenProject(false)}
          editData={null}
          onSubmitForm={onSubmitProject}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer>

      <CustomDrawer
        open={openAiAssistant}
        onCloseModal={() => setOpenAiAssistant(false)}
        title="Update AI Assistant"
        description="Create new AI Assistant, provide all the data during create your Assistant."
      >
        <CreateAiAisstantForm
          onCloseModal={() => setOpenAiAssistant(false)}
          editData={null}
          onSubmitForm={onSubmitAiAssistant}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer>

      <CustomModal
        open={openDocumentModal}
        onCloseModal={() => setOpenDocumentModal(false)}
        title="Add Document"
        size="max-w-lg"
      >
        <DocumentForm
          onCloseModal={() => setOpenDocumentModal(false)}
          loading={documentFormLoader}
          onSubmitData={creatingDocument}
          editData={null}
        />
      </CustomModal>

      <CustomModal
        open={openDiagramModal}
        onCloseModal={() => setOpenDiagramModal(false)}
        title="Add Diagram"
        size="max-w-lg"
      >
        <DiagramForm
          onCloseModal={() => setOpenDiagramModal(false)}
          loading={diagramFormLoader}
          onSubmitData={creatingDiagram}
          editData={null}
        />
      </CustomModal>
    </div>
  );
}
