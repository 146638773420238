import {
    AdjustmentsHorizontalIcon,
    ChevronDownIcon,
    CubeIcon,
    DocumentDuplicateIcon,
    EllipsisVerticalIcon,
    LightBulbIcon,
    PencilSquareIcon,
    PuzzlePieceIcon,
    ScaleIcon,
    Squares2X2Icon,
    TableCellsIcon,
    TrashIcon,
} from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { countCharacters, countWords, getLastIdFromUrl } from '../../utils/utilities';
import { updateProject, updateProjectDocument } from '../../redux/projects/projectsApi';
import { useAppDispatch, useAppSelector } from '../../hooks';

import ChatAIModal from '../../pages/projectsDetails/leftSideBar/chatAImodal';
import CustomButton from 'src/components/customButton';
import DropdownSelectionOption from '../../components/dropdownSelectionOption';
import ItemWithDropdown from '../../components/itemWithDropdown';
import SwitchSlide from 'src/components/switchSlide';
import { createConversation } from '../../redux/chatGPT/chatGPTApi';
import { setSelectedAiChat } from '../../redux/chatGPT/chatGPTSlice';
import { useAuth0 } from '@auth0/auth0-react';

interface DocumentHeaderProps {
    value: string;
    updatingProjectDocument: Function;
    documentSaveLoading: boolean;
    onOpenSaveProjectTemp?: Function;
}

export default function DocumentHeader({
    value,
    updatingProjectDocument,
    documentSaveLoading,
    onOpenSaveProjectTemp,
}: DocumentHeaderProps) {
    const [autoSaveLoader, setAutoSaveLoader] = useState(false);
    const [aiChatsModal, setAiChatsModal] = useState(false);

    const selectedDocument = useAppSelector((state) => state.projects.selectedDocument);
    const { projectsByIdRes } = useAppSelector((state) => state.projects);
    const { getAllConversationsRes } = useAppSelector((state) => state.chatGPT);
    const { currentAiAssistantTab } = useAppSelector((state) => state.userAiAssistants);

    const dispatch = useAppDispatch();
    const { getAccessTokenSilently } = useAuth0();

    const updatingProject = () => {
        const fetchData = async () => {
            setAutoSaveLoader(true);

            const body = {
                title: projectsByIdRes?.title,
                status: projectsByIdRes?.status,
                category: projectsByIdRes?.category,
                complexity: projectsByIdRes?.complexity,
                description: projectsByIdRes?.description,
                autosave: !projectsByIdRes?.autosave,
            };
            try {
                const accessToken = await getAccessTokenSilently();

                if (accessToken) {
                    await dispatch(
                        updateProject({
                            body,
                            accessToken,
                            id: projectsByIdRes?.id,
                        })
                    );
                    setAutoSaveLoader(false);
                }
            } catch (error) {
                console.error('Error getting access token:', error);
                setAutoSaveLoader(false);
            }
        };

        fetchData();
    };

    const updatingDocument = async (changes: Record<string, any>) => {
        if (!selectedDocument || !projectsByIdRes) return;

        const body = {
            ...selectedDocument,
            ...changes,
        };

        try {
            const accessToken = await getAccessTokenSilently();

            if (accessToken) {
                await dispatch(
                  updateProjectDocument({
                        body,
                        accessToken,
                        project_id: projectsByIdRes.id,
                        document_id: selectedDocument.id,
                    })
                );
            }
        } catch (error) {
            console.error('Error getting access token:', error);
        }
    };

    const onSelectingAiChat = (value: any) => {
        dispatch(setSelectedAiChat(value));
        updatingDocument({ ai_conversation_id: value.id });
    };

    const creatingConversation = (conversation_configuration_id: string) => {
        const fetchData = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                if (accessToken) {
                    await dispatch(
                        createConversation({
                            body: {},
                            accessToken,
                            project_id: getLastIdFromUrl(window.location.pathname),
                            conversation_configuration_id,
                            template_type:
                                (currentAiAssistantTab === 1 && 'community') ||
                                (currentAiAssistantTab === 2 && 'private'),
                        })
                    ).then((res) => {
                        setAiChatsModal(false);
                    });
                }
            } catch (error) {
                console.error('Error getting access token:', error);
            }
        };
        fetchData();
    };

    const saveAsList = [
        {
            id: 1,
            name: 'Template',
            onActionClick: () => (onOpenSaveProjectTemp ? onOpenSaveProjectTemp(true) : console.log('...')),
            icon: <LightBulbIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
        },
        {
            id: 2,
            name: 'Design Standard',
            onActionClick: () => console.log('...'),
            icon: <PuzzlePieceIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
        },
    ];

    const actionsList = [
        {
            id: 1,
            name: 'Edit',
            onActionClick: () => console.log('...'),
            icon: <PencilSquareIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
        },
        {
            id: 3,
            name: 'Delete',
            onActionClick: () => console.log('...'),
            icon: <TrashIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
        },
    ];

    const validateList = [
        {
            id: 1,
            name: '/Check for limitations',
            onActionClick: () => console.log('...'),
        },
        {
            id: 2,
            name: '/Check for spelling mistakes',
            onActionClick: () => console.log('...'),
        },
        {
            id: 3,
            name: '/Check for improvements',
            onActionClick: () => console.log('...'),
        },
    ];

    const generateList = [
        {
            id: 1,
            name: 'Diagram',
            onActionClick: () => console.log('...'),
            icon: <CubeIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
        },
        {
            id: 2,
            name: 'T-Chart',
            onActionClick: () => console.log('...'),
            icon: <ScaleIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
        },
        {
            id: 3,
            name: 'Pros & Cons',
            onActionClick: () => console.log('...'),
            icon: <TableCellsIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
        },
        {
            id: 4,
            name: 'Decision Martix',
            onActionClick: () => console.log('...'),
            icon: <AdjustmentsHorizontalIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
        },
        {
            id: 5,
            name: 'SWOT',
            onActionClick: () => console.log('...'),
            icon: <Squares2X2Icon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
        },
    ];

    return (
        <>
            <div className='flex justify-between  mx-2'>
                <div className='flex items-center'>
                    {/* <div className="mr-6">
            <div className="flex items-center mr-3 border-b border-amber-200 text-xs">
              <div className="mr-3">
                <span className="text-zinc-400">Words:</span>{" "}
                {countWords(value)}
              </div>
              <div className="">
                <span className="text-zinc-400">Characters:</span>{" "}
                {countCharacters(value)}
              </div>
            </div>
          </div> */}
                    <div className='flex items-center mr-4'>
                        <SwitchSlide
                            setEnabled={() => updatingProject()}
                            enabled={projectsByIdRes?.autosave}
                        />
                        <span className='ml-2 text-xs text-customLightBlue'>Auto Save</span>
                    </div>
                    <div className='flex items-center mr-4'>
                        <SwitchSlide
                            setEnabled={() => updatingDocument({ ai_suggestions: !selectedDocument?.ai_suggestions })}
                            enabled={!!selectedDocument?.ai_suggestions}
                        />
                        <span className='ml-2 text-xs text-customLightBlue'>AI Suggestions</span>
                    </div>
                </div>

                <div className='flex items-center space-x-2'>
                    <div className='ml-4 font-medium text-customLightBlue hover:text-gray-800 border border-zinc-300 pr-2 pl-3 py-1 rounded-md'>
                        <DropdownSelectionOption
                            optionsList={getAllConversationsRes}
                            selected={getAllConversationsRes?.find(({ id }) => id === selectedDocument?.ai_conversation_id)}
                            setSelected={onSelectingAiChat}
                            onAddingDocument={() => setAiChatsModal(true)}
                            btnText='Ai Chat'
                        />
                    </div>

                    <div className='relative'>
                        <ItemWithDropdown actionsData={generateList}>
                            <CustomButton
                                text={
                                    <div className='flex items-center'>
                                        <span>Generate</span>
                                        <ChevronDownIcon className='h-4 ml-2' />
                                    </div>
                                }
                                type='button'
                                outlined
                                onClickBtn={() => console.log('...')}
                                btnStyle='border-zinc-300 text-customLightBlue hover:text-amber-400'
                            />
                        </ItemWithDropdown>
                    </div>

                    <div className='relative'>
                        <ItemWithDropdown actionsData={validateList}>
                            <CustomButton
                                text={
                                    <div className='flex items-center'>
                                        <span>Validate</span>
                                        <ChevronDownIcon className='h-4 ml-2' />
                                    </div>
                                }
                                type='button'
                                outlined
                                onClickBtn={() => console.log('...')}
                                btnStyle='border-zinc-300 text-customLightBlue hover:text-amber-400'
                            />
                        </ItemWithDropdown>
                    </div>

                    <div className='relative'>
                        <ItemWithDropdown actionsData={saveAsList}>
                            <CustomButton
                                text={
                                    <div className='flex items-center'>
                                        <span>Save As</span>
                                        <ChevronDownIcon className='h-4 ml-2' />
                                    </div>
                                }
                                type='button'
                                outlined
                                onClickBtn={() => console.log('...')}
                                btnStyle='border-zinc-300 text-customLightBlue hover:text-amber-400'
                            />
                        </ItemWithDropdown>
                    </div>

                    <CustomButton
                        text='Save Changes'
                        type='button'
                        outlined
                        onClickBtn={() => updatingProjectDocument()}
                        btnStyle='border-zinc-300 text-customLightBlue hover:text-amber-400'
                        beforeIcon={<DocumentDuplicateIcon className='h-4 mr-1' />}
                        loading={documentSaveLoading}
                    />

                    <ItemWithDropdown actionsData={actionsList}>
                        <EllipsisVerticalIcon
                            className='h-6 w-6'
                            aria-hidden='true'
                        />
                    </ItemWithDropdown>
                </div>
            </div>
            {aiChatsModal && (
                <ChatAIModal
                    onCloseModal={() => setAiChatsModal(false)}
                    openModal={aiChatsModal}
                    onUseTemplate={creatingConversation}
                />
            )}
        </>
    );
}
