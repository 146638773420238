import React, { useEffect, useState } from "react";
import CustomButton from "src/components/customButton";
import InputField from "../../../../components/formFields/inputField";

interface OptionFormProps {
  onCloseModal: () => void;
  loading: boolean;
  onSubmitData: Function;
  editData: any;
}

export default function OptionForm({
  onCloseModal,
  loading,
  onSubmitData,
  editData,
}: OptionFormProps) {
  const [name, setName] = useState("");
  const [isName, setIsName] = useState(false);

  useEffect(() => {
    if (editData) {
      if (editData?.title) setName(editData?.title);
     
    }
  }, [editData]);

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    const payload = {
      title: name,
    };

    if (name) {
      onSubmitData(payload);
    } else {
      setIsName(true);
    }
  };

  const onChangeName = (value: string) => {
    setName(value);
    setIsName(false);
  };

  return (
    <form className="flex h-full flex-col" onSubmit={onSubmitForm}>
      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
        <div>
          <label
            htmlFor="title"
            className="block text-xs font-medium leading-6 text-customDarkBlue"
          >
            Name
          </label>
        </div>
        <div className="sm:col-span-2">
          <InputField
            type="text"
            id="title"
            error={isName}
            name="title"
            value={name}
            onChange={(e) => onChangeName(e.target.value)}
          />
        </div>
      </div>

      {/* Action buttons */}
      <div className="w-full text-right mt-5">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-xs font-semibold text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-2"
          onClick={onCloseModal}
        >
          Cancel
        </button>
        <CustomButton
          text="Save"
          type="submit"
          onClickBtn={() => console.log()}
          loading={loading}
        />
      </div>
    </form>
  );
}
