import { ComponentProps, useCallback, useEffect, useRef, useState } from 'react';
import { autoUpdate, shift, useFloating } from '@floating-ui/react-dom';

import ArrowLeftIcon from '@heroicons/react/20/solid/ArrowLeftIcon';
import ArrowRightIcon from '@heroicons/react/20/solid/ArrowRightIcon';
import type { SuggestionsAPI } from 'src/pages/projectsDetails/textEditor/autoSuggestions';
import { classNames } from 'src/utils/utilities';

function Kbd({ className = '', children, optional = false, ...props }: ComponentProps<'kbd'> & { optional?: boolean }) {
    return (
        <kbd
            className={classNames(
                'mx-1 min-w-5 h-5 px-1 rounded-[0.25rem] border-[1px] border-gray-400',
                'flex items-center justify-center bg-white font-semibold text-gray-900',
                optional && 'opacity-50',
                className
            )}
            {...props}
        >
            {children}
        </kbd>
    );
}

function NavButton({ className, children, ...props }: ComponentProps<'button'>) {
    return (
        <button
            type='button'
            className={classNames(
                'py-1 px-3 rounded-md border-[1px]',
                'transition-colors hover:bg-amber-50 hover:border-amber-400',
                className
            )}
            tabIndex={-1}
            {...props}
        >
            {children}
        </button>
    );
}

export default function AiSuggestionsTooltip({
    className = '',
    suggestionsApi,
    ...props
}: ComponentProps<'div'> & { suggestionsApi: SuggestionsAPI | null }) {
    const [isVisible, setIsVisible] = useState(false);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
    const placeholderRef = useRef<HTMLElement | null>(null);

    const { refs, floatingStyles } = useFloating({
        placement: 'top-start',
        whileElementsMounted: autoUpdate,
        middleware: [shift({ padding: { right: 42 } })],
    });

    const handleMouseEnterOrLeave = useCallback(() => {
        const { current: reference } = placeholderRef;
        const {
            floating: { current: floating },
        } = refs;

        if (!reference) {
            return setIsVisible(false);
        }

        setIsVisible(reference.matches(':hover') || !!floating?.matches(':hover'));
    }, [refs]);

    useEffect(() => {
        return suggestionsApi?.onPlaceholder((type, { element, anchor }) => {
            if (type === 'enter') {
                refs.setReference(anchor);
                placeholderRef.current = element;
                element.addEventListener('mouseenter', handleMouseEnterOrLeave);
                element.addEventListener('mouseleave', handleMouseEnterOrLeave);
            } else if (type === 'exit') {
                refs.setReference(null);
                placeholderRef.current = null;
                element.removeEventListener('mouseenter', handleMouseEnterOrLeave);
                element.removeEventListener('mouseleave', handleMouseEnterOrLeave);
            }
        });
    }, [suggestionsApi, refs, handleMouseEnterOrLeave]);

    useEffect(() => {
        return suggestionsApi?.onUpdate((suggestions, currentIndex) => {
            setSuggestions(suggestions);
            setCurrentSuggestionIndex(currentIndex);
        });
    }, [suggestionsApi]);

    if (!isVisible || !suggestionsApi || suggestions.length === 0) return null;

    return (
        <div
            ref={refs.setFloating}
            className={classNames(
                'max-w-[28rem] w-full flex flex-col rounded-lg border-[1px] z-50 shadow-lg',
                className
            )}
            style={floatingStyles}
            onMouseEnter={handleMouseEnterOrLeave}
            onMouseLeave={handleMouseEnterOrLeave}
            onClick={() => {
                // return the focus back to editor so keyboard shortcuts work
                suggestionsApi.focusEditor();
            }}
            {...props}
        >
            <div className='py-2 px-4 flex items-center justify-between bg-gray-50 rounded-t-lg border-b-[1px]'>
                <p className='text-sm font-medium text-gray-900'>
                    Suggestion {currentSuggestionIndex + 1}/{suggestions.length}
                </p>
                <div className='group flex items-center bg-white text-gray-700'>
                    <NavButton
                        className='rounded-r-none group-hover:border-r-0 hover:!border-r-[1px]'
                        onClick={suggestionsApi.prev}
                    >
                        <ArrowLeftIcon className='h-5' />
                    </NavButton>
                    <NavButton
                        className='rounded-l-none border-l-0 hover:border-l-[1px]'
                        onClick={suggestionsApi.next}
                    >
                        <ArrowRightIcon className='h-5' />
                    </NavButton>
                </div>
            </div>
            <p className='w-full p-4 text-sm bg-white text-gray-500 transition-colors duration-150 ease-in-out'>
                {suggestions[currentSuggestionIndex]}
            </p>
            <div className='basis-full flex flex-wrap items-center bg-gray-50 px-4 py-[0.625rem] text-xs text-gray-700 border-t-[1px] rounded-b-lg'>
                <Kbd>Tab</Kbd> to accept,
                <Kbd>Ctrl</Kbd>{' '}
                <Kbd>
                    <ArrowRightIcon className='h-4' />
                </Kbd>{' '}
                to accept a word,
                <Kbd>Esc</Kbd> to reject
            </div>
        </div>
    );
}
