import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import Detials from "./Details";
import Analysis from "./Analysis";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getProsConsById } from "../../redux/prosCons/prosConsApi";
import { getLastIdFromUrl } from "../../utils/utilities";
import WrapperLoader from "../../components/wrapperLoader";
import { getQueryParams } from "../../utils/utilities";

// const tabs: any = [
//   { id: 1, name: "Details", current: true },
//   { id: 2, name: "Analysis", current: false },
// ];

export default function ProsConsDetails() {
  // const [tabsList, setProsConsList] = useState(tabs);
  // const [activeId, setActiveId] = useState(1);
  const [prosConsByIdLoader, setProsConsByIdLoader] = useState(false);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { prosConsByIdRes } = useAppSelector((state) => state.prosCons);
  const queryStringObj = getQueryParams(window.location.href);

  useEffect(() => {
    const fetchData = async () => {
      setProsConsByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(
            getProsConsById({
              accessToken,
              id: queryStringObj?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        setProsConsByIdLoader(false);
      } catch (error) {
        console.error("Error getting access token:", error);
        setProsConsByIdLoader(false);
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, dispatch]);

  // const onSelectTab = (id: number) => {
  //   const updatedProsConsList = tabsList.map((item: any) => ({
  //     ...item,
  //     current: item.id === id,
  //   }));

  //   setProsConsList(updatedProsConsList);
  //   setActiveId(id);
  // };

  // const pageTitle = prosConsByIdRes?.details?.title;

  return (
    <WrapperLoader loading={prosConsByIdLoader}>
      <div className="p-6 pb-0">
        {/* <DetailPageHead
          title={pageTitle}
          backUrl={`${window.location.pathname}?active=pros-cons`}
        /> */}

        {/* <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabsList.map((tab: any) => (
              <span
                key={tab.id}
                onClick={() => onSelectTab(tab.id)}
                className={classNames(
                  tab.current
                    ? "text-amber-500 border-amber-500"
                    : "border-transparent text-customLightBlue hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 py-4 px-1 text-xs font-medium cursor-pointer"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div> */}
        <div>
          <Analysis detailsData={prosConsByIdRes} />
          {/* {activeId === 1 && (
            <div className="bg-white rounded-xl shadow-lg pt-6 px-6 h-[calc(100vh_-_190px)] overflow-y-auto">
              <Detials detailsData={prosConsByIdRes} />
            </div>
          )}
          {activeId === 2 && <Analysis detailsData={prosConsByIdRes} />} */}
        </div>
      </div>
    </WrapperLoader>
  );
}
