import React from "react";
import { PlusIcon } from "@heroicons/react/24/outline";

interface RoundButtonProps {
  onClickBtn: () => void;
}

export default function RoundButton({ onClickBtn }: RoundButtonProps) {
  return (
    <button
      onClick={onClickBtn}
      type="button"
      className="rounded-full bg-amber-400 p-1 text-white shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
    >
      <PlusIcon className="h-4 w-4" aria-hidden="true" />
    </button>
  );
}
