import { createSlice } from "@reduxjs/toolkit";
import {
  getTemplatesList,
  getTemplateById,
  createTemplate,
  deleteTemplate,
  createProjectFromTemplate,
  getPublicTemplatesList,
  getPublicTemplateById,
  publishProjectTemplate,
  unPublishProjectTemplate,
  getPrivateTemplateDocument,
  getPublicTemplateDocument,
  getPublicDocumentsTemplatesList,
  getPrivateDocumentsTemplatesList,
  getPrivateDocumentTemplate,
  createDocumentFromPublicTemplate,
  createDocumentFromPrivateTemplate
} from "./templatesApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  templatesList: any[];
  createTemplatesRes: any;
  templateByIdRes: any;
  templateDeleteRes: any;
  createProjectFromTemplateRes: any;
  getPublicTemplatesListRes: any;
  getPublicTemplateByIdRes: any;
  publishProjectTemplateRes: any;
  unPublishProjectTemplateRes: any;
  getPrivateTemplateDocumentRes: any;
  getPublicTemplateDocumentRes: any;
  getPublicDocumentsTemplatesListRes: any;
  getPrivateDocumentsTemplatesListRes: any;
  getPrivateDocumentTemplateRes: any;
  createDocumentFromPublicTemplateRes: any;
  createDocumentFromPrivateTemplateRes: any;
}
const initialState: initialStateType = {
  error: null,
  message: null,
  templatesList: [],
  createTemplatesRes: null,
  templateByIdRes: null,
  templateDeleteRes: null,
  createProjectFromTemplateRes: null,
  getPublicTemplatesListRes: null,
  getPublicTemplateByIdRes: null,
  publishProjectTemplateRes: null,
  unPublishProjectTemplateRes: null,
  getPrivateTemplateDocumentRes: null,
  getPublicTemplateDocumentRes: null,
  getPublicDocumentsTemplatesListRes: null,
  getPrivateDocumentsTemplatesListRes: null,
  getPrivateDocumentTemplateRes: null,
  createDocumentFromPublicTemplateRes: null,
  createDocumentFromPrivateTemplateRes: null,
};
const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Templates List
    builder.addCase(getTemplatesList.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getTemplatesList.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.templatesList = action.payload?.data;
    });
    builder.addCase(getTemplatesList.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get Template By Id
    builder.addCase(getTemplateById.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getTemplateById.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.templateByIdRes = action.payload?.data;
    });
    builder.addCase(getTemplateById.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create Template
    builder.addCase(createTemplate.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createTemplate.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.createTemplatesRes = action.payload?.data;
    });
    builder.addCase(createTemplate.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete Template
    builder.addCase(deleteTemplate.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deleteTemplate.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.templateDeleteRes = action.payload?.data;
    });
    builder.addCase(deleteTemplate.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create Project From Template
    builder.addCase(createProjectFromTemplate.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createProjectFromTemplate.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.createProjectFromTemplateRes = action.payload?.data;
    });
    builder.addCase(createProjectFromTemplate.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get Public Templates List
    builder.addCase(getPublicTemplatesList.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getPublicTemplatesList.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.getPublicTemplatesListRes = action.payload?.data;
    });
    builder.addCase(getPublicTemplatesList.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get Public Template By Id
    builder.addCase(getPublicTemplateById.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getPublicTemplateById.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.getPublicTemplateByIdRes = action.payload?.data;
    });
    builder.addCase(getPublicTemplateById.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Publish Template
    builder.addCase(publishProjectTemplate.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(publishProjectTemplate.fulfilled, (state, action) => {
      const previousData = [...state.templatesList];
      const previousIndex = previousData?.findIndex(
        (item) => item?.id === action.payload?.data?.id
      );

      if (previousIndex !== -1) {
        previousData[previousIndex].privacy = false;
        state.templatesList = previousData;
      }

      // Template Detail
      state.templateByIdRes = {
        ...state.templateByIdRes,
        project_template: {
          ...state.templateByIdRes.project_template,
          privacy: false,
          public_template_ref: action.payload?.data?.id,
        },
      };

      state.error = null;
      state.message = action.payload?.message;
      state.publishProjectTemplateRes = action.payload?.data;
    });
    builder.addCase(publishProjectTemplate.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // unPublish Template
    builder.addCase(unPublishProjectTemplate.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(unPublishProjectTemplate.fulfilled, (state, action) => {
      const previousData = [...state.templatesList];
      const previousIndex = previousData?.findIndex(
        (item) => item?.id === action.payload?.data?.id
      );

      if (previousIndex !== -1) {
        previousData[previousIndex].privacy = true;
        state.templatesList = previousData;
      }

      // Template Detail
      state.templateByIdRes = {
        ...state.templateByIdRes,
        project_template: {
          ...state.templateByIdRes.project_template,
          privacy: true,
          public_template_ref: null,
        },
      };

      state.error = null;
      state.message = action.payload?.message;
      state.unPublishProjectTemplateRes = action.payload?.data;
    });
    builder.addCase(unPublishProjectTemplate.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get Private Template Document
    builder.addCase(getPrivateTemplateDocument.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getPrivateTemplateDocument.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.getPrivateTemplateDocumentRes = action.payload?.data;
    });
    builder.addCase(getPrivateTemplateDocument.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get Public Template Document
    builder.addCase(getPublicTemplateDocument.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getPublicTemplateDocument.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.getPublicTemplateDocumentRes = action.payload?.data;
    });
    builder.addCase(getPublicTemplateDocument.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get Public Documents List
    builder.addCase(getPublicDocumentsTemplatesList.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(
      getPublicDocumentsTemplatesList.fulfilled,
      (state, action) => {
        state.error = null;
        state.message = action.payload?.message;
        state.getPublicDocumentsTemplatesListRes = action.payload?.data;
      }
    );
    builder.addCase(
      getPublicDocumentsTemplatesList.rejected,
      (state, action) => {
        state.error = action.error.message || "Something went wrong";
      }
    );

    // Get Private Documents List
    builder.addCase(getPrivateDocumentsTemplatesList.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(
      getPrivateDocumentsTemplatesList.fulfilled,
      (state, action) => {
        state.error = null;
        state.message = action.payload?.message;
        state.getPrivateDocumentsTemplatesListRes = action.payload?.data;
      }
    );
    builder.addCase(
      getPrivateDocumentsTemplatesList.rejected,
      (state, action) => {
        state.error = action.error.message || "Something went wrong";
      }
    );

    // Get Private Document By Id
    builder.addCase(getPrivateDocumentTemplate.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getPrivateDocumentTemplate.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.getPrivateDocumentTemplateRes = action.payload?.data;
    });
    builder.addCase(getPrivateDocumentTemplate.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create Document from Public Document Template
    builder.addCase(createDocumentFromPublicTemplate.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(
      createDocumentFromPublicTemplate.fulfilled,
      (state, action) => {
        state.error = null;
        state.message = action.payload?.message;
        state.createDocumentFromPublicTemplateRes = action.payload?.data;
      }
    );
    builder.addCase(
      createDocumentFromPublicTemplate.rejected,
      (state, action) => {
        state.error = action.error.message || "Something went wrong";
      }
    );

    // Create Document from Private Document Template
    builder.addCase(createDocumentFromPrivateTemplate.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(
      createDocumentFromPrivateTemplate.fulfilled,
      (state, action) => {
        state.error = null;
        state.message = action.payload?.message;
        state.createDocumentFromPrivateTemplateRes = action.payload?.data;
      }
    );
    builder.addCase(
      createDocumentFromPrivateTemplate.rejected,
      (state, action) => {
        state.error = action.error.message || "Something went wrong";
      }
    );
  },
});

export default templatesSlice.reducer;
