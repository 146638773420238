// amazon_aws.js

export const software_nodes = [
  {
    name: "Other",
    items: [
      "golang",
      "html-5",
      "javascript",
      "nextjs",
      "nodejs",
      "python",
      "react-native",
      "react",
      "typescript"
    ],
  },
];