import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { useAppDispatch } from "../../../../hooks";
import {
  getProsConsList,
  createProsCons,
} from "../../../../redux/prosCons/prosConsApi";
import CustomDrawer from "../../../../components/customDrawer";
import CreationForm from "../../../prosCons/CreationForm";
import { prosConsIcon } from "../../../../assets/icons";
import CompleteEmptyPage from "../../../../components/completeEmptyPage";
import { getLastIdFromUrl } from "../../../../utils/utilities";

import prosConsSVG from "../../../../assets/images/Illustration-06.svg";

const ProsConsEmpty = () => {
  const [open, setOpen] = useState(false);
  const [formLoader, setFormLoader] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    setOpen(false);
  };

  const onOpenCreateModal = () => {
    setOpen(true);
  };

  const creatingProsCons = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createProsCons({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          onCloseModal();
          setFormLoader(false);

          await dispatch(
            getProsConsList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitProsCons = async (body: any) => {
    creatingProsCons(body);
  };

  return (
    <>
      <div
        className="flex items-center justify-center"
        style={{ height: "calc(100vh - 140px)" }}
      >
        <CompleteEmptyPage
          title="You don't have any Pros-Cons"
          subTitle="Get started by creating a new Pros-Cons"
          btnText="Create Pros-Cons"
          onClickBtn={onOpenCreateModal}
          icon={prosConsSVG}
        />
      </div>

      <CustomDrawer
        open={open}
        onCloseModal={onCloseModal}
        title={
          <div className="flex items-center">
            <span className="mr-2">{prosConsIcon}</span>
            <span>Pros-Cons</span>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer"
              className="text-xs text-yellow-500 hover:text-yellow-800 flex items-center font-semibold ml-3"
            >
              <span className="mr-1">Learn More</span>
              <ArrowTopRightOnSquareIcon className="h-3" />
            </a>
          </div>
        }
        description="Create new Pros-Cons, provide title and options that you will try to analyze and compare. Options are mandatory fields."
      >
        <CreationForm
          onCloseModal={onCloseModal}
          editData={null}
          onSubmitForm={onSubmitProsCons}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer>
    </>
  );
};

export default ProsConsEmpty;
