import { memo, useEffect, useRef } from "react";
import {
  NodeProps,
  NodeToolbar,
  useReactFlow,
  useStore,
  useStoreApi,
  NodeResizer,
  Handle,
  Position,
} from "@xyflow/react";
import debounce from "lodash/debounce";
import {
  TrashIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import useDetachNodes from "./useDetachNodes";
import { getRelativeNodesBounds } from "./utils";

const lineStyle = { borderColor: "white" };

function GroupNode({ id, data }: NodeProps) {
  const store = useStoreApi();
  const { deleteElements } = useReactFlow();
  const detachNodes = useDetachNodes();
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const { minWidth, minHeight, hasChildNodes } = useStore((store) => {
    const childNodes = Array.from(store.nodeLookup.values()).filter(
      (n) => n.parentId === id
    );
    const rect = getRelativeNodesBounds(childNodes);

    return {
      minWidth: rect.x + rect.width,
      minHeight: rect.y + rect.height,
      hasChildNodes: childNodes.length > 0,
    };
  }, isEqual);

  const onDelete = () => {
    deleteElements({ nodes: [{ id }] });
  };

  const onDetach = () => {
    const childNodeIds = Array.from(store.getState().nodeLookup.values())
      .filter((n) => n.parentId === id)
      .map((n) => n.id);

    detachNodes(childNodeIds, id);
  };

  const handleResize = debounce(() => {
    // Any resize logic that might cause the ResizeObserver warning can be placed here.
  }, 100);

  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) return;

    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
    }

    resizeObserverRef.current = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserverRef.current.observe(element);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const iconStyle = "h-4 m-1 cursor-pointer text-zinc-800";

  return (
    <div
      id={id}
      className={`rounded-md h-full`}
      style={{ border: `${data?.border_style} 2px ${data?.color}` }}
    >
      <div className="w-full flex items-center mb-2 h-[54px]">
        <div className={`rounded-br-md mr-2 p-3`} style={{ background: `${data?.color}` }}>
          <img
            className="h-[30px]"
            src={`${data?.icon_url}`}
            alt={`${data?.label}`}
          />
        </div>
        <div
          className={`text-[${data?.color}] text-sm`}
        >{`${data?.label}`}</div>
      </div>

      <NodeResizer
        lineStyle={lineStyle}
        minHeight={minHeight}
        minWidth={minWidth}
      />
      <NodeToolbar
        style={{
          borderRadius: 300,
          boxShadow: "0 0 15px 5px rgba(0, 0, 0, 0.1)",
        }}
        className="nodrag bg-white px-2 flex items-center justify-center"
      >
        <TrashIcon
          data-tooltip-id="delete-node-tooltip"
          onClick={onDelete}
          className={`${iconStyle} hover:text-red-600`}
        />

        {hasChildNodes && (
          <ArrowTopRightOnSquareIcon
            data-tooltip-id="ungroup-node-tooltip"
            onClick={onDetach}
            className={`${iconStyle} hover:text-blue-600`}
          />
        )}
      </NodeToolbar>

      <Tooltip className="z-[50]" id="delete-node-tooltip" place="top">
        Delete
      </Tooltip>

      <Tooltip className="z-[50]" id="ungroup-node-tooltip" place="top">
        Ungroup
      </Tooltip>

      <Handle
        className="w-[100px] h-[100px] text-lg"
        type="target"
        position={Position.Top}
        id="top-target"
      />
      <Handle type="target" position={Position.Bottom} id="bottom-target" />
      <Handle type="target" position={Position.Left} id="left-target" />
      <Handle type="target" position={Position.Right} id="right-target" />

      <Handle type="source" position={Position.Top} id="top-source" />
      <Handle type="source" position={Position.Bottom} id="bottom-source" />
      <Handle type="source" position={Position.Left} id="left-source" />
      <Handle type="source" position={Position.Right} id="right-source" />
    </div>
  );
}

type IsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
  hasChildNodes: boolean;
};

function isEqual(prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  );
}

export default memo(GroupNode);
