import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { UserIcon, ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getProfileData } from "../../redux/profile/profileApi";
import CustomButton from '../customButton';

export default function LoginUserInfo({ collapseSidebar }: any) {
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { profileData } = useAppSelector<any>((state: any) => state.profile);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) await dispatch(getProfileData(accessToken));
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, dispatch]);

  const logoutWithRedirect = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const userNavigation = [
    {
      name: "Your profile",
      icon: <UserIcon className="h-4 text-zinc-500" />,
      href: () => navigate("/profile"),
    },
    {
      name: "Sign out",
      icon: <ArrowRightOnRectangleIcon className="h-4 text-zinc-500" />,
      href: () => logoutWithRedirect(),
    },
  ];

  return (
    <Menu
      as="div"
      className={`relative ${
        collapseSidebar ? "flex items-center justify-center z-[300]" : ""
      }`}
    >
      <Menu.Button
        className={`${collapseSidebar ? "" : "-m-1.5 p-1.5"} flex items-center`}
      >
        <img
          className="h-8 w-8 rounded-full"
          src={user?.picture}
          alt={profileData?.email}
        />
        <span className="hidden lg:flex lg:items-center">
          {profileData && (
            <div className="ml-3 text-left">
              <span
                className="block text-xs font-semibold text-customDarkBlue leading-4"
                aria-hidden="true"
              >
                {`${profileData?.first_name} ${profileData?.last_name}`}
              </span>
              <span
                className="block text-xs font-normal text-customLightBlue"
                aria-hidden="true"
              >
                {profileData?.email}
              </span>
            </div>
          )}
        </span>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className={`absolute left-[-100px] top-[35px] mt-2.5 w-[280px] border border-zinc-200 origin-top-right p-6 rounded-2xl bg-[#ffffff] shadow-lg`}
        >
          <div className="text-center">
            <h3 className="text-xs mb-4">{profileData?.email}</h3>
            <img
              className="h-16 w-16 border-2 border-amber-400 rounded-full mb-1 mx-auto"
              src={user?.picture}
              alt={profileData?.email}
            />

            <div className="text-xs mb-3">{`${profileData?.first_name || profileData?.last_name ? `Hi, ${profileData?.first_name} ${profileData?.last_name}` : ""}`}</div>
            <CustomButton
              text="Manage You Account"
              type="button"
              onClickBtn={() => navigate("/profile")}
              outlined
              btnStyle="text-xs mb-4 rounded-3xl px-6"
            />

            <div
              onClick={() => logoutWithRedirect()}
              className="bg-zinc-100 text-orange-500 rounded-full py-3 px-6 w-full flex items-center text-xs cursor-pointer hover:bg-zinc-200"
            >
              <ArrowRightOnRectangleIcon className="h-4 text-orange-500 mr-1" />
              Sign Out
            </div>
          </div>
        </div>
      </Transition>
    </Menu>
  );
}
