import { createSlice } from "@reduxjs/toolkit";
import {
  promptMessage,
  sendChatGPTMessage,
  getAllConversations,
  getConversation,
  createConversation,
  updateConversation,
  deleteConversations,
  getAllMessages,
  getAllAiAssistants,
  getSingleAiAssistant,
  getAllPublishedAiAssistants,
} from "./chatGPTApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  sendChatGPTMessageRes: any[];
  promptMessageRes: any;
  getAllConversationsRes: any[];
  getConversationRes: any;
  createConversationRes: any;
  updateConversationRes: any;
  deleteConversationsRes: any;
  getAllMessagesRes: any[];
  getAllAiAssistantsRes: any[];
  getSingleAiAssistantRes: any;
  getAllPublishedAiAssistantsRes: any[];
  selectedAiChat: any;
  
}
const initialState: initialStateType = {
  error: null,
  message: null,
  promptMessageRes: null,
  sendChatGPTMessageRes: [],
  getAllConversationsRes: [],
  getConversationRes: null,
  createConversationRes: null,
  updateConversationRes: null,
  deleteConversationsRes: null,
  getAllMessagesRes: [],
  getAllAiAssistantsRes: [],
  getSingleAiAssistantRes: null,
  getAllPublishedAiAssistantsRes: [],
  selectedAiChat: null,

};
const chatGptSlice = createSlice({
  name: "chatGPT",
  initialState,
  reducers: {
    setSelectedAiChat: (state, action) => {
      state.selectedAiChat = action.payload;
    },

  },
  extraReducers: (builder) => {
    // Send Message to Chat GPT
    builder.addCase(sendChatGPTMessage.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(sendChatGPTMessage.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;

      state.sendChatGPTMessageRes = [
        ...state.sendChatGPTMessageRes,
        { role: "user", content: action.payload?.question },
        { role: "assistant", content: action.payload?.ai_response?.at(0) },
      ];
    });
    builder.addCase(sendChatGPTMessage.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get All Messages
    builder.addCase(getAllMessages.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getAllMessages.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload;

      state.getAllMessagesRes = action?.payload?.data;
    });
    builder.addCase(getAllMessages.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Send Prompt to Chat GPT
    builder.addCase(promptMessage.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(promptMessage.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;

      state.promptMessageRes = action?.payload?.data;
    });
    builder.addCase(promptMessage.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get All Conversations
    builder.addCase(getAllConversations.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getAllConversations.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload;

      state.getAllConversationsRes = action?.payload?.data;
    });
    builder.addCase(getAllConversations.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get Single Conversation
    builder.addCase(getConversation.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getConversation.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload;

      state.getConversationRes = action.payload?.data;
    });
    builder.addCase(getConversation.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create Conversation
    builder.addCase(createConversation.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createConversation.fulfilled, (state, action) => {
      const previousData = [...(state.getAllConversationsRes || [])];
      state.getAllConversationsRes = [...previousData, action.payload?.data];

      state.error = null;
      state.message = action.payload?.message;
      state.createConversationRes = action.payload?.data;
    });
    builder.addCase(createConversation.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update Conversation
    builder.addCase(updateConversation.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateConversation.fulfilled, (state, action) => {
      const previousData = [...state.getAllConversationsRes];
      const previousIndex = previousData?.findIndex(
        (item) => item?.id === action.payload?.data?.id
      );
      if (previousIndex !== -1) {
        previousData[previousIndex] = action.payload.data;
        state.getAllConversationsRes = previousData;
      }

      state.error = null;
      state.message = action.payload?.message;
      state.updateConversationRes = action.payload.data;
    });
    builder.addCase(updateConversation.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete Conversation
    builder.addCase(deleteConversations.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deleteConversations.fulfilled, (state, action) => {
      const filterData = state.getAllConversationsRes?.filter(
        (item: { id: string }) => item?.id !== action.payload?.data?.id
      );
      state.getAllConversationsRes = filterData;

      state.error = null;
      state.message = action.payload?.message;
      state.deleteConversationsRes = action.payload?.data;
    });
    builder.addCase(deleteConversations.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get All Templates
    builder.addCase(getAllAiAssistants.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getAllAiAssistants.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.data;

      state.getAllAiAssistantsRes = action?.payload?.data;
    });
    builder.addCase(getAllAiAssistants.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get Single Template
    builder.addCase(getSingleAiAssistant.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getSingleAiAssistant.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload;

      state.getSingleAiAssistantRes = action.payload?.data;
    });
    builder.addCase(getSingleAiAssistant.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get All Published AI Assistants
    builder.addCase(getAllPublishedAiAssistants.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getAllPublishedAiAssistants.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.data;

      state.getAllPublishedAiAssistantsRes = action?.payload?.data;
    });
    builder.addCase(getAllPublishedAiAssistants.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { setSelectedAiChat } = chatGptSlice.actions;
export default chatGptSlice.reducer;
