import React, { useEffect, useState } from "react";
import {
  UserGroupIcon,
  CreditCardIcon,
  UserIcon,
  BuildingOfficeIcon,
  BookmarkSquareIcon,
  BookOpenIcon,
} from "@heroicons/react/20/solid";
import { categoriesList } from "../../utils/utilities";
// import { getRawIconObjectByName } from "../../utils/documentComponentsIcons";


interface CategoriesLeftSidebarProps {
  setSelectedCategory: Function;
  selectedCategory: { value: string };
}

export function CategoriesLeftSidebar({
  setSelectedCategory,
  selectedCategory,
}: CategoriesLeftSidebarProps) {
  const [categoriesListNew, setCategoriesListNew] = useState<any>([]);

  useEffect(() => {
    let categoriesData = [...categoriesList];

    categoriesData.unshift({ value: "All" });

    setCategoriesListNew(categoriesData);
  }, []);

  const showIcon = (item: any) => {
    const classStyle = `h-4 group-hover:text-white ${selectedCategory?.value === item.value ? "text-white" : "text-zinc-400"}`;
    if (item.value === "All") {
      return <UserGroupIcon className={classStyle} />;
    } else if (item.value === "Azure") {
      return <CreditCardIcon className={classStyle} />;
    } else if (item.value === "AI") {
      return <UserIcon className={classStyle} />;
    } else if (item.value === "DevOps") {
      return <BuildingOfficeIcon className={classStyle} />;
    } else if (item.value === "AWS") {
      return <BookmarkSquareIcon className={classStyle} />;
    } else if (item.value === "GCP") {
      return <BookOpenIcon className={classStyle} />;
    } else if (item.value === "Software") {
      return <UserIcon className={classStyle} />;
    } else if (item.value === "Strategy") {
      return <UserIcon className={classStyle} />;
    } else if (item.value === "Business") {
      return <UserIcon className={classStyle} />;
    } else if (item.value === "General Purpose") {
      return <UserIcon className={classStyle} />;
    }
  };

  return (
    <div className="mr-5 w-full">
      <div className="scrollarea app-center-category-scroll-area overflow-hidden">
        <div
          className="scrollarea-content"
          tabIndex={1}
          style={{ marginTop: 0 }}
        >
          <div className="mr-1.5 flex flex-col gap-2 overflow-hidden">
            {categoriesListNew?.length > 0 &&
              categoriesListNew.map(
                (data: { value: string }, index: number) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setSelectedCategory(data)}
                      className={`bg-primary-10 fill-primary text-xs group flex cursor-pointer items-center gap-3 rounded-lg bg-gradient-to-r px-4 py-2 hover:from-amber-500 hover:to-amber-400 hover:text-white ${selectedCategory?.value === data.value ? "from-amber-500 to-amber-400 text-white" : ""}`}
                    >
                      {showIcon(data)}
                      <span>{data.value}</span>
                    </div>
                  );
                }
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
