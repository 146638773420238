import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import innerRoutes from "../routes/innerRoutes";
import ComponentRender from "../routes/componentRender";
import Layout from "./Layout";

const MainComponent = () => {
  return (
    <Layout>
      <Routes>
        {innerRoutes?.map((data: any) =>
          data?.subRoutes?.length > 0 ? (
            <Route index={data?.index} key={data.path} path={data?.path}>
              {data?.subRoutes?.map((subdata: any) => (
                <Route
                  index={subdata?.index}
                  key={subdata.path}
                  path={subdata?.path}
                  element={<ComponentRender Component={subdata?.component} />}
                />
              ))}
            </Route>
          ) : (
            <Route
              index={data?.index}
              key={data.path}
              path={data?.path}
              element={<ComponentRender Component={data?.component} />}
            />
          )
        )}
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </Layout>
  );
};

export default MainComponent;
