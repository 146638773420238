import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {
  ArrowDownCircleIcon,
  TrashIcon,
  ArrowUpOnSquareStackIcon,
  ArrowTopRightOnSquareIcon,
  PencilIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../hooks";
import WrapperLoader from "../../components/wrapperLoader";
import SearchField from "../../components/searchField";

import TemplateBox from "../../components/templateBox";
import AIChatTempDetail from "../../components/aiChatTempDetail";
import { createConversation } from "../../redux/chatGPT/chatGPTApi";
import CustomButton from "../../components/customButton";
import CustomModal from "../../components/customModal";
import ProjectsSelection from "./ProjectsSelectionForm";
import {
  getUserAllAiAssistants,
  createUserAiAssistant,
  updateUserAiAssistant,
  deleteUserAiAssistant,
  getUserAiAssistant,
  publishUserAiAssistant,
  unPublishUserAiAssistant,
} from "../../redux/userAiAssistants/userAiAssistantsApi";
import { getAllPublishedAiAssistants } from "../../redux/chatGPT/chatGPTApi";
import CompleteEmptyPage from "../../components/completeEmptyPage";
import CustomDrawer from "../../components/customDrawer";
import ConfirmationModal from "../../components/confirmationModal";
import CreationForm from "./CreationForm";
import PublishConfirmation from "../../components/publishConfirmation";
import { getLastIdFromUrl } from "src/utils/utilities";
import CommunityleftMenu from "../communityPage/CommunityleftMenu";
import { CategoriesLeftSidebar } from "../../components/categoriesLeftSidebar";

import aiSVG from "../../assets/images/Illustration-09.svg";

interface AiAssistantsDataProps {
  isModal: boolean;
  onUseWithModal?: Function;
  tempType: string;
  selectedCategory?: any;
}

const AiAssistantsData = ({
  isModal,
  onUseWithModal,
  tempType,
  selectedCategory,
}: AiAssistantsDataProps) => {
  const [aiAssistantsCompleteList, setAiAssistantsCompleteList] = useState<any>(
    []
  );
  const [search, setSearch] = useState("");
  const [showTempDetail, setShowTempDetail] = useState<any>(null);
  const [allTemplatesLoader, setAllTemplatesLoader] = useState(false);
  const [selectProjectLoader, setSelectProjectLoader] = useState(false);
  const [projectSelectionModal, setProjectSelectionModal] = useState(false);
  const [selectedAIChatId, setSelectedAIChatId] = useState("");

  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState<any>(null);
  const [formLoader, setFormLoader] = useState(false);
  const [assistantByIdLoader, setAssistantByIdLoader] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState<any>(null);
  const [confirmationLoader, setConfirmationLoader] = useState(false);
  const [publishingLoader, setPublishingLoader] = useState(false);
  const [publishConfirmation, setPublishConfirmation] = useState(false);
  const [selectedMainCategory, setSelectedMainCategory] = useState({
    value: "All",
  });
  const [checkAiTemplatesList, setCheckAiTemplatesList] = useState<any>([]);

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { getUserAllAiAssistantsRes } = useAppSelector(
    (state) => state.userAiAssistants
  );
  const { getUserAiAssistantRes } = useAppSelector(
    (state) => state.userAiAssistants
  );
  const { getAllPublishedAiAssistantsRes } = useAppSelector(
    (state) => state.chatGPT
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setAllTemplatesLoader(true);

          if (tempType === "my") {
            await dispatch(
              getUserAllAiAssistants({
                accessToken,
              })
            );
          }

          if (tempType === "community") {
            await dispatch(
              getAllPublishedAiAssistants({
                accessToken,
                query: selectedCategory
                  ? `?category=${selectedCategory?.value === "All" ? "" : selectedCategory?.value}`
                  : `?category=${selectedMainCategory?.value === "All" ? "" : selectedMainCategory?.value}`,
              })
            );
          }

          setAllTemplatesLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMainCategory]);

  useEffect(() => {
    if (tempType === "my") {
      setAiAssistantsCompleteList(getUserAllAiAssistantsRes);
      setCheckAiTemplatesList(getUserAllAiAssistantsRes);
    }
    if (tempType === "community") {
      setAiAssistantsCompleteList(getAllPublishedAiAssistantsRes);
      setCheckAiTemplatesList(getAllPublishedAiAssistantsRes);
    }
  }, [getUserAllAiAssistantsRes, getAllPublishedAiAssistantsRes, tempType]);

  const onSearchingList = (value: string) => {
    setSearch(value);

    if (value) {
      let filteredResults = null;

      if (tempType === "my") {
        filteredResults = getUserAllAiAssistantsRes?.filter((item: any) =>
          item?.title.toLowerCase().includes(value.toLowerCase())
        );
      }
      if (tempType === "community") {
        filteredResults = getAllPublishedAiAssistantsRes?.filter((item: any) =>
          item?.title.toLowerCase().includes(value.toLowerCase())
        );
      }

      setAiAssistantsCompleteList(filteredResults);
    } else {
      if (tempType === "my")
        setAiAssistantsCompleteList(getUserAllAiAssistantsRes);
      if (tempType === "community")
        setAiAssistantsCompleteList(getAllPublishedAiAssistantsRes);
    }
  };

  const onOpeningProjectSelection = (id: string) => {
    setSelectedAIChatId(id);
    setProjectSelectionModal(true);
  };

  const onClosingProjectSelection = () => {
    setSelectedAIChatId("");
    setProjectSelectionModal(false);
  };

  const creatingAIChat = (project_id: string, templateType: string) => {
    const fetchData = async () => {
      try {
        setSelectProjectLoader(true);
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createConversation({
              body: {},
              accessToken,
              project_id,
              conversation_configuration_id: selectedAIChatId,
              template_type: templateType,
            })
          )
            .then((res) => {
              if (res?.payload) {
                navigate(`/projects/${project_id}`);
                onClosingProjectSelection();
              }
              setSelectProjectLoader(false);
            })
            .catch(() => {
              setSelectProjectLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchData();
  };

  const onOpenFormModal = () => {
    setOpen(true);
    setIsEditMode(false);
  };

  const onOpenEditModal = (id: string) => {
    setIsEditMode(true);
    setOpen(true);
    gettingSingleAiAssistant(id);
  };

  const onCloseModal = () => {
    setOpen(false);
    setIsEditMode(false);
  };

  const updatingAIAssistant = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateUserAiAssistant({
              body,
              accessToken,
              resource_id: showTempDetail?.id,
            })
          )
            .then((res) => {
              onCloseModal();
              setFormLoader(false);
              setShowTempDetail(res?.payload?.data);
            })
            .catch(() => {
              setFormLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingAIAssistant = (body: any) => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          setFormLoader(true);

          await dispatch(createUserAiAssistant({ body, accessToken }))
            .then(() => {
              onCloseModal();
              setFormLoader(false);
            })
            .catch(() => {
              setFormLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  const onSubmitAiAssistant = async (body: any) => {
    if (isEditMode) {
      updatingAIAssistant(body);
    } else {
      creatingAIAssistant(body);
    }
  };

  const gettingSingleAiAssistant = (id: string) => {
    const fetchData = async () => {
      setAssistantByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(getUserAiAssistant({ accessToken, resource_id: id }));
        setAssistantByIdLoader(false);
      } catch (error) {
        console.error("Error getting access token:", error);
        setAssistantByIdLoader(false);
      }
    };

    fetchData();
  };

  const deletingAIAssistant = () => {
    const fetchData = async () => {
      setConfirmationLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteUserAiAssistant({ accessToken, resource_id: selectedId })
          )
            .then(() => {
              setConfirmationLoader(false);
              deletingModalClose();
              setShowTempDetail(null);
            })
            .catch(() => {
              setConfirmationLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setConfirmationLoader(false);
      }
    };

    fetchData();
  };

  const deletingModalOpen = (id: string) => {
    setDeleteConfirm(true);
    setSelectedId(id);
  };

  const deletingModalClose = () => {
    setDeleteConfirm(false);
    setSelectedId(null);
  };

  const publishingAIAssistant = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          await dispatch(
            publishUserAiAssistant({
              accessToken,
              resource_id: showTempDetail
                ? showTempDetail?.id
                : getLastIdFromUrl(window.location.pathname),
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: false,
                  };
                });

                setPublishConfirmation(false);
              }
              setPublishingLoader(false);
            })
            .catch(() => {
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  const unPublishingAIAssistant = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          await dispatch(
            unPublishUserAiAssistant({
              accessToken,
              resource_id: showTempDetail
                ? showTempDetail?.id
                : getLastIdFromUrl(window.location.pathname),
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: true,
                  };
                });

                setPublishConfirmation(false);
              }
              setPublishingLoader(false);
            })
            .catch(() => {
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  return (
    <>
      <WrapperLoader loading={allTemplatesLoader}>
        <div className="flex">
          <div
            style={{
              width: "100%",
            }}
          >
            {checkAiTemplatesList?.length > 0 ? (
              <div>
                <div className="pb-4 mt-4 border-b border-gray-200 text-right">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center justify-between w-full">
                      {showTempDetail && !isModal && (
                        <CustomButton
                          text="Back"
                          type="button"
                          onClickBtn={() => setShowTempDetail(null)}
                          outlined
                          btnStyle="border-gray-300 text-customLightBlue px-6 text-lg mr-4 hover:border-gray-600 hover:text-customDarkBlue"
                        />
                      )}

                      {!showTempDetail && (
                        <div className="w-full">
                          {selectedMainCategory.value === "All" &&
                          (checkAiTemplatesList?.length === 0 ||
                            !checkAiTemplatesList) ? (
                            ""
                          ) : (
                            <div className="flex items-center justify-between w-full px-4">
                              <div className="w-80">
                                <SearchField
                                  onSearchField={onSearchingList}
                                  searchValue={search}
                                />
                              </div>
                              {tempType !== "community" && (
                                <CustomButton
                                  text="New Assistant"
                                  type="button"
                                  onClickBtn={onOpenFormModal}
                                  beforeIcon={<PlusIcon className="h-4 mr-1" />}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      {showTempDetail && !isModal && (
                        <div className="flex">
                          <CustomButton
                            text="Delete"
                            type="button"
                            onClickBtn={() =>
                              deletingModalOpen(showTempDetail?.id)
                            }
                            beforeIcon={<TrashIcon className="h-4 mr-1" />}
                            loading={false}
                            outlined
                            btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                          />

                          <CustomButton
                            text={
                              showTempDetail?.privacy === true
                                ? "Publish"
                                : "Unpublish"
                            }
                            type="button"
                            onClickBtn={() => setPublishConfirmation(true)}
                            beforeIcon={
                              <ArrowUpOnSquareStackIcon className="h-4 mr-1" />
                            }
                            loading={publishingLoader}
                            outlined
                            btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                          />

                          <CustomButton
                            text="Publish on LinkedIn"
                            type="button"
                            onClickBtn={() => console.log("hello...")}
                            beforeIcon={
                              <ArrowDownCircleIcon className="h-4 mr-1" />
                            }
                            loading={false}
                            outlined
                            btnStyle="whitespace-nowrap text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue"
                          />

                          <CustomButton
                            text="Edit"
                            type="button"
                            onClickBtn={() =>
                              onOpenEditModal(showTempDetail?.id)
                            }
                            beforeIcon={<PencilIcon className="h-4 mr-1" />}
                            loading={false}
                            outlined
                            btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="pb-6">
                  <div>
                    {showTempDetail ? (
                      <AIChatTempDetail
                        onCancel={() => setShowTempDetail(null)}
                        onUse={
                          isModal && onUseWithModal
                            ? onUseWithModal
                            : onOpeningProjectSelection
                        }
                        isModal={isModal}
                        tempType={tempType}
                        showTempDetail={showTempDetail}
                      />
                    ) : (
                      <div className="flex">
                        {!isModal && (
                          <div>
                            {selectedMainCategory.value === "All" &&
                            (checkAiTemplatesList?.length === 0 ||
                              !checkAiTemplatesList) ? (
                              ""
                            ) : (
                              <div>
                                {tempType === "community" && (
                                  <div className="w-[270px]">
                                    <div className="bg-white p-4 pt-[10px] border-r border-gray-200 ml-2 mr-3 mt-2 h-[calc(100vh_-_80px)]">
                                      <CategoriesLeftSidebar
                                        setSelectedCategory={
                                          setSelectedMainCategory
                                        }
                                        selectedCategory={selectedMainCategory}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}{" "}
                        <div
                          className="w-full overflow-y-auto px-4 mt-4"
                          style={{
                            height: isModal
                              ? "calc(100vh - 290px)"
                              : "calc(100vh - 170px)",
                          }}
                        >
                          {aiAssistantsCompleteList?.length > 0 && (
                            <div
                              className={`grid ${isModal ? "grid-cols-3" : "grid-cols-4"} gap-2`}
                            >
                              {aiAssistantsCompleteList?.map(
                                (data: any, index: number) => {
                                  return (
                                    <TemplateBox
                                      key={index}
                                      data={data}
                                      onShow={() => {
                                        isModal
                                          ? setShowTempDetail(data)
                                          : (tempType === "my" &&
                                              navigate(
                                                `/ai-assistant/${data?.id}`
                                              )) ||
                                            (tempType === "community" &&
                                              navigate(
                                                `/community-ai-assistant/${data?.id}`
                                              ));
                                      }}
                                      onUse={
                                        isModal && onUseWithModal
                                          ? onUseWithModal
                                          : onOpeningProjectSelection
                                      }
                                    />
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="flex items-center justify-center"
                style={{ height: "calc(100vh - 72px)" }}
              >
                <CompleteEmptyPage
                  title="You don't have any Assistant"
                  subTitle="Get started by creating a new AI Assistant"
                  btnText="New Assistant"
                  onClickBtn={onOpenFormModal}
                  icon={aiSVG}
                />
              </div>
            )}
          </div>
        </div>
      </WrapperLoader>

      <CustomModal
        open={projectSelectionModal}
        onCloseModal={() => onClosingProjectSelection()}
        title={
          <div>
            <h1 className="font-semibold text-lg mb-1">Create a new Chat</h1>
            <p className="text-xs font-normal">
              Select project and start a new chat
            </p>
          </div>
        }
        size="max-w-lg"
      >
        <ProjectsSelection
          onCloseModal={() => onClosingProjectSelection()}
          loading={selectProjectLoader}
          onSubmitData={creatingAIChat}
          templateType="private"
        />
      </CustomModal>

      <CustomDrawer
        open={open}
        onCloseModal={onCloseModal}
        title={
          <div className="flex items-center">
            <span>{isEditMode ? "Update" : "Create"} AI Assistant</span>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer"
              className="text-xs text-yellow-500 hover:text-yellow-800 flex items-center font-semibold ml-3"
            >
              <span className="mr-1">Learn More</span>
              <ArrowTopRightOnSquareIcon className="h-3" />
            </a>
          </div>
        }
        description={
          isEditMode
            ? "Update Your AI Assistant."
            : "Create new AI Assistant, provide all the data during create your Assistant."
        }
      >
        <WrapperLoader loading={assistantByIdLoader}>
          <CreationForm
            onCloseModal={onCloseModal}
            editData={getUserAiAssistantRes}
            onSubmitForm={onSubmitAiAssistant}
            loader={formLoader}
            editMode={isEditMode}
          />
        </WrapperLoader>
      </CustomDrawer>

      <ConfirmationModal
        open={deleteConfirm}
        closingModal={() => deletingModalClose()}
        onDeleting={deletingAIAssistant}
        deleteLoader={confirmationLoader}
      />

      <PublishConfirmation
        open={publishConfirmation}
        closingModal={() => setPublishConfirmation(false)}
        onClickYes={
          showTempDetail?.privacy === true
            ? () => publishingAIAssistant()
            : () => unPublishingAIAssistant()
        }
        loading={publishingLoader}
        privacy={showTempDetail?.privacy}
      />
    </>
  );
};

export default AiAssistantsData;
