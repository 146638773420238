import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { classNames } from "../../utils/utilities";

interface ActionProps {
  id: number;
  name: string;
  icon?: React.ReactNode;
  onActionClick: () => void;
}

interface ItemWithDropdownProps {
  actionsData: ActionProps[];
  children: React.ReactNode;
  menuPosition?: string;
}

export default function ItemWithDropdown({
  actionsData,
  children,
  menuPosition,
}: ItemWithDropdownProps) {
  return (
    <Menu as="div">
      <Menu.Button className="w-full flex items-center rounded-full text-customLightBlue hover:text-gray-600">
        {children}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute right-0 py-1 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${menuPosition || ""}`}
        >
          {actionsData?.length > 0 &&
            actionsData.map((item, index) => {
              return (
                <div
                  className="px-1 cursor-pointer"
                  onClick={() => item.onActionClick()}
                >
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <span
                        className={classNames(
                          active
                            ? "bg-gray-100 text-customDarkBlue"
                            : "text-gray-700",
                          "group flex items-center px-4 py-3 text-xs rounded-md"
                        )}
                      >
                        {item.icon}
                        {item.name}
                      </span>
                    )}
                  </Menu.Item>
                </div>
              );
            })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
