import React, { useEffect, useState } from "react";
import {
  DocumentDuplicateIcon,
  PencilSquareIcon,
  TrashIcon,
  ChevronRightIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/20/solid";
import WrapperLoader from "src/components/wrapperLoader";
import ContentsList from "../../contentsList";
import ItemWithDropdown from "src/components/itemWithDropdown";
import CustomModal from "../../../../../components/customModal";
import ProjectAsTemplateForm from "../../../../templates/ProjectAsTemplateForm";
import { classNames } from "../../../../../utils/utilities";

interface DiagramBoxProps {
  totalLength: number;
  data: any;
  onEdit: () => void;
  onDelete: () => void;
  deleteLoader: boolean;
  selectedData: any;
  onSelectDiagram: () => void;
  selectedDiagram: any;
}

export default function DiagramBox({
  totalLength,
  data,
  onEdit,
  onDelete,
  deleteLoader,
  selectedData,
  onSelectDiagram,
  selectedDiagram,
}: DiagramBoxProps) {
  const [showContentList, setShowContentList] = useState(false);
  const [openSaveAsTemp, setOpenSaveAsTemp] = useState(false);

  useEffect(() => {
    if (selectedDiagram?.id !== data?.id) {
      setShowContentList(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDiagram]);

  const parseHtml = (stringCode: string) => {
    // const parser = new DOMParser();
    // const doc = parser.parseFromString(htmlCode, "text/html");
    // const headings: any = [];

    // const addHeading = (nodeName: string, nodeText: string) => {
    //   headings.push({ heading: nodeName, text: nodeText });
    // };

    // doc.body.childNodes.forEach((node: any) => {
    //   if (node.nodeType === Node.ELEMENT_NODE) {
    //     const nodeName = node.nodeName.toLowerCase();

    //     // Extract text content from the node
    //     const nodeText = node.textContent.trim();

    //     if (
    //       nodeName.startsWith("h") &&
    //       nodeName.length === 2 &&
    //       !isNaN(nodeName[1])
    //     ) {
    //       addHeading(nodeName, nodeText);
    //     }
    //   }
    // });

    // if (stringCode) {
    //   const lines = stringCode.split("\n");
    //   const headingsList: { heading: string; text: string }[] = [];

    //   lines.forEach((line) => {
    //     const matches = /^(#{2,4})\s/.exec(line);
    //     if (matches) {
    //       const headingType = matches[1].length; // Calculate heading type
    //       const headingText = line.replace(/^(#{2,4})\s/, ""); // Remove '#' characters
    //       headingsList.push({
    //         heading: `h${headingType - 1}`,
    //         text: headingText,
    //       });
    //     }
    //   });

    //   return headingsList;
    // } else {
    //   return []
    // }

    // const headingsList: any = [];
    // const parser = new DOMParser();
    // const doc = parser.parseFromString(stringCode, "text/html");

    // const headingTags = ["h1", "h2", "h3"];
    // headingTags.forEach((tagName) => {
    //   const headings: any = doc.getElementsByTagName(tagName);
    //   for (let i = 0; i < headings.length; i++) {
    //     const headingText = headings[i].textContent.trim();
    //     headingsList.push({
    //       heading: tagName.toUpperCase(),
    //       text: headingText,
    //     });
    //   }
    // });

    const parser = new DOMParser();
    const doc = parser.parseFromString(stringCode, "text/html");

    const headings: any = [];

    const headingElements = doc.querySelectorAll("h1, h2, h3");
    headingElements.forEach((element: any) => {
      const heading = element.tagName.toLowerCase();
      const text = element.textContent.trim();
      headings.push({ heading, text });
    });

    return headings;

    // return headingsList;
  };

  const headingsList = parseHtml(data?.content);

  const onShowContent = (e: any) => {
    e.stopPropagation();
    setShowContentList(!showContentList);
  };

  const onOpeningSaveAsTemp = () => {
    setOpenSaveAsTemp(true);
  };

  const actionsList = [
    {
      id: 1,
      name: "Edit",
      onActionClick: onEdit,
      icon: (
        <PencilSquareIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
      ),
    },
    // {
    //   id: 2,
    //   name: "Save as Template",
    //   onActionClick: onOpeningSaveAsTemp,
    //   icon: (
    //     <DocumentDuplicateIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
    //   ),
    // },
    {
      id: 3,
      name: "Delete",
      onActionClick: onDelete,
      icon: (
        <TrashIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
      ),
    },
  ];

  return (
    <li key={data?.id} className="mb-4 transition-all duration-500 ease-in-out">
      <div
        className={`rounded-md shadow-sm border ${
          selectedDiagram?.id === data?.id
            ? "border-amber-400 bg-amber-50"
            : "border-gray-200 bg-white"
        } hover:border-amber-400 hover:bg-amber-50`}
        onClick={onSelectDiagram}
      >
        <WrapperLoader loading={selectedData?.id === data?.id && deleteLoader}>
          <div className={`flex items-center`}>
            <div className="flex flex-1 items-center justify-between truncate rounded-r-md cursor-pointer">
              <div className="flex-1 truncate px-4 py-2 text-xs">
                <div className="flex items-center">
                  {headingsList?.length > 0 && (
                    <div
                      onClick={onShowContent}
                      className={`mr-3 rounded flex items-center justify-center cursor-pointer ${
                        showContentList
                          ? "text-customDarkBlue"
                          : "border-zinc-300 text-customDarkBlue"
                      }  hover:text-customDarkBlue `}
                    >
                      <ChevronRightIcon
                        className={`transition-all duration-200 ease-in-out h-5 ${
                          showContentList ? "rotate-90" : "rotate-0"
                        }`}
                      />
                    </div>
                  )}

                  <span className="font-medium text-xs text-gray-700 hover:text-customLightBlue">
                    {data?.title}
                  </span>
                </div>
              </div>
              <ItemWithDropdown actionsData={actionsList}>
                <EllipsisVerticalIcon className="h-4 w-4" aria-hidden="true" />
              </ItemWithDropdown>
              <div className="flex-shrink-0 pr-4 flex items-center">
                {/* <PencilIcon
                  className="h-4 w-4 mr-1 cursor-pointer text-customLightBlue hover:text-customDarkBlue"
                  onClick={onEdit}
                />
                {totalLength > 1 && (
                  <TrashIcon
                    onClick={onDelete}
                    className="h-4 w-4 cursor-pointer text-customLightBlue hover:text-customDarkBlue"
                  />
                )} */}
              </div>
            </div>
          </div>
        </WrapperLoader>
      </div>

      {showContentList && (
        <div
          className={`bg-zinc-50 p-4 border border-zinc-200 rounded-md mt-2 transition-all duration-500 ease-in-out ${
            !showContentList ? "opacity-0" : "opacity-100"
          }`}
        >
          <ContentsList headingsList={headingsList} classNames={classNames} />
        </div>
      )}

      <CustomModal
        open={openSaveAsTemp}
        onCloseModal={() => setOpenSaveAsTemp(false)}
        title={<h1 className="font-semibold">Save Document as Template</h1>}
        size="max-w-lg"
      >
        <ProjectAsTemplateForm
          onCloseModal={() => setOpenSaveAsTemp(false)}
          loading={false}
          onSubmitData={() => console.log("Saving...")}
          templateType="community"
          titleValue={data?.title}
        />
      </CustomModal>
    </li>
  );
}
