// kubernetes_nodes.js

export const kubernetes_nodes = [
  {
    name: "Control Plane",
    items: [
      "api",
      "c-c-m",
      "c-m",
      "k-proxy",
      "kubelet",
      "sched"
    ],
  },
  {
    name: "Infrastructure",
    items: [
      "control-plane",
      "etcd",
      "node"
    ],
  },
  {
    name: "Resources",
    items: [
      "c-role",
      "cm",
      "crb",
      "crd",
      "cronjob",
      "deploy",
      "ds",
      "ep",
      "group",
      "hpa",
      "ingress",
      "job",
      "limits",
      "name-space",
      "netpol",
      "pod",
      "psp",
      "pv",
      "pvc",
      "quota",
      "rb",
      "role",
      "rs",
      "sa",
      "sc",
      "secret",
      "sts",
      "svc",
      "user",
      "vol"
    ],
  },
];