import React from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import WrapperLoader from "src/components/wrapperLoader";

interface ArgumentBoxProps {
  id: string;
  classNames: any;
  name: string;
  value: number;
  onEdit: () => void;
  onDelete: () => void;
  classes?: string;
  deleteLoader: boolean;
  selectedData: any;
  type?: string;
}

export default function ArgumentBox({
  id,
  classNames,
  name,
  value,
  onEdit,
  onDelete,
  classes,
  deleteLoader,
  selectedData,
  type
}: ArgumentBoxProps) {
  return (
    <li
      key={name}
      className="rounded-md shadow-sm mb-2 overflow-hidden border border-gray-200 bg-white"
    >
      <WrapperLoader loading={selectedData?.id === id && deleteLoader}>
        <div
          className={`flex items-center ${
            type === "swot" ? "pl-1 h-9" : "pl-4 h-12"
          }`}
        >
          <div
            className={classNames(
              `flex w-7 h-7 flex-shrink-0 items-center justify-center rounded-full text-xs font-medium ${classes}`
            )}
          >
            {value}
          </div>
          <div className="flex flex-1 items-center justify-between truncate rounded-r-md">
            <div className="flex-1 truncate px-4 py-2 text-xs">
              <span className="font-medium text-gray-900 hover:text-gray-600">
                {name}
              </span>
            </div>
            <div className="flex-shrink-0 pr-4 flex items-center">
              <PencilIcon
                className="h-4 w-4 mr-1 cursor-pointer text-customLightBlue hover:text-customDarkBlue"
                onClick={onEdit}
              />
              <TrashIcon
                onClick={onDelete}
                className="h-4 w-4 cursor-pointer text-customLightBlue hover:text-customDarkBlue"
              />
            </div>
          </div>
        </div>
      </WrapperLoader>
    </li>
  );
}
