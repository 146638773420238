import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import WrapperLoader from "src/components/wrapperLoader";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { getProsConsById } from "../../../../redux/prosCons/prosConsApi";
import { getQueryParams, getLastIdFromUrl } from "../../../../utils/utilities";
import DetailsOfAllDecisions from '../details';

export default function ProsConsDetails() {
  const [prosConsByIdLoader, setProsConsByIdLoader] = useState(false);
	const [detailsData, setDetailsData] = useState<any>(null);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { selectedProsCons, prosConsByIdRes } = useAppSelector((state) => state.prosCons);

  useEffect(() => {
    const fetchData = async () => {
      const queryStringObj = getQueryParams(window.location.href);
      setProsConsByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(
            getProsConsById({
              accessToken,
              id: queryStringObj?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        setProsConsByIdLoader(false);
      } catch (error) {
        console.error("Error getting access token:", error);
        setProsConsByIdLoader(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProsCons]);

	useEffect(() => {
		if(prosConsByIdRes) {
			const showData = {
        title: prosConsByIdRes?.title || "",
        description: prosConsByIdRes?.pnc_description || "",
        status: prosConsByIdRes?.pnc_status || "",
        category: prosConsByIdRes?.category || "",
        assumptions: prosConsByIdRes?.assumptions || "",
        implications: prosConsByIdRes?.implications || "",
        final_decision: prosConsByIdRes?.final_decision || "",
      };
			setDetailsData(showData);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [prosConsByIdRes]);

  return (
    <WrapperLoader loading={prosConsByIdLoader}>
      <DetailsOfAllDecisions detailsData={detailsData} />
    </WrapperLoader>
  );
}
