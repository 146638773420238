import React, { useEffect, useState } from "react";
import CustomButton from "src/components/customButton";
import InputField from "../../../../components/formFields/inputField";

interface ArgumentProps {
  onCloseModal: () => void;
  loading: boolean;
  onSubmitData: Function;
  editData: any;
  side: string;
}

export default function Argument({
  onCloseModal,
  loading,
  onSubmitData,
  editData,
  side,
}: ArgumentProps) {
  const [argument, setArgument] = useState("");
  const [value, setValue] = useState<number>(1);
  const [isArgument, setIsArgument] = useState(false);

  useEffect(() => {
    if (editData) {
      if (editData?.argument) setArgument(editData?.argument);
      if (editData?.argument_weight) setValue(editData?.argument_weight);
    }
  }, [editData]);

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    const payload = {
      argument: argument,
      argument_weight: value,
      side
    };

    if (argument) {
      onSubmitData(payload);
    } else {
      setIsArgument(true);
    }
  };

  const onChangeArgument = (value: string) => {
    setArgument(value);
    setIsArgument(false);
  };

  const onChangeValue = (value: string) => {
    const data = parseInt(value);
    setValue(data);
  };

  return (
    <form className="flex h-full flex-col" onSubmit={onSubmitForm}>
      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
        <div>
          <label
            htmlFor="argument-name"
            className="block text-xs font-medium leading-6 text-customDarkBlue"
          >
            Argument
          </label>
        </div>
        <div className="sm:col-span-2">
          <InputField
            type="text"
            id="agrument"
            error={isArgument}
            name="agrument"
            value={argument}
            onChange={(e) => onChangeArgument(e.target.value)}
          />
        </div>
      </div>

      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mt-4 items-center">
        <div>
          <label
            htmlFor="argument-value"
            className="block text-xs font-medium leading-6 text-customDarkBlue"
          >
            Weight
          </label>
        </div>
        <div className="sm:col-span-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-2">
              <input
                type="radio"
                name="valueOptions"
                value="1"
                checked={`${value}` === "1"}
                id="normal"
                onChange={(e) => onChangeValue(e.target.value)}
                className="h-4 w-4 border-gray-300 text-amber-500 focus:ring-blue-600"
              />
              <label htmlFor="normal" className="block text-xs text-customDarkBlue">
                1-Normal
              </label>
            </div>
            <div className="flex items-center gap-x-2">
              <input
                id="important"
                name="valueOptions"
                type="radio"
                value="2"
                checked={`${value}` === "2"}
                onChange={(e) => onChangeValue(e.target.value)}
                className="h-4 w-4 border-gray-300 text-amber-500 focus:ring-blue-600"
              />
              <label
                htmlFor="important"
                className="block text-xs text-customDarkBlue"
              >
                2-Important
              </label>
            </div>
            <div className="flex items-center gap-x-2">
              <input
                id="critical"
                name="valueOptions"
                type="radio"
                value="3"
                checked={`${value}` === "3"}
                onChange={(e) => onChangeValue(e.target.value)}
                className="h-4 w-4 border-gray-300 text-amber-500 focus:ring-blue-600"
              />
              <label htmlFor="critical" className="block text-xs text-customDarkBlue">
                3-Critical
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* Action buttons */}
      <div className="w-full text-right mt-5">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-xs font-semibold text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-2"
          onClick={onCloseModal}
        >
          Cancel
        </button>
        <CustomButton
          text="Save"
          type="submit"
          onClickBtn={() => console.log()}
          loading={loading}
        />
      </div>
    </form>
  );
}
