import React from "react";
import {
  PlusIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import {useAppSelector} from '../../hooks';
import CustomButton from "../../components/customButton";

import chatGPT from "../../assets/images/chatgpt_logo.jpg";

interface TemplateBoxProps {
  data: any;
  onShow: Function;
  onUse: Function;
}

export default function TemplateBox({ data, onShow, onUse }: TemplateBoxProps) {
    const { profileData } = useAppSelector<any>((state: any) => state.profile);

  return (
    <div
      onClick={() => onShow()}
      className="group overflow-hidden cursor-pointer relative w-full bg-white shadow-sm border transition-all border-zinc-100 bg-zinc-0 rounded-xl hover:shadow-xl top-0 hover:top-1"
    >
      <div className="px-6 py-6">
        <div className="flex items-center mb-4 text-xs text-zinc-500">
          <div className="mr-2">
            <img src={chatGPT} alt="Chat GPT" className="h-8 rounded-md" />
          </div>
          <span>{data?.ai_type}</span>
        </div>

        <span className="inline-block w-full text-md text-customDarkBlue text-ellipsis flex-shrink-0 overflow-x-hidden whitespace-nowrap line-clamp-1">
          {data?.title}
        </span>

        <div className="mb-3 text-xs text-zinc-500 line-clamp-2">
          {data?.description}
        </div>

        {/* <div className="flex items-center justify-between mb-2 text-xs">
          <div>Frequency</div>
          <div>{data?.configuration?.frequency_penalty}</div>
        </div> */}

        <div className="flex items-center justify-between text-xs mb-3">
          <div className="text-zinc-600">Category</div>
          <div>
            <span
              className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset justify-center bg-blue-50 text-blue-700 ring-blue-600/20`}
            >
              {data?.category}
            </span>
          </div>
        </div>

        <div className="flex items-center justify-between mb-2 text-xs">
          <div className="text-zinc-600">Published By</div>
          {data?.published_by === "userTemplate" && (
            <div className="line-clamp-1" data-tooltip-id="tooltip-content">
              {`${profileData?.first_name || profileData?.last_name ? `${profileData?.first_name} ${profileData?.last_name}` : profileData?.email}`}
            </div>
          )}

          <Tooltip id="tooltip-content" place="top">
            {`${profileData?.first_name || profileData?.last_name ? `${profileData?.first_name} ${profileData?.last_name}` : profileData?.email}`}
          </Tooltip>

          {data?.published_by === "solutionPilotTemplate" && (
            <div className="line-clamp-1">Solution Pilot</div>
          )}

          {data?.published_by === "communityTemplate" && (
            <div className="line-clamp-1">Community</div>
          )}
        </div>
      </div>

      <div className="w-full px-6 absolute bottom-[-100px] group-hover:bottom-[20px] transition-all">
        <CustomButton
          text="Use"
          type="button"
          onClickBtn={(e: any) => {
            e.stopPropagation();
            onUse(data);
          }}
          beforeIcon={
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          }
          btnStyle="w-full flex items-center justify-center"
        />
      </div>

      {/* <div className="flex border-t border-zinc-100 text-xs absolute w-full bottom-0">
        <div
          className="w-[50%] flex items-center justify-center border-r border-zinc-100 p-3 rounded-bl-xl cursor-pointer hover:bg-blue-500 hover:text-white"
          onClick={() => onShow()}
        >
          Show
        </div>
        <div
          className="w-[50%] flex items-center justify-center p-3 rounded-br-xl cursor-pointer hover:bg-blue-500 hover:text-white"
          onClick={(e: any) => {
            e.stopPropagation();
            onUse(data?.id);
          }}
        >
          Use
        </div>
      </div> */}
    </div>
  );
}




