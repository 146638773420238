import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {
  ChevronLeftIcon,
  ArrowDownCircleIcon,
} from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../hooks";
import WrapperLoader from "../../components/wrapperLoader";
import {
  getPrivateTemplateDocument,
  getPublicTemplateDocument,
} from "../../redux/templates/templatesApi";
import CustomButton from "../../components/customButton";
import CustomModal from "../../components/customModal";
import ProjectsSelection from "../templates/ProjectsSelectionForm";
import { createConversation } from "../../redux/chatGPT/chatGPTApi";
import {
  getPathnameInSplitArray,
  getFirstPathFromUrl,
} from "../../utils/utilities";
import HtmlRenderer from "../../components/HTMLRenderer";

const TemplatesDetails = () => {
  const [selectProjectLoader, setSelectProjectLoader] = useState(false);
  const [projectSelectionModal, setProjectSelectionModal] = useState(false);
  const [selectedAIChatId, setSelectedAIChatId] = useState("");
  const [documentDetailLoader, setDocumentDetailLoader] = useState(false);
  const [documentData, setDocumentData] = useState('');

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { getPrivateTemplateDocumentRes, getPublicTemplateDocumentRes } =
    useAppSelector((state) => state.templates);

  useEffect(() => {
    const fetchData = async () => {
      setDocumentDetailLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        const urlSplit = getPathnameInSplitArray(window.location.pathname);
        const template_id = urlSplit ? urlSplit[2] : "";
        const document_id = urlSplit ? urlSplit[4] : "";

        if (accessToken) {
          const firstPath = getFirstPathFromUrl(window.location.pathname);

          if (firstPath === "template") {
            await dispatch(
              getPrivateTemplateDocument({
                accessToken,
                template_id: template_id,
                template_document_id: document_id,
              })
            );
          }

          if (firstPath === "community-template") {
            await dispatch(
              getPublicTemplateDocument({
                accessToken,
                template_id: template_id,
                template_document_id: document_id,
              })
            );
          }

          setDocumentDetailLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setDocumentDetailLoader(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const firstPath = getFirstPathFromUrl(window.location.pathname);

    if (firstPath === "template") {
      setDocumentData(getPrivateTemplateDocumentRes?.content);
    }
    if (firstPath === "community-template") {
      setDocumentData(getPublicTemplateDocumentRes?.content);
    }
  }, [getPrivateTemplateDocumentRes, getPublicTemplateDocumentRes]);

  const onClosingProjectSelection = () => {
    setSelectedAIChatId("");
    setProjectSelectionModal(false);
  };

  const creatingAIChat = (project_id: string, templateType: string) => {
    const fetchData = async () => {
      try {
        setSelectProjectLoader(true);
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createConversation({
              body: {},
              accessToken,
              project_id,
              conversation_configuration_id: selectedAIChatId,
              template_type: templateType,
            })
          )
            .then((res) => {
              if (res?.payload) {
                navigate(`/projects/${project_id}`);
                onClosingProjectSelection();
              }
              setSelectProjectLoader(false);
            })
            .catch(() => {
              setSelectProjectLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchData();
  };

  return (
    <WrapperLoader loading={documentDetailLoader}>
      <div className="px-4">
        <div className="mb-4 pb-4 mt-4 border-b border-gray-200 text-right">
          <div className="flex items-center justify-between w-full">
            <CustomButton
              text="Back"
              type="button"
              onClickBtn={() => navigate(-1)}
              beforeIcon={<ChevronLeftIcon className="h-4 mr-1" />}
              outlined
              btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue border-none"
            />

            <div className="flex">
              <CustomButton
                text="Save Changes"
                type="button"
                onClickBtn={() => setProjectSelectionModal(true)}
                beforeIcon={<ArrowDownCircleIcon className="h-4 mr-1" />}
                loading={false}
                outlined
                btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
              />

              <CustomButton
                text="Use"
                type="button"
                onClickBtn={() => setProjectSelectionModal(true)}
                beforeIcon={<ArrowDownCircleIcon className="h-4 mr-1" />}
                loading={false}
                outlined
                btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
              />
            </div>
          </div>
        </div>

        <div className="bg-white border border-zinc-100 mt-2 px-7 py-6 rounded-xl shadow-md">
          <HtmlRenderer htmlString={documentData || ""} />
        </div>
      </div>

      <CustomModal
        open={projectSelectionModal}
        onCloseModal={() => onClosingProjectSelection()}
        title={
          <div>
            <h1 className="font-semibold text-lg mb-1">Select Project</h1>
            <p className="text-xs font-normal">
              Select an existing project to add this document template
            </p>
          </div>
        }
        size="max-w-lg"
      >
        <ProjectsSelection
          onCloseModal={() => onClosingProjectSelection()}
          loading={selectProjectLoader}
          onSubmitData={creatingAIChat}
          templateType="solutionPilot"
        />
      </CustomModal>
    </WrapperLoader>
  );
};

export default TemplatesDetails;
