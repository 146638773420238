import { memo, useRef, useEffect, useState } from "react";
import {
  Handle,
  Position,
  NodeToolbar,
  // NodeProps,
  useStore,
  useReactFlow,
  NodeResizer,
} from "@xyflow/react";
import debounce from "lodash/debounce";
import {
  TrashIcon,
  ArrowTopRightOnSquareIcon,
  PencilIcon
} from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import useDetachNodes from "./useDetachNodes";
import { getRelativeNodesBounds } from "./utils";
import { useAppDispatch } from '../../../hooks';
import {setOpenNodeSetting, setSelectedNodeSetting} from '../../../redux/diagrams/diagramsSlice';

const lineStyle = { borderColor: "white" };

function SimpleNode({ id, data }: any) {
  const [isEditText, setIsEditText] = useState(false);
  const [nodeTitle, setNodeTitle] = useState("");

  const hasParent = useStore((store) => !!store.nodeLookup.get(id)?.parentId);
  const { deleteElements, getNodes, setNodes } = useReactFlow();
  const detachNodes = useDetachNodes();

  const dispatch = useAppDispatch();

  const onDelete = () => deleteElements({ nodes: [{ id }] });
  const onDetach = () => detachNodes([id]);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const nodeRef = useRef<any>(null);

  const { minWidth, minHeight, hasChildNodes } = useStore((store) => {
    const childNodes = Array.from(store.nodeLookup.values()).filter(
      (n) => n.parentId === id
    );
    const rect = getRelativeNodesBounds(childNodes);

    return {
      minWidth: rect.x + rect.width,
      minHeight: rect.y + rect.height,
      hasChildNodes: childNodes.length > 0,
    };
  }, isEqual);

  const onOpenNodeEditSetting = () => {
    dispatch(
      setSelectedNodeSetting({
        id,
        data,
      })
    );
    dispatch(setOpenNodeSetting(true));
  };

  const handleResize = debounce(() => {
    // Any resize logic that might cause the ResizeObserver warning can be placed here.
  }, 100);

  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) return;

    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
    }

    resizeObserverRef.current = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserverRef.current.observe(element);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onEditNodeText = (id: string) => {
    // const getId = getNode(id);
    setNodeTitle(data?.label);
    setIsEditText(!isEditText);
  };

  // Handle Enter key press
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onUpdateNodes();
      setIsEditText(false); // Exit edit mode after pressing Enter
    }
  };

  const onUpdateNodes = () => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? { ...node, data: { ...node.data, label: nodeTitle || data?.label} }
          : node
      )
    );
  }

  // Handle clicks outside the node
  // const handleOutsideClick = (event: MouseEvent) => {
  //   if (nodeRef.current && !nodeRef.current.contains(event.target as Node)) {
  //     setIsEditText(false); // Exit edit mode
  //   }
  // };

  // useEffect(() => {
  //   if (isEditText) {
  //     document.addEventListener("mousedown", handleOutsideClick);
  //   } else {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, [isEditText]);

  const onChangeNodeTitle = (e: any) => {
    const value = e.target.value;
    setNodeTitle(value);
  };

  const iconStyle = "h-4 m-1 cursor-pointer text-zinc-800";

  return (
    <div className="relative px-4 py-2 shadow-md rounded-md bg-white border-2 border-stone-400 h-[100%]">
      <div className="text-center">
        <img src={data?.icon_url} alt="icon" className="h-14 mx-auto mb-3" />
        <div className="text-xs font-semibold cursor-text" ref={nodeRef}>
          {isEditText ? (
            <input
              type="text"
              autoFocus
              value={nodeTitle}
              onChange={onChangeNodeTitle}
              onKeyDown={handleKeyDown}
              className="p-0 pl-1 border-0 bg-white shadow-sm text-xs font-semibold"
            />
          ) : (
            <span onDoubleClick={() => onEditNodeText(id)}>{data?.label}</span>
          )}
        </div>
      </div>

      <Handle type="target" position={Position.Top} id="top-target" />
      <Handle type="target" position={Position.Bottom} id="bottom-target" />
      <Handle type="target" position={Position.Left} id="left-target" />
      <Handle type="target" position={Position.Right} id="right-target" />

      <Handle type="source" position={Position.Top} id="top-source" />
      <Handle type="source" position={Position.Bottom} id="bottom-source" />
      <Handle type="source" position={Position.Left} id="left-source" />
      <Handle type="source" position={Position.Right} id="right-source" />

      <NodeResizer
        lineStyle={lineStyle}
        minHeight={minHeight}
        minWidth={minWidth}
      />

      <NodeToolbar
        style={{
          borderRadius: 300,
          boxShadow: "0 0 15px 5px rgba(0, 0, 0, 0.1)",
        }}
        className="nodrag absolute top-0 left-0 bg-white px-2 flex items-center justify-center"
      >
        <TrashIcon
          data-tooltip-id="delete-node-tooltip"
          onClick={onDelete}
          className={`${iconStyle} hover:text-red-600`}
        />

        <PencilIcon
          data-tooltip-id="edit-node-tooltip"
          onClick={onOpenNodeEditSetting}
          className={`${iconStyle} hover:text-blue-600`}
        />

        {hasParent && (
          <ArrowTopRightOnSquareIcon
            data-tooltip-id="detach-node-tooltip"
            onClick={onDetach}
            className={`${iconStyle} hover:text-blue-600`}
          />
        )}
      </NodeToolbar>

      <Tooltip className="z-[50]" id="delete-node-tooltip" place="top">
        Delete
      </Tooltip>

      <Tooltip className="z-[50]" id="edit-node-tooltip" place="top">
        Edit Setting
      </Tooltip>

      <Tooltip className="z-[50]" id="detach-node-tooltip" place="top">
        Detach
      </Tooltip>
    </div>
  );
}

type IsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
  hasChildNodes: boolean;
};

function isEqual(prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  );
}

export default memo(SimpleNode);