import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useReactFlow } from "@xyflow/react";
import { useNavigate } from "react-router-dom";
import RoundButton from "../../../../components/roundButton";
import CustomModal from "../../../../components/customModal";
import DiagramForm from "./diagramForm";
import ConfirmationModal from "../../../../components/confirmationModal";
import DiagramBox from "./diagramBox";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getDiagramsList,
  createDiagram,
  updateDiagram,
  deleteDiagram,
} from "../../../../redux/diagrams/diagramsApi";
import { setSelectedDiagram } from "../../../../redux/diagrams/diagramsSlice";
import WrapperLoader from "../../../../components/wrapperLoader";
import { getLastIdFromUrl, getQueryParams } from "../../../../utils/utilities";

interface DiagramsProps {
  isTextEditorDirty: boolean;
  updatingProjectDocument: Function;
}

// interface Item {
//   content: string;
//   created_at: string;
//   document_order: number;
//   id: string;
//   project_id: string;
//   title: string;
//   updated_at: string;
//   user_id: string;
// }

const Diagrams = ({
  isTextEditorDirty,
  updatingProjectDocument,
}: DiagramsProps) => {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [allDiagramsLoading, setAllDiagramsLoading] = useState(false);
  // const [openDiagramsTemplatesModal, setOpenDiagramsTemplatesModal] =
  //   useState(false);

  // const [draggedItem, setDraggedItem] = useState<Item | null>(null);
  // const [diagramsitems, setDiagramsItems] = useState<Item[]>([]);
  // const [targetItemIndex, setTargetItemIndex] = useState<any>(null);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { getDiagramsListRes, selectedDiagram } = useAppSelector(
    (state) => state.diagrams
  );
  const navigate = useNavigate();

  const { getEdges, getNodes } = useReactFlow();

  // const handleDragStart = (e: React.DragEvent<HTMLDivElement>, item: Item) => {
  //   setDraggedItem(item);
  // };

  // const handleDragEnter = (
  //   e: React.DragEvent<HTMLDivElement>,
  //   targetItem: Item
  // ) => {
  //   const newList = [...diagramsitems];
  //   const draggedIndex = diagramsitems.findIndex((i) => i === draggedItem);
  //   const targetIndex = diagramsitems.findIndex((i) => i === targetItem);

  //   setTargetItemIndex(targetIndex);

  //   // Swap the diagramsitems
  //   if (draggedIndex !== -1 && targetIndex !== -1) {
  //     newList[draggedIndex] = targetItem;
  //     newList[targetIndex] = draggedItem as Item;
  //   }

  //   setDiagramsItems(newList);
  // };

  // const handleDragEnd = () => {
  //   if (draggedItem) {
  //     const payloadBody = {
  //       title: draggedItem?.title,
  //       document_order: targetItemIndex + 1,
  //       project_id: draggedItem?.project_id,
  //       id: draggedItem?.id,
  //     };

  //     updatingDocument(payloadBody);
  //   }
  // };

  // useEffect(() => {
  //   const documentsList = [...(getProjectAllDocumentsRes || [])];
  //   documentsList.sort((a: any, b: any) => a.document_order - b.document_order);

  //   setDiagramsItems(documentsList);
  // }, [getProjectAllDocumentsRes]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          setAllDiagramsLoading(true);
          await dispatch(
            getDiagramsList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setAllDiagramsLoading(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryStringObj = getQueryParams(window.location.href);

    if (queryStringObj?.active === "diagrams") {
      navigate(
        `${window.location.pathname}?active=diagrams&id=${selectedDiagram?.id || queryStringObj?.id}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDiagram]);

  useEffect(() => {
    const queryStringObj = getQueryParams(window.location.href);

    if (getDiagramsListRes?.length > 0 && queryStringObj?.active === "diagrams") {
      const findDiagram = getDiagramsListRes.find(
        (data: any) => data.id === queryStringObj.id
      );

      dispatch(setSelectedDiagram(findDiagram || getDiagramsListRes[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDiagramsListRes]);

  const onCloseModal = () => {
    setOpenFormModal(false);
    setSelectedData(null);
  };

  const onOpenModal = () => {
    setOpenFormModal(true);
  };

  const onOpenEditModal = (data: any) => {
    setSelectedData(data);
    setOpenFormModal(true);
  };

  const updatingDiagram = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            updateDiagram({
              body,
              accessToken,
              project_id: selectedData?.project_id,
              diagram_id: selectedData?.id,
            })
          );

          onCloseModal();
          setFormLoader(false);
          // setDraggedItem(null);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };
    fetchData();
  };

  const creatingDiagram = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createDiagram({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          onCloseModal();
          setFormLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };
    fetchData();
  };

  const deletingDiagram = () => {
    const fetchData = async () => {
      setDeleteLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteDiagram({
              accessToken,
              project_id: selectedData?.project_id,
              diagram_id: selectedData?.id,
            })
          );

          setDeleteLoader(false);
          closeConfirmationModal();
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setDeleteLoader(false);
        closeConfirmationModal();

        // if (getProjectAllDocumentsRes?.length > 0) {
        //   dispatch(setSelectedDiagram(getProjectAllDocumentsRes[0]));
        // }
      }
    };
    fetchData();
  };

  const onSubmitForm = async (body: any) => {
    if (selectedData) {
      const updateBody = {
        title: body?.title,
        diagram_status: "Approved",
        design: JSON.stringify({
          nodes: getNodes(),
          edges: getEdges(),
        }),
      };
      updatingDiagram(updateBody);
    } else {
      const createBody = {
        title: body?.title,
        diagram_type: "Block Diagram",
        diagram_status: "Draft",
        category: "Infrastructure",
        flow: "",
      };

      creatingDiagram(createBody);
    }
  };

  const deleteConfirmationModal = (data: any) => {
    setSelectedData(data);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedData(null);
  };

  const onSelectedDiagram = async (data: any) => {
    // if (isTextEditorDirty) {
    //   await updatingProjectDocument();
    // }

    dispatch(setSelectedDiagram(data));
  };

  return (
    <>
      <WrapperLoader loading={allDiagramsLoading}>
        <div className="p-0">
          <div className="mb-3">
            <h3 className=" text-md mb-2 pb-2 border-b border-zinc-100">
              Diagrams
            </h3>
            <div
              onClick={() => onOpenModal()}
              className="w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-amber-400"
            >
              <RoundButton onClickBtn={() => console.log("Clicking...")} />
            </div>
          </div>

          <div
            className="overflow-y-auto transition-all"
            style={{ height: "calc(100vh - 169px)" }}
          >
            <ul className="drag-drop-container mt-3 grid grid-cols-1">
              {getDiagramsListRes?.length > 0 &&
                getDiagramsListRes.map((item: any, index: number) => (
                  <div
                    key={item.id}
                  >
                    <DiagramBox
                      totalLength={getDiagramsListRes?.length}
                      key={index}
                      data={item}
                      onEdit={() => onOpenEditModal(item)}
                      onDelete={() => deleteConfirmationModal(item)}
                      deleteLoader={false}
                      selectedData={selectedData}
                      onSelectDiagram={() => onSelectedDiagram(item)}
                      selectedDiagram={selectedDiagram}
                    />
                  </div>
                ))}
            </ul>

            {/* <div className="mb-4 w-full block">
              <h3>Nodes</h3>
              <Editor
                height="350px"
                theme="vs-dark"
                defaultLanguage="javascript"
                onChange={handleNodesChange}
                value={`${JSON.stringify(diagramNodes)}`}
                options={{
                  autoIndent: "advanced",
                  formatOnPaste: true,
                  formatOnType: true,
                }}
              />
            </div>

            <div className="w-full block">
              <h3>Edges</h3>
              <Editor
                height="350px"
                theme="vs-dark"
                defaultLanguage="javascript"
                value={`${JSON.stringify(diagramEdges)}`}
                onChange={handleEdgesChange}
                options={{
                  autoIndent: "advanced",
                  formatOnPaste: true,
                  formatOnType: true,
                }}
              />
            </div> */}
          </div>
        </div>
      </WrapperLoader>

      <CustomModal
        open={openFormModal}
        onCloseModal={() => onCloseModal()}
        title={`${selectedData ? "Update" : "Add"} Diagram`}
        size="max-w-lg"
      >
        <DiagramForm
          onCloseModal={() => onCloseModal()}
          loading={formLoader}
          onSubmitData={onSubmitForm}
          editData={selectedData}
        />
      </CustomModal>

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingDiagram}
        deleteLoader={deleteLoader}
      />

      {/* {openDiagramsTemplatesModal && (
        <TemplatesProjectsDocumentsModal
          onCloseModal={() => setOpenDiagramsTemplatesModal(false)}
          openModal={openDiagramsTemplatesModal}
          onUseTemplate={() => console.log("Creating Document")}
        />
      )} */}
    </>
  );
};

export default Diagrams;
