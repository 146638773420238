import React from "react";
import { BookmarkIcon } from "@heroicons/react/24/outline";
import { getIconObjectByName } from "../../utils/documentComponentsIcons";

interface DocumentComponentBoxProps {
  data: any;
}

export default function DocumentComponentBox({
  data,
}: DocumentComponentBoxProps) {

  const iconObject = getIconObjectByName(data.icon);
  return (
    <div className="p-4">
      <div className="flex items-center justify-between mb-3">
        <div>
          <img
            src={`${iconObject?.icon || ""}`}
            className="h-8 mr-1"
            alt={data?.title}
          />
        </div>
        <BookmarkIcon
          className={`h-6 mr-1 transition-all ${data?.is_favorite ? " fill-amber-400 stroke-amber-400" : " stroke-zinc-400"} hover:scale-125`}
        />
      </div>

      <div className="flex items-center mb-3">
        <span className="text-xs text-customDarkBlue line-clamp-1">
          {data?.title}
        </span>
      </div>

      <div className="text-xs text-zinc-400 line-clamp-1 mb-3">
        {data?.short_description}
      </div>

      <div>
        <span
          className={`inline-flex items-center rounded-md px-2 py-1 text-[9px] font-medium ring-1 ring-inset justify-center bg-blue-50 text-blue-700 ring-blue-600/20`}
        >
          {data?.category}
        </span>
      </div>
    </div>
  );
}
