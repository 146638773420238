import Home from '../../pages/home';
import TBar from '../../pages/T-bar';
import TBarDetails from "../../pages/T-bar-Details";
import DecisionMatrix from '../../pages/decisionMatrix';
import DecisionMatrixDetails from '../../pages/decisionMatrixDetails';
import SWOT from '../../pages/swot';
import SWOTDetails from '../../pages/swotDetails';
import ProCons from '../../pages/prosCons';
import ProConsDetails from '../../pages/prosConsDetails';
import Profile from '../../pages/profile';
import Projects from '../../pages/projects';
import ProjectsDetails from '../../pages/projectsDetails';
import AIAssitants from '../../pages/aiAssistants';
import CommunityPage from '../../pages/communityPage';
import LoginPage from '../../pages/loginPage';
import SignUpPage from "../../pages/signupPage";
import Templates from '../../pages/templates';
import TemplatesDetails from "../../pages/templatesDetails";
import DocumentDetails from "../../pages/documentDetails";
import FlowChart from '../../pages/flowchart/flow/App';
import AiAssistantsDetails from '../../pages/aiAssistantsDetials';
import DocumentComponents from '../../pages/documentComponents';
import CommunityTemplates from '../../pages/communityPage/communityTemplates';
import CommunityAiPrompts from "../../pages/communityPage/communityAiPrompts";
import Playground from '../../pages/playground';

const routes: any = [
  {
    key: "router-login",
    component: LoginPage,
    path: "/login",
    exact: false,
    index: true,
  },
  {
    key: "router-signup",
    component: SignUpPage,
    path: "/signup",
    exact: false,
    index: true,
  },
  {
    key: "router-home",
    component: Home,
    path: "/home",
    exact: true,
    index: true,
  },
  {
    key: "router-community",
    component: CommunityPage,
    path: "/community",
    exact: false,
    index: true,
  },
  {
    key: "router-t-bar",
    component: TBar,
    path: "/t-bar",
    exact: false,
    index: true,
  },
  {
    key: "router-t-bar-detail",
    component: TBarDetails,
    path: "/t-bar/:id",
    exact: false,
    index: true,
  },
  {
    key: "router-pro-con",
    component: ProCons,
    path: "/pro-con",
    exact: false,
    index: true,
  },
  {
    key: "router-pro-con-details",
    component: ProConsDetails,
    path: "/pro-con/:id",
    exact: false,
    index: true,
  },
  {
    key: "router-decisionMatrix",
    component: DecisionMatrix,
    path: "/decision-matrix",
    exact: false,
    index: true,
  },
  {
    key: "router-decisionMatrix-details",
    component: DecisionMatrixDetails,
    path: "/decision-matrix/:id",
    exact: false,
    index: true,
  },
  {
    key: "router-SWOTAnalysis",
    component: SWOT,
    path: "/swot-analysis",
    exact: false,
    index: true,
  },
  {
    key: "router-SWOTAnalysis-details",
    component: SWOTDetails,
    path: "/swot-analysis/:id",
    exact: false,
    index: true,
  },
  {
    key: "router-profile",
    component: Profile,
    path: "/profile",
    exact: false,
    index: true,
  },

  {
    key: "router-projects",
    component: Projects,
    path: "/projects",
    exact: false,
    index: true,
  },
  {
    key: "router-projects-details",
    component: ProjectsDetails,
    path: "/projects/:id",
    exact: false,
    index: true,
  },
  {
    key: "router-community-ai-assistant",
    component: CommunityAiPrompts,
    path: "/community-ai-assistants",
    exact: false,
    index: true,
  },
  {
    key: "router-ai-assistants",
    component: AIAssitants,
    path: "/ai-assistants",
    exact: false,
    index: true,
  },
  {
    key: "router-ai-assistant-detail",
    component: AiAssistantsDetails,
    path: "/ai-assistant/:id",
    exact: false,
    index: true,
  },
  {
    key: "router-community-ai-assistant-detail",
    component: AiAssistantsDetails,
    path: "/community-ai-assistant/:id",
    exact: false,
    index: true,
  },
  {
    key: "router-templates",
    component: Templates,
    path: "/templates",
    exact: false,
    index: true,
  },
  {
    key: "router-community-templates",
    component: CommunityTemplates,
    path: "/community-templates",
    exact: false,
    index: true,
  },
  {
    key: "router-templates-community-details",
    component: TemplatesDetails,
    path: "/community-template/:id",
    exact: false,
    index: true,
  },
  {
    key: "router-template-document-community-details",
    component: DocumentDetails,
    path: "/community-template/:id/document/:id",
    exact: false,
    index: true,
  },
  {
    key: "router-templates-details",
    component: TemplatesDetails,
    path: "/template/:id",
    exact: false,
    index: true,
  },
  {
    key: "router-template-document-details",
    component: DocumentDetails,
    path: "/template/:id/document/:id",
    exact: false,
    index: true,
  },
  {
    key: "router-flow-chart",
    component: FlowChart,
    path: "/flow-chart",
    exact: false,
    index: true,
  },
  {
    key: "router-document-components",
    component: DocumentComponents,
    path: "/document-components",
    exact: false,
    index: true,
  },
  {
    key: "router-playground",
    component: Playground,
    path: "/playground",
    exact: false,
    index: true,
  },
];

export default routes;
