import { createSlice } from "@reduxjs/toolkit";
import {
  getDocumentComponents,
  getDocumentComponentDetail,
  getDocumentFavoriteComponents,
  pinDocumentComponent,
  unpinDocumentComponent,
} from "./documentComponentsApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  getDocumentComponentsRes: any;
  getDocumentComponentDetailRes: any;
  getDocumentFavoriteComponentsRes: any;
  pinDocumentComponentRes: any;
  unpinDocumentComponentRes: any;
}
const initialState: initialStateType = {
  error: null,
  message: null,
  getDocumentComponentsRes: null,
  getDocumentComponentDetailRes: null,
  getDocumentFavoriteComponentsRes: undefined,
  pinDocumentComponentRes: null,
  unpinDocumentComponentRes: null,
};
const templatesSlice = createSlice({
  name: "documentComponents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Document Components List
    builder.addCase(getDocumentComponents.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getDocumentComponents.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.getDocumentComponentsRes = action.payload?.data;
    });
    builder.addCase(getDocumentComponents.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get Document Component Detail
    builder.addCase(getDocumentComponentDetail.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getDocumentComponentDetail.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.getDocumentComponentDetailRes = action.payload?.data;
    });
    builder.addCase(getDocumentComponentDetail.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get Document Components Pin List
    builder.addCase(getDocumentFavoriteComponents.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(
      getDocumentFavoriteComponents.fulfilled,
      (state, action) => {
        state.error = null;
        state.message = action.payload?.message;
        state.getDocumentFavoriteComponentsRes = action.payload?.data;
      }
    );
    builder.addCase(getDocumentFavoriteComponents.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Pin Document Component
    builder.addCase(pinDocumentComponent.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(pinDocumentComponent.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;

      const previousData = [...state.getDocumentComponentsRes];
      const previousIndex = previousData?.findIndex(
        (item) => item?.id === action.payload?.data?.id
      );

      if (previousIndex !== -1) {
        previousData[previousIndex].is_favorite = true;
        state.getDocumentComponentsRes = previousData;
      }

      state.getDocumentComponentDetailRes.is_favorite = true;
      state.pinDocumentComponentRes = action.payload?.data;
    });
    builder.addCase(pinDocumentComponent.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // UnPin Document Component
    builder.addCase(unpinDocumentComponent.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(unpinDocumentComponent.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;

      const previousData = [...state.getDocumentComponentsRes];
      const previousIndex = previousData?.findIndex(
        (item) => item?.id === action.payload?.data?.id
      );
      if (previousIndex !== -1) {
        previousData[previousIndex].is_favorite = false;
        state.getDocumentComponentsRes = previousData;
      }

      state.getDocumentComponentDetailRes.is_favorite = false;
      state.unpinDocumentComponentRes = action.payload?.data;
    });
    builder.addCase(unpinDocumentComponent.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default templatesSlice.reducer;
