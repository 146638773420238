import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from "../../utils/api";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const sendChatGPTMessage = createAsyncThunk(
  "sendChatGPTMessage/Create",
  async ({ body, accessToken, message }: any) => {
    try {
      const response = await postRequest({
        url: `/chatRequest`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      response.question = message;

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getAllMessages = createAsyncThunk(
  "getAllMessagess/get",
  async ({ accessToken, project_id, conversation_id }: any) => {
    try {
      const response = await getRequest({
        url: `/aoaiCompletionHistory?project_id=${project_id}&conversation_id=${conversation_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const promptMessage = createAsyncThunk(
  "promptMessage/Create",
  async ({ body, accessToken, project_id, conversation_id, signal }: any) => {
    try {
      const response = await postRequest({
        url: `/aoaiCompletion?project_id=${project_id}&conversation_id=${conversation_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        signal
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const promptStreamMessage = createAsyncThunk(
  "promptMessage/Create",
  async ({ body, accessToken, project_id, conversation_id }: any) => {
    try {
      const response = await postRequest({
        url: `/aoaiCompletionStream?project_id=${project_id}&conversation_id=${conversation_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log(response.status)
    
      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createConversation = createAsyncThunk(
  "createConversation/create",
  async ({
    body,
    accessToken,
    project_id,
    conversation_configuration_id,
    template_type,
  }: any) => {
    try {
      const response = await postRequest({
        url: `/conversation?project_id=${project_id}&conversation_configuration_id=${conversation_configuration_id}&template_type=${template_type}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updateConversation = createAsyncThunk(
  "updateConversation/update",
  async ({ body, accessToken, project_id, conversation_id }: any) => {
    try {
      const response = await putRequest({
        url: `/conversation?project_id=${project_id}&conversation_id=${conversation_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getConversation = createAsyncThunk(
  "getConversation/get",
  async ({ accessToken, project_id, conversation_id }: any) => {
    try {
      const response = await getRequest({
        url: `/conversation?project_id=${project_id}&conversation_id=${conversation_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getAllConversations = createAsyncThunk(
  "getConversations/get",
  async ({ accessToken, project_id, conversation_id }: any) => {
    try {
      const response = await getRequest({
        url: `/conversations?project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const deleteConversations = createAsyncThunk(
  "deleteConversations/delete",
  async ({ accessToken, project_id, conversation_id }: any) => {
    try {
      const response = await deleteRequest({
        url: `/conversation?project_id=${project_id}&conversation_id=${conversation_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getAllAiAssistants = createAsyncThunk(
  "getAllAiAssistants/get",
  async ({ accessToken }: any) => {
    try {
      const response = await getRequest({
        url: `/spAiTemplates`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getSingleAiAssistant = createAsyncThunk(
  "getSingleAiAssistant/get",
  async ({ accessToken, resource_id }: any) => {
    try {
      const response = await getRequest({
        url: `/publicTemplate?id=${resource_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getAllPublishedAiAssistants = createAsyncThunk(
  "getAllPublishedAiAssistants/get",
  async ({ accessToken, query }: any) => {
    try {
      const response = await getRequest({
        url: `/publicTemplates${query}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);