
export function getConfig() {
  const domain = `${process.env.REACT_APP_DOMAIN}`;
  const clientId = `${process.env.REACT_APP_CLIENTID}`;
  const audienceUrl = `${process.env.REACT_APP_AUDIENCE}`;
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.
  const audience =
    audienceUrl && audienceUrl !== "YOUR_API_IDENTIFIER"
      ? audienceUrl
      : null;

  return {
    domain: domain,
    clientId: clientId,
    ...(audience ? { audience } : null),
  };
}
