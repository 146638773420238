import React from "react";

interface IconWithBoxProps {
  icon: any;
  title: any;
  onClickBox: Function;
}

export default function IconWithBox({
  icon: BoxIcon,
  title,
  onClickBox,
}: IconWithBoxProps) {
  return (
    <div
      onClick={() => onClickBox()}
      className="group cursor-pointer w-[110px] px-4 py-3 rounded-lg bg-white shadow-md text-center hover:shadow-2xl"
    >
        <BoxIcon className="text-zinc-400 h-5 mx-auto mb-1 group-hover:text-amber-500" />
        <div className="text-xs">{title}</div>
    </div>
  );
}
