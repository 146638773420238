import React from "react";

interface ProgressBarProps {
  percent: string;
  styles?: string;
  color: string;
}

export default function ProgressBar({ percent, styles, color }: ProgressBarProps) {
  return (
    <div
      className={`w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 ${
        styles || ""
      }`}
    >
      <div
        className={`${color} h-1.5 rounded-full ${styles || ""}`}
        style={{ width: `${percent ? percent : 0}%` }}
      />
    </div>
  );
}
