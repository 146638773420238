// This file has only Microsoft Azure Categories
// ./microsoft_azure_categories.js

export const azure_nodes = [
  {
    name: "Networking",
    items: [
      "application-gateway-containers",
      "application-gateways",
      "atm-multistack",
      "azure-communications-gateway",
      "azure-firewall-manager",
      "azure-firewall-policy",
      "bastions",
      "cdn-profiles",
      "connected-cache",
      "connections",
      "ddos-protection-plans",
      "dns-multistack",
      "dns-private-resolver",
      "dns-security-policy",
      "dns-zones",
      "expressroute-circuits",
      "firewalls",
      "front-door-and-cdn-profiles",
      "ip-address-manager",
      "ip-groups",
      "load-balancer-hub",
      "load-balancers",
      "local-network-gateways",
      "nat",
      "network-interfaces",
      "network-security-groups",
      "network-watcher",
      "on-premises-data-gateways",
      "private-link-service",
      "private-link-services",
      "private-link",
      "proximity-placement-groups",
      "public-ip-addresses-(classic)",
      "public-ip-addresses",
      "public-ip-prefixes",
      "reserved-ip-addresses-(classic)",
      "resource-management-private-link",
      "route-filters",
      "route-tables",
      "service-endpoint-policies",
      "spot-vm",
      "spot-vmss",
      "subnet",
      "traffic-manager-profiles",
      "virtual-network-gateways",
      "virtual-networks-(classic)",
      "virtual-networks",
      "virtual-router",
      "virtual-wan-hub",
      "virtual-wans",
      "web-application-firewall-policies(waf)"
    ]
  },
  {
    name: "Compute",
    items: [
      "app-services",
      "application-group",
      "automanaged-vm",
      "availability-sets",
      "azure-compute-galleries",
      "azure-spring-apps",
      "batch-accounts",
      "cloud-services-(classic)",
      "container-instances",
      "container-services-(deprecated)",
      "disk-encryption-sets",
      "disks-(classic)",
      "disks-snapshots",
      "disks",
      "function-apps",
      "host-groups",
      "host-pools",
      "hosts",
      "image-definitions",
      "image-templates",
      "image-versions",
      "images",
      "kubernetes-services",
      "maintenance-configuration",
      "managed-service-fabric",
      "mesh-applications",
      "metrics-advisor",
      "os-images-(classic)",
      "restore-points-collections",
      "restore-points",
      "service-fabric-clusters",
      "shared-image-galleries",
      "virtual-machine",
      "virtual-machines-(classic)",
      "vm-images-(classic)",
      "vm-scale-sets",
      "workspaces",
    ],
  },
  {
    name: "Databases",
    items: [
      "azure-cosmos-db",
      "azure-data-explorer-clusters",
      "azure-database-mariadb-server",
      "azure-database-migration-services",
      "azure-database-mysql-server",
      "azure-database-postgresql-server-group",
      "azure-database-postgresql-server",
      "azure-purview-accounts",
      "azure-sql-edge",
      "azure-sql-server-stretch-databases",
      "azure-sql-vm",
      "azure-sql",
      "azure-synapse-analytics",
      "cache-redis",
      "data-factories",
      "elastic-job-agents",
      "instance-pools",
      "managed-database",
      "oracle-database",
      "sql-data-warehouses",
      "sql-database",
      "sql-elastic-pools",
      "sql-managed-instance",
      "sql-server-registries",
      "sql-server",
      "ssis-lift-and-shift-ir",
      "virtual-clusters"
    ]
  },
  {
    name: "Containers",
    items: [
      "app-services",
      "azure-red-hat-openshift",
      "batch-accounts",
      "container-instances",
      "container-registries",
      "kubernetes-services",
      "service-fabric-clusters",
    ],
  },
  {
    name: "Analytics",
    items: [
      "analysis-services",
      "azure-data-explorer-clusters",
      "azure-databricks",
      "azure-synapse-analytics",
      "azure-workbooks",
      "data-factories",
      "data-lake-analytics",
      "data-lake-store-gen1",
      "endpoint-analytics",
      "event-hub-clusters",
      "event-hubs",
      "hd-insight-clusters",
      "log-analytics-workspaces",
      "power-bi-embedded",
      "power-platform",
      "private-link-services",
      "stream-analytics-jobs",
    ],
  },
  {
    name: "Azure DevOps",
    items: [
      "api-connections",
      "api-management-services",
      "application-insights",
      "azure-devops",
      "change-analysis",
      "cloudtest",
      "code-optimization",
      "devops-starter",
      "devtest-labs",
      "lab-accounts",
      "lab-services",
      "load-testing",
    ],
  },
  {
    name: "General",
    items: [
      "all-resources",
      "backlog",
      "biz-talk",
      "blob-block",
      "blob-page",
      "branch",
      "browser",
      "bug",
      "builds",
      "cache",
      "code",
      "commit",
      "controls-horizontal",
      "controls",
      "cost-alerts",
      "cost-analysis",
      "cost-budgets",
      "cost-management-and-billing",
      "cost-management",
      "counter",
      "cubes",
      "dashboard",
      "dev-console",
      "download",
      "error",
      "extensions",
      "feature-previews",
      "file",
      "files",
      "folder-blank",
      "folder-website",
      "free-services",
      "ftp",
      "gear",
      "globe-error",
      "globe-success",
      "globe-warning",
      "guide",
      "heart",
      "help-and-support",
      "image",
      "information",
      "input-output",
      "journey-hub",
      "launch-portal",
      "learn",
      "load-test",
      "location",
      "log-streaming",
      "management-groups",
      "management-portal",
      "marketplace-management",
      "marketplace",
      "media-file",
      "media",
      "mobile-engagement",
      "mobile",
      "module",
      "power-up",
      "power",
      "powershell",
      "preview-features",
      "process-explorer",
      "production-ready-database",
      "quickstart-center",
      "recent",
      "region-management",
      "reservations",
      "resource-explorer",
      "resource-group-list",
      "resource-groups",
      "resource-linked",
      "scheduler",
      "search-grid",
      "search",
      "server-farm",
      "service-health",
      "ssd",
      "storage-azure-files",
      "storage-container",
      "storage-queue",
      "subscriptions",
      "table",
      "tag",
      "tags",
      "templates",
      "tfs-vc-repository",
      "toolbox",
      "troubleshoot",
      "versions",
      "web-slots",
      "web-test",
      "website-power",
      "website-staging",
      "workbooks",
      "workflow",
    ],
  },
  {
    name: "Intune",
    items: [
      "client-apps",
      "device-compliance",
      "device-configuration",
      "device-enrollment",
      "device-security-apple",
      "device-security-google",
      "device-security-windows",
      "devices",
      "ebooks",
      "entra-identity-roles-and-administrators",
      "exchange-access",
      "intune-app-protection",
      "intune-for-education",
      "intune",
      "mindaro",
      "security-baselines",
      "software-updates",
      "tenant-status",
    ],
  },
  {
    name: "Management and Governance",
    items: [
      "activity-log",
      "advisor",
      "alerts",
      "application-insights",
      "arc-machines",
      "automation-accounts",
      "azure-arc",
      "azure-lighthouse",
      "blueprints",
      "compliance",
      "cost-management-and-billing",
      "customer-lockbox-for-microsoft-azure",
      "diagnostics-settings",
      "education",
      "intune-trends",
      "log-analytics-workspaces",
      "machinesazurearc",
      "managed-applications-center",
      "managed-desktop",
      "metrics",
      "monitor",
      "my-customers",
      "operation-log-(classic)",
      "policy",
      "recovery-services-vaults",
      "resource-graph-explorer",
      "resources-provider",
      "scheduler-job-collections",
      "service-catalog-mad",
      "service-providers",
      "solutions",
      "universal-print",
      "user-privacy",
    ],
  },
  {
    name: "Migration",
    items: [
      "azure-database-migration-services",
      "azure-databox-gateway",
      "azure-migrate",
      "azure-stack-edge",
      "cost-management-and-billing",
      "data-box",
      "recovery-services-vaults",
    ],
  },
  {
    name: "Mobile",
    items: [
      "app-services",
      "notification-hubs",
      "power-platform",
    ],
  },
  {
    name: "Monitor",
    items: [
      "activity-log",
      "application-insights",
      "auto-scale",
      "azure-monitors-for-sap-solutions",
      "azure-workbooks",
      "change-analysis",
      "diagnostics-settings",
      "log-analytics-workspaces",
      "metrics",
      "monitor",
      "network-watcher",
    ],
  },
  {
    name: "Other",
    items: [
      "aks-automatic",
      "aks-istio",
      "app-compliance-automation",
      "app-registrations",
      "app-space-component",
      "aquila",
      "arc-data-services",
      "arc-kubernetes",
      "arc-postgresql",
      "arc-sql-managed-instance",
      "arc-sql-server",
      "avs-vm",
      "azure-a",
      "azure-backup-center",
      "azure-center-for-sap",
      "azure-chaos-studio",
      "azure-cloud-shell",
      "azure-communication-services",
      "azure-compute-galleries",
      "azure-deployment-environments",
      "azure-dev-tunnels",
      "azure-edge-hardware-center",
      "azure-hpc-workbenches",
      "azure-load-testing",
      "azure-managed-grafana",
      "azure-monitor-dashboard",
      "azure-monitor-pipeline",
      "azure-network-function-manager-functions",
      "azure-network-function-manager",
      "azure-orbital",
      "azure-quotas",
      "azure-sphere",
      "azure-storage-mover",
      "azure-support-center-blue",
      "azure-sustainability",
      "azure-video-indexer",
      "azure-virtual-desktop",
      "azure-vmware-solution",
      "azureattestation",
      "azurite",
      "backup-vault",
      "bare-metal-infrastructure",
      "business-process-tracking",
      "capacity-reservation-groups",
      "central-service-instance-for-sap",
      "ceres",
      "cloud-services-(extended-support)",
      "community-images",
      "compliance-center",
      "compute-fleet",
      "confidential-ledgers",
      "connected-vehicle-platform",
      "container-apps-environments",
      "cost-export",
      "custom-ip-prefix",
      "dashboard-hub",
      "data-collection-rules",
      "database-instance-for-sap",
      "dedicated-hsm",
      "defender-cm-local-manager",
      "defender-dcs-controller",
      "defender-distributer-control-system",
      "defender-engineering-station",
      "defender-external-management",
      "defender-freezer-monitor",
      "defender-historian",
      "defender-hmi",
      "defender-industrial-packaging-system",
      "defender-industrial-printer",
      "defender-industrial-robot",
      "defender-industrial-scale-system",
      "defender-marquee",
      "defender-meter",
      "defender-plc",
      "defender-pneumatic-device",
      "defender-programable-board",
      "defender-relay",
      "defender-robot-controller",
      "defender-rtu",
      "defender-sensor",
      "defender-slot",
      "defender-web-guiding-system",
      "device-update-iot-hub",
      "disk-pool",
      "edge-management",
      "elastic-san",
      "entra-connect-health",
      "entra-connect-sync",
      "entra-identity-licenses",
      "exchange-on-premises-access",
      "express-route-traffic-collector",
      "expressroute-direct",
      "fhir-service",
      "fiji",
      "hdi-aks-cluster",
      "icm-troubleshooting",
      "instance-pools",
      "internet-analyzer-profiles",
      "kubernetes-fleet-manager",
      "local-network-gateways",
      "log-analytics-query-pack",
      "managed-devops-pools",
      "managed-file-shares",
      "managed-instance-apache-cassandra",
      "medtech-service",
      "microsoft-dev-box",
      "mission-landing-zone",
      "mobile-networks",
      "modular-data-center",
      "monitor-health-models",
      "network-managers",
      "network-security-perimeters",
      "open-supply-chain-platform",
      "osconfig",
      "peering-service",
      "peerings",
      "private-endpoints",
      "reserved-capacity",
      "resource-guard",
      "resource-mover",
      "rtos",
      "savings-plans",
      "scvmm-management-servers",
      "sonic-dash",
      "ssh-keys",
      "storage-actions",
      "storage-functions",
      "targets-management",
      "template-specs",
      "test-base",
      "update-management-center",
      "video-analyzers",
      "virtual-enclaves",
      "virtual-instance-for-sap",
      "virtual-visits-builder",
      "vm-app-definitions",
      "vm-app-versions",
      "vm-image-version",
      "wac-installer",
      "wac",
      "web-app-+-database",
      "web-jobs",
      "windows-notification-services",
      "worker-container-app",
      "workspace-gateway",
    ],
  },
  {
    name: "Security",
    items: [
      "application-security-groups",
      "azure-information-protection",
      "azure-sentinel",
      "conditional-access",
      "detonation",
      "entra-identity-risky-signins",
      "entra-identity-risky-users",
      "extendedsecurityupdates",
      "identity-secure-score",
      "key-vaults",
      "microsoft-defender-easm",
      "microsoft-defender-for-cloud",
      "microsoft-defender-for-iot",
      "multifactor-authentication",
      "user-settings",
    ],
  },
  {
    name: "Storage",
    items: [
      "azure-databox-gateway",
      "azure-fileshares",
      "azure-hcp-cache",
      "azure-netapp-files",
      "azure-stack-edge",
      "data-box",
      "data-lake-storage-gen1",
      "data-share-invitations",
      "data-shares",
      "import-export-jobs",
      "recovery-services-vaults",
      "storage-accounts-(classic)",
      "storage-accounts",
      "storage-explorer",
      "storage-sync-services",
      "storsimple-data-managers",
      "storsimple-device-managers",
    ],
  },
  {
    name: "Web",
    items: [
      "api-center",
      "api-connections",
      "api-management-services",
      "app-service-certificates",
      "app-service-domains",
      "app-service-environments",
      "app-service-plans",
      "app-services",
      "app-space",
      "azure-media-service",
      "azure-spring-apps",
      "cognitive-search",
      "cognitive-services",
      "front-door-and-cdn-profiles",
      "notification-hub-namespaces",
      "power-platform",
      "signalr",
      "static-apps",
    ],
  },
  {
    name: "Azure Ecosystem",
    items: [
      "applens",
      "azure-hybrid-center",
      "collaborative-service",
    ],
  },
  {
    name: "IoT",
    items: [
      "azure-cosmos-db",
      "azure-databox-gateway",
      "azure-iot-operations",
      "azure-maps-accounts",
      "azure-stack-hci-sizer",
      "azure-stack",
      "device-provisioning-services",
      "digital-twins",
      "event-grid-subscriptions",
      "event-hub-clusters",
      "event-hubs",
      "function-apps",
      "industrial-iot",
      "iot-central-applications",
      "iot-edge",
      "iot-hub",
      "logic-apps",
      "machine-learning-studio-(classic)-web-services",
      "machine-learning-studio-web-service-plans",
      "machine-learning-studio-workspaces",
      "notification-hub-namespaces",
      "notification-hubs",
      "stack-hci-premium",
      "stream-analytics-jobs",
      "time-series-data-sets",
      "time-series-insights-access-policies",
      "time-series-insights-environments",
      "time-series-insights-event-sources",
      "windows10-core-services",
    ],
  },
  {
    name: "Integration",
    items: [
      "api-connections",
      "api-management-services",
      "app-configuration",
      "azure-api-for-fhir",
      "azure-data-catalog",
      "azure-databox-gateway",
      "azure-service-bus",
      "azure-sql-server-stretch-databases",
      "azure-stack-edge",
      "data-factories",
      "event-grid-domains",
      "event-grid-subscriptions",
      "event-grid-topics",
      "integration-accounts",
      "integration-environments",
      "integration-service-environments",
      "logic-apps-custom-connector",
      "logic-apps",
      "partner-namespace",
      "partner-registration",
      "partner-topic",
      "power-platform",
      "relays",
      "sendgrid-accounts",
      "software-as-a-service",
      "sql-data-warehouses",
      "storsimple-device-managers",
      "system-topic",
    ],
  },
  {
    name: "Azure Stack",
    items: [
      "capacity",
      "infrastructure-backup",
      "multi-tenancy",
      "offers",
      "plans",
      "updates",
      "user-subscriptions",
    ],
  },
  {
    name: "Blockchain",
    items: [
      "abs-member",
      "azure-blockchain-service",
      "azure-token-service",
      "blockchain-applications",
      "consortium",
      "outbound-connection",
    ],
  },
  {
    name: "Hybrid and Multicloud",
    items: [
      "azure-operator-5g-core",
      "azure-operator-insights",
      "azure-operator-nexus",
      "azure-operator-service-manager",
      "azure-programmable-connectivity",
    ],
  },
  {
    name: "Identity",
    items: [
      "active-directory-connect-health",
      "administrative-units",
      "api-proxy",
      "app-registrations",
      "azure-ad-b2c",
      "azure-information-protection",
      "enterprise-applications",
      "entra-connect",
      "entra-domain-services",
      "entra-global-secure-access",
      "entra-id-protection",
      "entra-identity-custom-roles",
      "entra-identity-licenses",
      "entra-identity-roles-and-administrators",
      "entra-internet-access",
      "entra-managed-identities",
      "entra-private-access",
      "entra-privleged-identity-management",
      "entra-verified-id",
      "external-identities",
      "groups",
      "identity-governance",
      "managed-identities",
      "multi-factor-authentication",
      "security",
      "tenant-properties",
      "user-settings",
      "users",
      "verifiable-credentials",
      "verification-as-a-service",
    ],
  },
];
