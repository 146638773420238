import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import { useAppSelector } from "../../hooks";
import {
  DocumentTextIcon,
  LightBulbIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import StarRating from "../startRating";
import ComplexityStatus from "../../components/complexityStatus";
import CustomButton from "../../components/customButton";

interface TemplateBoxProps {
  data: any;
  onShow: Function;
  onUse: Function;
  tempType?: string;
}

export default function TemplateBox({
  data,
  onShow,
  onUse,
  tempType,
}: TemplateBoxProps) {
  const [rating, setRating] = useState(data.rating);

  const { profileData } = useAppSelector<any>((state: any) => state.profile);

  const onGiveRating = (value: number) => {
    setRating(value);
  };

  return (
    <div
      onClick={() => onShow()}
      className="group overflow-hidden cursor-pointer relative w-full bg-white shadow-sm border transition-all border-zinc-100 bg-zinc-0 rounded-xl hover:shadow-xl top-0 hover:top-1"
    >
      <div className="px-6 py-6">
        {/* {tempType !== "my" && (
          <div className="flex items-center justify-between text-xs mb-4">
            <span
              className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset justify-center bg-blue-50 text-blue-700 ring-blue-600/20`}
            >
              {data?.category}
            </span>
            <div className="flex items-center">
            <span className="text-xs mr-1 text-zinc-400">{rating}</span>
            <StarRating ratingValue={rating} onChangeRating={onGiveRating} />
          </div>
          </div>
        )} */}

        <div className="flex items-center justify-between mb-4">
          <span
            className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset justify-center bg-blue-50 text-blue-700 ring-blue-600/20`}
          >
            {data?.category}
          </span>

          <div className="text-neutral-600 text-xs flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="12"
              fill="currentColor"
              className="mr-1"
            >
              <path
                fill-opacity="0.8"
                d="M5.7 11a4.5 4.5 0 0 1-3.18-7.68.5.5 0 0 1 .815.18c.107.223.253.426.43.6A4.62 4.62 0 0 1 5.38 1.11a.5.5 0 0 1 .665.035c.459.437.961.826 1.5 1.16.475.286.92.621 1.325 1 .427.415.767.911 1 1.46a.5.5 0 0 1-.925.38 3.5 3.5 0 0 0-.745-1.12 7.395 7.395 0 0 0-1.17-.885c-.465-.289-.91-.61-1.33-.96A4.04 4.04 0 0 0 4.7 5a.5.5 0 0 1-.725.445 3.47 3.47 0 0 1-1.16-.945A3.5 3.5 0 0 0 5.7 10c.312 0 .624-.041.925-.125a.5.5 0 0 1 .26.965c-.386.106-.785.16-1.185.16Z"
              ></path>
              <path
                fill-opacity="0.8"
                d="M8.7 10a.5.5 0 0 1-.5-.5v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 1 1 1 0v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-.5.5Z"
              ></path>
            </svg>
            <span>5246</span>
          </div>
        </div>

        <div className="flex items-center mb-4 text-xs">
          {data.type === "document" && (
            <DocumentTextIcon className="h-5 mr-2 text-zinc-500" />
          )}
          {data.type === "project" && (
            <LightBulbIcon className="h-5 mr-2 text-zinc-500" />
          )}
          <span className="text-md text-customDarkBlue text-ellipsis flex-shrink-0 overflow-x-hidden whitespace-nowrap line-clamp-1">
            {data?.title}
          </span>
        </div>

        <div className="mb-3 text-xs text-zinc-500 line-clamp-2">
          {data?.description}
        </div>

        <div className="flex items-center justify-between text-xs mb-3">
          <div className="text-zinc-600">Complexity</div>
          <div>
            <ComplexityStatus status={data?.complexity} />
          </div>
        </div>

        {tempType !== "my" && (
          <>
            <div className="flex items-center justify-between text-xs mb-3">
              <div>Imports</div>
              <div>{data?.imports}</div>
            </div>

            <div className="flex items-center justify-between mb-2 text-xs">
              <div>Published By</div>
              {data?.published_by === "userTemplate" && (
                <div className="line-clamp-1" data-tooltip-id="tooltip-content">
                  {`${profileData?.first_name || profileData?.last_name ? `${profileData?.first_name} ${profileData?.last_name}` : profileData?.email}`}
                </div>
              )}

              <Tooltip id="tooltip-content" place="top">
                {`${profileData?.first_name || profileData?.last_name ? `${profileData?.first_name} ${profileData?.last_name}` : profileData?.email}`}
              </Tooltip>

              <>
                {data?.published_by === "solutionPilotTemplate" && (
                  <div className="line-clamp-1">Solution Pilot</div>
                )}

                {data?.published_by === "communityTemplate" && (
                  <div className="line-clamp-1">Community</div>
                )}
              </>
            </div>
          </>
        )}
      </div>

      {/* <div className="bg-customDarkBlue backdrop-blur-[10px] transition-all absolute w-[100%] h-[100%] bottom-[-50%] left-0 opacity-0 group-hover:opacity-10" /> */}

      <div className="w-full px-6 absolute bottom-[-100px] group-hover:bottom-[20px] transition-all">
        <CustomButton
          text="Use"
          type="button"
          onClickBtn={(e: any) => {
            if (e) {
              e.stopPropagation();
            }
            onUse(data);
          }}
          beforeIcon={
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          }
          btnStyle="w-full flex items-center justify-center"
        />
      </div>

      {/* <div className="flex border-t border-zinc-100 text-xs absolute w-full bottom-0">
        <div
          className="w-[50%] flex items-center justify-center border-r border-zinc-100 p-3 rounded-bl-xl cursor-pointer hover:bg-blue-500 hover:text-white"
          onClick={() => onShow()}
        >
          Show
        </div>
        <div
          className="w-[50%] flex items-center justify-center p-3 rounded-br-xl cursor-pointer hover:bg-blue-500 hover:text-white"
          onClick={(e: any) => {
            e.stopPropagation();
            onUse(data);
          }}
        >
          Use
        </div>
      </div> */}
    </div>
  );
}
