import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest
} from "../../utils/api";

export const createUserAiAssistant = createAsyncThunk(
  "createUserAiAssistant/create",
  async ({
    body,
    accessToken,
  }: any) => {
    try {
      const response = await postRequest({
        url: `/tenantAiTemplate`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updateUserAiAssistant = createAsyncThunk(
  "updateUserAiAssistant/update",
  async ({ body, accessToken, resource_id }: any) => {
    try {
      const response = await putRequest({
        url: `/tenantAiTemplate?id=${resource_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getUserAiAssistant = createAsyncThunk(
  "getUserAiAssistant/get",
  async ({ accessToken, resource_id }: any) => {
    try {
      const response = await getRequest({
        url: `/tenantAiTemplate?id=${resource_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getPublicAiAssistant = createAsyncThunk(
  "getPublicAiAssistant/get",
  async ({ accessToken, resource_id }: any) => {
    try {
      const response = await getRequest({
        url: `/publicTemplate?id=${resource_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getUserAllAiAssistants = createAsyncThunk(
  "getUserAllAiAssistants/get",
  async ({ accessToken }: any) => {
    try {
      const response = await getRequest({
        url: `/tenantAiTemplates`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const deleteUserAiAssistant = createAsyncThunk(
  "deleteUserAiAssistant/delete",
  async ({ accessToken, resource_id }: any) => {
    try {
      const response = await deleteRequest({
        url: `/tenantAiTemplate?id=${resource_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const publishUserAiAssistant = createAsyncThunk(
  "publishUserAiAssistant/update",
  async ({ accessToken, resource_id }: any) => {
    try {
      const response = await putRequest({
        url: `/tenantAiTemplate/publish?id=${resource_id}`,
        payload: {},
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const unPublishUserAiAssistant = createAsyncThunk(
  "unPublishUserAiAssistant/update",
  async ({ accessToken, resource_id }: any) => {
    try {
      const response = await putRequest({
        url: `/tenantAiTemplate/unpublish?id=${resource_id}`,
        payload: {},
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);