import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DocumentTextIcon } from "@heroicons/react/20/solid";
import {useNavigate} from 'react-router-dom';
import RoundButton from "../../../../components/roundButton";
import CustomModal from "../../../../components/customModal";
import DocumentForm from "./documentForm";
import ConfirmationModal from "../../../../components/confirmationModal";
import DocumentBox from "./documentBox";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getProjectAllDocuments,
  createProjectDocument,
  updateProjectDocument,
  deleteProjectDocument,
} from "../../../../redux/projects/projectsApi";
import { setSelectedDocument } from "../../../../redux/projects/projectsSlice";
import WrapperLoader from "../../../../components/wrapperLoader";
import { getLastIdFromUrl } from "../../../../utils/utilities";
import ItemWithDropdown from "../../../../components/itemWithDropdown";
import TemplatesProjectsDocumentsModal from "../../../templates/templatesProjectsDocumentsModal";
import { setTemplatesCurrentTab } from "../../../../redux/profile/profileSlice";
import { getQueryParams } from "../../../../utils/utilities";

interface DocumnetsPagesProps {
  isTextEditorDirty: boolean;
  updatingProjectDocument: Function;
}

interface Item {
  content: string;
  created_at: string;
  document_order: number;
  id: string;
  project_id: string;
  title: string;
  updated_at: string;
  user_id: string;
}

const DocumnetsPages = ({
  isTextEditorDirty,
  updatingProjectDocument,
}: DocumnetsPagesProps) => {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [allDocumentsLoading, setAllDocumentsLoading] = useState(false);
  const [openDocumentsTemplatesModal, setOpenDocumentsTemplatesModal] =
    useState(false);

  const [draggedItem, setDraggedItem] = useState<Item | null>(null);
  const [docuemntsitems, setDocumentsItems] = useState<Item[]>([]);
  const [targetItemIndex, setTargetItemIndex] = useState<any>(null);

  const navigate = useNavigate();

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, item: Item) => {
    setDraggedItem(item);
  };

  const handleDragEnter = (
    e: React.DragEvent<HTMLDivElement>,
    targetItem: Item
  ) => {
    const newList = [...docuemntsitems];
    const draggedIndex = docuemntsitems.findIndex((i) => i === draggedItem);
    const targetIndex = docuemntsitems.findIndex((i) => i === targetItem);

    setTargetItemIndex(targetIndex);

    // Swap the docuemntsitems
    if (draggedIndex !== -1 && targetIndex !== -1) {
      newList[draggedIndex] = targetItem;
      newList[targetIndex] = draggedItem as Item;
    }

    setDocumentsItems(newList);
  };

  const handleDragEnd = () => {
    if (draggedItem) {
      const payloadBody = {
        title: draggedItem?.title,
        document_order: targetItemIndex + 1,
        project_id: draggedItem?.project_id,
        id: draggedItem?.id,
      };

      updatingDocument(payloadBody);
    }
  };

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { getProjectAllDocumentsRes, selectedDocument, deleteProjectDocumentRes } = useAppSelector(
    (state) => state.projects
  );

  useEffect(() => {
    const documentsList = [...(getProjectAllDocumentsRes || [])];
    documentsList.sort((a: any, b: any) => a.document_order - b.document_order);
    setDocumentsItems(documentsList);
  }, [getProjectAllDocumentsRes]);

  useEffect(() => {
    if (getProjectAllDocumentsRes?.length > 0 && deleteProjectDocumentRes) {
      dispatch(setSelectedDocument(getProjectAllDocumentsRes[0]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProjectDocumentRes]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          setAllDocumentsLoading(true);
          await dispatch(
            getProjectAllDocuments({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setAllDocumentsLoading(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryStringObj = getQueryParams(window.location.href);

    if(queryStringObj?.active === 'documents') {
      navigate(
        `${window.location.pathname}?active=documents&id=${selectedDocument?.id || queryStringObj?.id}`
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocument]);

  useEffect(() => {
    const queryStringObj = getQueryParams(window.location.href);
    if (docuemntsitems?.length > 0 && queryStringObj?.active === 'documents') {

      const findDocument = docuemntsitems.find(
        (data) => data.id === queryStringObj.id);
        
      dispatch(setSelectedDocument(findDocument || docuemntsitems[0]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docuemntsitems]);

  const onCloseModal = () => {
    setOpenFormModal(false);
    setSelectedData(null);
  };

  const onOpenModal = () => {
    setOpenFormModal(true);
  };

  const onOpenEditModal = (data: any) => {
    setSelectedData(data);
    setOpenFormModal(true);
  };

  const updatingDocument = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            updateProjectDocument({
              body,
              accessToken,
              project_id: selectedData?.project_id || body?.project_id,
              document_id: selectedData?.id || body?.id,
            })
          );

          onCloseModal();
          setFormLoader(false);
          setDraggedItem(null);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };
    fetchData();
  };

  const creatingDocument = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createProjectDocument({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          onCloseModal();
          setFormLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };
    fetchData();
  };

  const deletingDocument = () => {
    const fetchData = async () => {
      setDeleteLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteProjectDocument({
              accessToken,
              project_id: selectedData?.project_id,
              document_id: selectedData?.id,
            })
          );

          setDeleteLoader(false);
          closeConfirmationModal();
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setDeleteLoader(false);
        closeConfirmationModal();
      }
    };
    fetchData();
  };

  const onSubmitForm = async (body: any) => {
    if (selectedData) {
      updatingDocument(body);
    } else {
      creatingDocument(body);
    }
  };

  const deleteConfirmationModal = (data: any) => {
    setSelectedData(data);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedData(null);
  };

  const onSelectedDocument = async (data: any) => {
    if (isTextEditorDirty) {
      await updatingProjectDocument();
    }

    dispatch(setSelectedDocument(data));
  };

  const openingTemplateDocuments = (value: number) => {
    dispatch(setTemplatesCurrentTab(value));
    setOpenDocumentsTemplatesModal(true);
  };

  const actionsList = [
    {
      id: 1,
      name: "Black Document",
      onActionClick: () => onOpenModal(),
      icon: (
        <DocumentTextIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
      ),
    },
    {
      id: 2,
      name: "My Templates",
      onActionClick: () => openingTemplateDocuments(3),
      icon: (
        <DocumentTextIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
      ),
    },
    {
      id: 4,
      name: "Community Templates",
      onActionClick: () => openingTemplateDocuments(2),
      icon: (
        <DocumentTextIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
      ),
    },
  ];

  return (
    <>
      <WrapperLoader loading={allDocumentsLoading}>
        <div className="p-0">
          <div className="mb-3">
            <h3 className=" text-md mb-2 pb-2 border-b border-zinc-100">
              Documents
            </h3>
            <ItemWithDropdown actionsData={actionsList}>
              <div className="w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-amber-400">
                <RoundButton onClickBtn={() => console.log("clicking...")} />
              </div>
            </ItemWithDropdown>
          </div>

          <div
            className="overflow-y-hidden hover:overflow-y-auto transition-all"
            style={{ height: "calc(100vh - 169px)" }}
          >
            <ul className="drag-drop-container mt-3 grid grid-cols-1">
              {docuemntsitems.map((item, index) => (
                <div
                  key={item.id}
                  draggable
                  onDragStart={(e) => handleDragStart(e, item)}
                  onDragEnter={(e) => handleDragEnter(e, item)}
                  onDragEnd={handleDragEnd}
                  className={`drag-item ${draggedItem === item ? "dragging" : ""}`}
                >
                  <DocumentBox
                    totalLength={docuemntsitems?.length}
                    key={index}
                    data={item}
                    onEdit={() => onOpenEditModal(item)}
                    onDelete={() => deleteConfirmationModal(item)}
                    deleteLoader={false}
                    selectedData={selectedData}
                    onSelectDocument={() => onSelectedDocument(item)}
                    selectedDocument={selectedDocument}
                  />
                </div>
              ))}
              {/* {getProjectAllDocumentsRes?.length > 0
                ? getProjectAllDocumentsRes.map((data: any, index: number) => (
                    <DocumentBox
                      totalLength={getProjectAllDocumentsRes?.length}
                      key={index}
                      data={data}
                      onEdit={() => onOpenEditModal(data)}
                      onDelete={() => deleteConfirmationModal(data)}
                      deleteLoader={false}
                      selectedData={selectedData}
                      onSelectDocument={() => onSelectedDocument(data)}
                      selectedDocument={selectedDocument}
                    />
                  ))
                : ""} */}
            </ul>
          </div>
        </div>
      </WrapperLoader>

      <CustomModal
        open={openFormModal}
        onCloseModal={() => onCloseModal()}
        title={`${selectedData ? "Update" : "Add"} Document`}
        size="max-w-lg"
      >
        <DocumentForm
          onCloseModal={() => onCloseModal()}
          loading={formLoader}
          onSubmitData={onSubmitForm}
          editData={selectedData}
        />
      </CustomModal>

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingDocument}
        deleteLoader={deleteLoader}
        title="Document Delete Confirmation"
        description={
          <div>
            Are you sure you want to delete: <b>{selectedData?.title}</b>
          </div>
        }
      />

      {openDocumentsTemplatesModal && (
        <TemplatesProjectsDocumentsModal
          onCloseModal={() => setOpenDocumentsTemplatesModal(false)}
          openModal={openDocumentsTemplatesModal}
          onUseTemplate={() => console.log("Creating Document")}
        />
      )}
    </>
  );
};

export default DocumnetsPages;
