import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import MainHeader from "../components/header";
import Sidebar from "../components/sidebar";
import { classNames } from "../utils/utilities";
import { setCollapseSidebar } from "../redux/profile/profileSlice";
import { useAppSelector } from "../hooks";

interface MainLayoutProps {
  children: React.ReactNode | React.ReactNode[];
}

export default function MainLayout({ children }: MainLayoutProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();
  const { collapseSidebar } = useAppSelector((state) => state.profile);

  return (
    <div className="overflow-x-hidden">
      <Sidebar
        classNames={classNames}
        setSidebarOpen={setSidebarOpen}
        sidebarOpen={sidebarOpen}
        setCollapseSidebar={setCollapseSidebar}
        collapseSidebar={collapseSidebar}
      />

      <div>
        {/* {location.pathname !== "/home" &&
          !location.pathname.includes("template") &&
          !location.pathname.includes("document-components") &&
          !location.pathname.includes("ai-assistant") &&
          !location.pathname.includes("profile") && (
            <MainHeader
              setSidebarOpen={setSidebarOpen}
              collapseSidebar={collapseSidebar}
            />
          )} */}

        <MainHeader
          setSidebarOpen={setSidebarOpen}
          collapseSidebar={collapseSidebar}
        />

        <div
          id="main_content"
          style={{ boxShadow: "0 0 5px 2px rgba(0, 0, 0, 0.07)" }}
          className={`h-[calc(100vh_-49px)] overflow-hidden bg-white rounded-tl-md ${collapseSidebar ? "lg:ml-[52px]" : "lg:ml-64"} pb-6 ${
            location.pathname === "/home" ||
            location.pathname.includes("template") ||
            location.pathname.includes("ai-assistant") ||
            location.pathname.includes("profile") ||
            location.pathname.includes("document-components")
              ? "mt-[0px]"
              : "mt-[49px]"
          }`}
        >
          <main>{children}</main>
        </div>
      </div>
    </div>
  );
}
