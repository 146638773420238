import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import InputField from "../../../components/formFields/inputField";
import CustomButton from "../../../components/customButton";
import TextAreaField from "../../../components/formFields/textareaField";
import SelectField from "../../../components/formFields/selectField";
import { Tooltip } from "react-tooltip";
import { categoriesList } from "../../../utils/utilities";

interface CreationFormProps {
  onCloseModal: () => void;
  editData: any;
  onSubmitForm: Function;
  loader: boolean;
  editMode: boolean;
}

export default function CreationForm({
  onCloseModal,
  editData,
  onSubmitForm,
  loader,
  editMode,
}: CreationFormProps) {
  const [temperatureValue, setTemperatureValue] = useState("0.5");
  const [frequencyValue, setFrequencyValue] = useState("0.5");
  const [presenceValue, setPresenceValue] = useState("0.5");
  const [topPValue, setTopPValue] = useState("0.5");
  const [maxTokensValue, setMaxTokensValue] = useState("800");

  const validationObject = {
    title: yup.string().required("Title is required"),
    category: yup.string().required("Category is required"),
    details: yup.string().required("Details is required"),
    prompt: yup.string().required("Prompt is required"),
  };

  const validationSchema = yup.object().shape(validationObject);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: editMode ? editData?.title : "",
      details: editMode ? editData?.description : "",
      prompt: editMode ? editData?.configuration?.system_config : "",
      category: editMode ? editData?.category : "Personal",
    },
    validationSchema,
    onSubmit: (values) => {
      // const payloadObj = {
      //   title: values?.title,
      //   category: values?.category,
      //   details: values?.details,
      //   prompt_role_message: values?.prompt,
      //   ai_temperature: temperatureValue,
      //   frequency_penalty: frequencyValue,
      //   presence_penalty: presenceValue,
      //   top_p: topPValue,
      //   max_tokens: maxTokensValue,
      //   prompt_role: "system",
      //   status: "unpublished",
      //   stop: "End",
      // };

      const payloadObj = {
        title: values?.title,
        description: values?.details,
        category: values?.category,
        ai_vendor: "Azure",
        ai_type: "Open AI GPT-4",
        configuration: {
          ai_temperature: parseFloat(temperatureValue),
          prompt_role: "system",
          system_config: values?.prompt,
          top_p: parseFloat(topPValue),
          frequency_penalty: parseFloat(frequencyValue),
          presence_penalty: parseFloat(presenceValue),
          max_tokens: parseFloat(maxTokensValue),
          stop: "End",
        },
        privacy: true,
        published_by: "userTemplate",
      };

      onSubmitForm(payloadObj);
    },
  });

  useEffect(() => {
    if (editData && editMode) {
      setTemperatureValue(editData?.configuration?.ai_temperature);
      setFrequencyValue(editData?.configuration?.frequency_penalty);
      setPresenceValue(editData?.configuration?.presence_penalty);
      setTopPValue(editData?.configuration?.top_p);
      setMaxTokensValue(editData?.configuration?.max_tokens);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const eachFieldRow =
    "items-start space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 border-b border-gray-200";

  return (
    <form
      className="flex flex-col bg-white shadow-xl"
      onSubmit={formik.handleSubmit}
    >
      {/* container */}
      <div
        className="overflow-y-scroll"
        style={{
          height: editMode ? "calc(100vh - 170px)" : "calc(100vh - 182px)",
        }}
      >
        {/* Title */}
        <div className={eachFieldRow}>
          <div className="flex items-center">
            <InformationCircleIcon
              data-tooltip-id="tooltip-content"
              className="w-5 text-zinc-400 hover:text-customDarkBlue"
            />
            <Tooltip id="tooltip-content" place="left">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>Make it meaningful so its self explanatory.</span>
                <span>This is just a simple tittle for your prompt configuration.</span>
              </div>
            </Tooltip>

            <label
              htmlFor="title"
              className="block ml-2 text-xs font-medium leading-6 text-customLightBlue"
            >
              Prompt Title
            </label>
          </div>
          <div className="sm:col-span-2">
            <InputField
              type="text"
              id="title"
              {...formik.getFieldProps("title")}
              placeholder="For example: 'Azure Architect'"
            />

            {formik.touched.title && formik.errors.title ? (
              <div className="text-red-600">{formik.errors.title}</div>
            ) : null}
          </div>
        </div>

        {/* Category */}
        <div className={eachFieldRow}>
          <div className="flex items-center">
            <InformationCircleIcon
              data-tooltip-id="tooltip-content"
              className="w-5 text-zinc-400 hover:text-customDarkBlue"
            />
            <Tooltip id="tooltip-content" place="left">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>Select a category for this prompt.</span>
                <span>You can always reach out to us and propose a new one.</span>
              </div>
            </Tooltip>

            <label
              htmlFor="category"
              className="block ml-2 text-xs font-medium leading-6 text-customLightBlue"
            >
              Category
            </label>
          </div>
          <div className="sm:col-span-2">
            <SelectField
              options={categoriesList}
              id="category"
              {...formik.getFieldProps("category")}
            />

            {formik.touched.category && formik.errors.category ? (
              <div className="text-red-600">{formik.errors.category}</div>
            ) : null}
          </div>
        </div>

        {/* Details */}
        <div className={eachFieldRow}>
          <div className="flex items-center">
            <InformationCircleIcon
              data-tooltip-id="tooltip-content"
              className="w-5 text-zinc-400 hover:text-customDarkBlue"
            />
            <Tooltip id="tooltip-content" place="left">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>This is just a brief description</span>
                <span>about the prompt configuration.</span>
              </div>
            </Tooltip>

            <label
              htmlFor="description"
              className="block ml-2 text-xs font-medium leading-6 text-customLightBlue"
            >
              Description
            </label>
          </div>
          <div className="sm:col-span-2">
            <TextAreaField
              id="details"
              {...formik.getFieldProps("details")}
              placeholder="Provide a brief description about this prompt configuration."
            />

            {formik.touched.details && formik.errors.details ? (
              <div className="text-red-600">{formik.errors.details}</div>
            ) : null}
          </div>
        </div>

        {/* Prompt */}
        <div className={eachFieldRow}>
          <div className="flex items-center">
            <InformationCircleIcon
              data-tooltip-id="tooltip-content"
              className="w-5 text-zinc-400 hover:text-customDarkBlue"
            />
            <Tooltip id="tooltip-content" place="left">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>Prompt configuration will help the AI deliver the best possible completion.</span>
                <span>For example by setting a value in this field like: </span>
                <span>'You always respond in direct and professional tone'</span>
                <span>Each time you receive a completion AI will generate it with profesional tone without you having</span>
                <span>to mention it in the prompt every time you send it.</span>
                <span>For more read this page.</span>
              </div>
            </Tooltip>

            <label
              htmlFor="description"
              className="block ml-2 text-xs font-medium leading-6 text-customLightBlue"
            >
              Prompt Configuration
            </label>
          </div>
          <div className="sm:col-span-2">
            <TextAreaField
              id="prompt"
              {...formik.getFieldProps("prompt")}
              placeholder="Insert your prompt configuration. Check the tooltip for more information."
            />

            {formik.touched.prompt && formik.errors.prompt ? (
              <div className="text-red-600">{formik.errors.prompt}</div>
            ) : null}
          </div>
        </div>

        {/* Temperature */}
        <div className={eachFieldRow}>
          <div className="flex items-center">
            <InformationCircleIcon
              data-tooltip-id="tooltip-content"
              className="w-5 text-zinc-400 hover:text-customDarkBlue"
            />
            <Tooltip id="tooltip-content" place="left">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>A lower temperature results in more predictable and conservative outputs,</span>
                <span>while a higher temperature produces more varied and</span>
                <span>sometimes creative or unexpected responses.</span>
              </div>
            </Tooltip>

            <label
              htmlFor="title"
              className="block ml-2 text-xs font-medium leading-6 text-customLightBlue"
            >
              Temperature
            </label>
          </div>
          <div className="sm:col-span-2">
            <div className="text-xs text-zinc-400 mb-1">
              <span>0.1</span>
              <span className="ml-[16px]">0.2</span>
              <span className="ml-[16px]">0.3</span>
              <span className="ml-[15px]">0.4</span>
              <span className="ml-[14px]">0.5</span>
              <span className="ml-[16px]">0.6</span>
              <span className="ml-[16px]">0.7</span>
              <span className="ml-[16px]">0.8</span>
              <span className="ml-[13px]">0.9</span>
              <span className="ml-[18px]">1</span>
            </div>

            <input
              type="range"
              min="0.1"
              max="1"
              value={temperatureValue}
              step="0.1"
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
              onChange={(e: any) => setTemperatureValue(e.target.value)}
            />
          </div>
        </div>

        {/* Frequency */}
        <div className={eachFieldRow}>
          <div className="flex items-center">
            <InformationCircleIcon
              data-tooltip-id="tooltip-content"
              className="w-5 text-zinc-400 hover:text-customDarkBlue"
            />
            <Tooltip id="tooltip-content" place="left">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>The "frequency penalty" in AI settings reduces repetition,</span>
                <span>encouraging diverse and natural language in responses.</span>
              </div>
            </Tooltip>

            <label
              htmlFor="title"
              className="block ml-2 text-xs font-medium leading-6 text-customLightBlue"
            >
              Frequency
            </label>
          </div>
          <div className="sm:col-span-2">
            <div className="text-xs text-zinc-400 mb-1">
              <span>0.1</span>
              <span className="ml-[16px]">0.2</span>
              <span className="ml-[16px]">0.3</span>
              <span className="ml-[15px]">0.4</span>
              <span className="ml-[14px]">0.5</span>
              <span className="ml-[16px]">0.6</span>
              <span className="ml-[16px]">0.7</span>
              <span className="ml-[16px]">0.8</span>
              <span className="ml-[13px]">0.9</span>
              <span className="ml-[18px]">1</span>
            </div>

            <input
              type="range"
              min="0.1"
              max="1"
              value={frequencyValue}
              step="0.1"
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
              onChange={(e: any) => setFrequencyValue(e.target.value)}
            />
          </div>
        </div>

        {/* Presence */}
        <div className={eachFieldRow}>
          <div className="flex items-center">
            <InformationCircleIcon
              data-tooltip-id="tooltip-content"
              className="w-5 text-zinc-400 hover:text-customDarkBlue"
            />
            <Tooltip id="tooltip-content" place="left">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>Reduce the chance of repeating any token that has appeared in</span>
                <span>the text at all so far. This increases the likelihood of </span>
                <span>introducing new topics in a response.</span>
              </div>
            </Tooltip>

            <label
              htmlFor="title"
              className="block ml-2 text-xs font-medium leading-6 text-customLightBlue"
            >
              Presence
            </label>
          </div>
          <div className="sm:col-span-2">
            <div className="text-xs text-zinc-400 mb-1">
              <span>0.1</span>
              <span className="ml-[16px]">0.2</span>
              <span className="ml-[16px]">0.3</span>
              <span className="ml-[15px]">0.4</span>
              <span className="ml-[14px]">0.5</span>
              <span className="ml-[16px]">0.6</span>
              <span className="ml-[16px]">0.7</span>
              <span className="ml-[16px]">0.8</span>
              <span className="ml-[13px]">0.9</span>
              <span className="ml-[18px]">1</span>
            </div>

            <input
              type="range"
              min="0.1"
              max="1"
              value={presenceValue}
              step="0.1"
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
              onChange={(e: any) => setPresenceValue(e.target.value)}
            />
          </div>
        </div>

        {/* Top P */}
        <div className={eachFieldRow}>
          <div className="flex items-center">
            <InformationCircleIcon
              data-tooltip-id="tooltip-content"
              className="w-5 text-zinc-400 hover:text-customDarkBlue"
            />
            <Tooltip id="tooltip-content" place="left">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>Similar to temperature, this controls randomness but uses a different method. </span>
                <span>Lowering Top P will narrow the model’s token selection to likelier tokens. </span>
                <span>Increasing Top P will let the model choose from tokens with both</span>
                <span>high and low likelihood. Try adjusting temperature or Top P but not both.</span>
              </div>
            </Tooltip>

            <label
              htmlFor="title"
              className="block ml-2 text-xs font-medium leading-6 text-customLightBlue"
            >
              TopP
            </label>
          </div>
          <div className="sm:col-span-2">
            <div className="text-xs text-zinc-400 mb-1">
              <span>0.1</span>
              <span className="ml-[16px]">0.2</span>
              <span className="ml-[16px]">0.3</span>
              <span className="ml-[15px]">0.4</span>
              <span className="ml-[14px]">0.5</span>
              <span className="ml-[16px]">0.6</span>
              <span className="ml-[16px]">0.7</span>
              <span className="ml-[16px]">0.8</span>
              <span className="ml-[13px]">0.9</span>
              <span className="ml-[18px]">1</span>
            </div>

            <input
              type="range"
              min="0.1"
              max="1"
              value={topPValue}
              step="0.1"
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
              onChange={(e: any) => setTopPValue(e.target.value)}
            />
          </div>
        </div>

        {/* Max Tokens */}
        <div className={eachFieldRow}>
          <div className="flex items-center">
            <InformationCircleIcon
              data-tooltip-id="tooltip-content"
              className="w-5 text-zinc-400 hover:text-customDarkBlue"
            />
            <Tooltip id="tooltip-content" place="left">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>Set a limit on the number of tokens per model response. The API supports a maximum </span>
                <span>of MaxTokensPlaceholderDoNotTranslate tokens shared between the prompt (including system message,</span>
                <span>examples, message history, and user query) and the model's response. One token is roughly 4 characters for typical English text.</span>
              </div>
            </Tooltip>

            <label
              htmlFor="title"
              className="block ml-2 text-xs font-medium leading-6 text-customLightBlue"
            >
              Tokens
            </label>
          </div>
          <div className="sm:col-span-2">
            <div className="text-xs text-center font-semibold text-customDarkBlue">
              <span>{maxTokensValue}</span>
            </div>

            <input
              type="range"
              min="800"
              max="4000"
              value={maxTokensValue}
              // step="800"
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
              onChange={(e: any) => setMaxTokensValue(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Action buttons */}
      <div className="fixed bg-white bottom-0 w-[530px] border-t border-gray-200 px-4 py-5 sm:px-6">
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            className="rounded-md bg-white px-3 py-2 text-xs font-semibold text-customLightBlue shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={onCloseModal}
          >
            Cancel
          </button>
          <CustomButton
            type="submit"
            onClickBtn={() => console.log("Clicking...")}
            text="Save"
            loading={loader}
          />
        </div>
      </div>
    </form>
  );
}
